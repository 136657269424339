import { AppUserClaim } from './app-user-claim';

export class AppUserAuth {
    userName = '';
    // tslint:disable-next-line: variable-name
    access_token = '';
    isAuthenticated = false;
    role = '';
    claims: AppUserClaim[] = [];
    userType ='';
    userImage = '';
}
