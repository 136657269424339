import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { AppUser } from './app-user';
import { AppUserAuth } from './app-user-auth';
import { SecurityService } from './security.service';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ConfigService } from '../shared/services/config.service';

@Component({
  selector: 'wfm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  userForm: FormGroup;
  user = new AppUser();
  customerForm: FormGroup;
  securityObject: AppUserAuth = null;
  returnUrl: string;
  usernameValidation: string;
  logoImageUrl: string;

  private validationMessages = {
    required: 'This field is required',
    minlength: 'should be 3 characters'
  };

  constructor(private fb: FormBuilder, private securityService: SecurityService, private router: Router, private route: ActivatedRoute, private configService: ConfigService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2) { }

  ngOnInit() {

    this.userForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    const usernameControl = this.userForm.get('username');
    usernameControl.valueChanges.pipe(
    ).subscribe(
      value => this.setMessage(usernameControl)
    );
    this.configService.getConfigData().subscribe((res) => {
      localStorage.setItem("logoImageUrl", res.configuration.logoImagePath);
      this.logoImageUrl = '../../assets/images/'.concat(res.configuration.logoImagePath);
    })
    //  this.logoImageUrl ="https://teamplanstorage.blob.core.windows.net/prodimages/logo-login-pharmatch.ico"
   // this.logoImageUrl = "https://teamplanstorage.blob.core.windows.net/prodimages/demo login page imag.png"
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');

    if (this.securityService.isAuthenticated() && localStorage.getItem('role')) {
      this.redirectToProfile()
    }

  }
  login() {
    if (this.userForm.valid) {
      this.user.username = this.userForm.get('username').value;
      this.user.password = this.userForm.get('password').value;

      // const u = {...this.user, ...this.userForm.value };
      this.securityService.login(this.user).subscribe(
        resp => {   
          this.securityObject = resp;
          let claims = JSON.parse(this.securityObject.claims.toString());
          localStorage.setItem("loggedinUserId",claims[0].userId);
          this.redirectToProfile()
        },
        () => {
          this.securityObject = new AppUserAuth();
        }
      );
    } else {
    }

  }

  redirectToProfile() {
    const role = localStorage.getItem('role');
    if (role === 'Administrator') {
      this.router.navigate(['/users/calendar']);
    } else if (role === 'SuperClient') {
      this.router.navigate(['clients/calendar']);
    } else if (role === 'Client') {
      this.router.navigate(['/calendar']);
    } else {
      this.router.navigate(['/bookings']);
    }
  }

  setMessage(c: AbstractControl): void {
    this.usernameValidation = '';
    if ((c.touched || c.dirty) && c.errors) {
      this.usernameValidation = Object.keys(c.errors).map(key => this.usernameValidation += this.validationMessages[key]).join(' ');
    }
  }

}
