import { LongBookingShift } from "./long-booking-shift.model";

export class LongBookingTemplate {
    Id:number;
    label:string;
    Name:string;
    CreatedDate:Date;
    Shifts: LongBookingShift[];
    showEdit:boolean;
    updatedInProgress:boolean;
}