import { AlliocationModel } from './../alliocation-model';
import { AvailabilityInfo } from './../availability-info';
import { CalendarService } from './../calendar.service';
import { PartialAvailabilityInfo } from './../partial-availability-info';
import { Subscription, Subject } from 'rxjs';
import { GenericValidator } from './../../shared/generic-validator';
import { AvailabilityUpdateModel } from './../availability-update-model';
import { IUserInfo } from './../../user/user-info';
import { BookingInfo } from './../booking-info';
import { AvailabilityModel } from './../availability-model';
import { FormControlName, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, AfterViewInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { UserService } from 'src/app/user/user.service';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SignalrService } from 'src/app/shared/services/common/signalr.service';
declare var kendo: any;

@Component({
  selector: 'wfm-superclient-calendar',
  templateUrl: './superclient-calendar.component.html',
  styleUrls: ['./superclient-calendar.component.css']
})
export class SuperclientCalendarComponent implements OnInit,OnDestroy {

  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  userId: string;
  userType: string;
  availabilityCheckDates: AvailabilityModel[];
  errorMessage: string;
  allocation: BookingInfo;
  visibleTimeLogs = false;
  updateAllocationId: number;
  swapUserList: IUserInfo[];
  allocationForm: FormGroup;
  selectedUserFullName: string;
  notificationModal: any;
  availabilityUpdateModel: AvailabilityUpdateModel;
  currentDate = new Date();
  isWeekend = true;
  displayValidationMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  subscription: Subscription;
  selectedPartialAvlDate: string;
  partialAvailableListForDay: PartialAvailabilityInfo[];
  allocationTryDate: Date;
  allocationTryTime: string;
  allocationTryLunch: number;
  selectedResourceTypeId = 1;
  resourceTypes: any[];
  isBookingSelected = true;
  isBookingFromPartialAvailability: boolean = false;
  availabilityData:AvailabilityInfo[];
  selectedCalendarMonth: Subject<Date> = new Subject<Date>();
  swedishHolidays:any
  holidayLastMonth:string
  clientCommonTimes:any[]
  allocationHistory: any
  sendRequest: any = false
  employeeProfileId: Subject<string>= new Subject<string>();
  routeSubscription:Subscription
  currentURL:any


  showHistory:boolean = false
  isInquiryCalendarSelected:boolean = false

  deviceWidth:number = window.innerWidth;
  deviceHight:number = window.innerHeight;

  selectedUserId: Subject<any> = new Subject<any>()
  selectedUserType: Subject<string> =  new Subject<string>()
  userListAction: Subject<string> = new Subject<string>()
  selectedDate: Date = new Date(new Date().setHours(0,0,0,0));


  lunchTimeList:any[]=[
    { value: 0, label: '0' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
    { value: 45, label: '45' },
    { value: 60, label: '60' },
  ]
  constructor(private fb: FormBuilder, private calendarService: CalendarService, private signalRService: SignalrService, private elementRef: ElementRef, private router:Router, private userService:UserService) {
    this.validationMessages = {
      month: {
        required: 'month is required'
      },
      startDate: {
        required: 'start is required'
      },
      endDate: {
        required: 'end is required'
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);

    this.subscription = this.signalRService.receiveCalendarUpdates().subscribe(st => {
      if (st) {
        const scheduler = $('#scheduler').data('kendoScheduler');
        scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
        scheduler.refresh();
      }
    });

    this.routeSubscription = this.router.events.subscribe((event: Event) => {
      this.currentURL = ''
      if (event instanceof NavigationStart) {
        this.currentURL = ''
      }
      if (event instanceof NavigationEnd) {
        this.currentURL = event.url.split('?')[0]

        if (
          (this.currentURL == '/users/calendar/inquiries') ||
          (this.currentURL == '/clients/calendar/inquiries') ||
          (this.currentURL == '/bookings/inquiries')
        ) {
          this.isInquiryCalendarSelected = true
        } else {
          this.isInquiryCalendarSelected = false
        }
      }
    }); 

    this.resourceTypes = [];
  }

  ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();
    }

  ngOnInit() {
    kendo.culture('sv-SE');
    this.currentDate.setHours(0, 0, 0, 0);
    this.allocation = {
      isExpAreaValidationFail:null,
      id: 0,
      title: '',
      sDate: '',
      eDate: '',
      lunch: 0,
      isPastDate: false,
      bookingTotal: 0,
      start: new Date(),
      end: new Date(),
      empName: '',
      empImage: '',
      empJobCategoryId: 0,
      empJobCategory: '',
      clientName: '',
      clientContactName: '',
      clientAddress: '',
      clientPhone: '',
      clientEmail: '',
      isTimeLogged: false,
      timeLogStartTime: '',
      timeLogEndTime: '',
      timeLogLunch: 0,
      timeLogTotal: 0,
      colorClass: 'slot-blue',
      timeLoggedClass: 'slot-no-check',
      empImagePath: '',
      clientImagePath: '',
      resourceTypeId: 1,
      resourceTypeClass: '',
      isInquiry:null,
      clientId:'',
      resourceTypeIconLetter:''
    };

    this.notificationModal = {
      header: '',
      description: '',
      type: ''
    };

    this.userService.newSelectedUser.subscribe((data)=>{
      this.loadCalendar(data);
    })

    this.getCurrentConfigData()
  }


  getJsonDateFormat(date:Date):string{
    return date.getFullYear()+''+(date.getMonth()+1>9? date.getMonth()+1:'0'+(date.getMonth()+1))
  }

  loadCalendar(data: any): void {
    // if (data == null || this.userId === data.userId) {
    //   this.userId = 'all';
    //   this.selectedUserFullName = null;
    //   // $('.employee-wrapper').removeClass('employee-wrapper-active');
    //   // $('.k-scheduler-table td').removeClass('cell-celected');
    //   this.selectedResourceTypeId = null;
    //   // this.userService.emmitSelectedUserId({id:this.userId,type:'all'})
    //   this.selectedUserType.next('all')
    // } else {
    //   debugger 
    //   this.userId = data.userId;
    //   this.selectedUserFullName = data.fullName;
    //   this.userType = data.userType;
    //   // this.userService.emmitSelectedUserId({id:this.userId,type:this.userType})
    //   this.selectedUserType.next(data.userType)
    // }

    //// this.userId = data.userId;
    //// if (!this.isInquiryCalendarSelected) {
    ////   const scheduler = $('#scheduler').data('kendoScheduler');
    ////   scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
    ////   scheduler.refresh();
    //// }

     // Values when tab select
        // 1 - Phamacists
        // 2 - Cashiers
        // null - Clients

        // Userdata when user selects
        // { 
        //     userId,
        //     userType,
        //     fullName,
        //     actualHoursBooked,
        //     totalCalculatedHours
        // }
    var reloadRequired = true
    if (data != null) {
      // if (data == 1 || data == 2 || this.userId === data.userId) {
      if (typeof (data) == "number" || this.userId === data.userId) {
        this.userId = 'all';
        this.selectedUserFullName = null;
        this.selectedUserType.next('all')
      } else {
        // Uncomment following block to reset resource type to phamacists on user select
        if (data.userType == "Client" && this.userId == 'all') {
          this.selectedResourceTypeId = 1;
          // this.resourceTypeForAvalReq = '1';
        }

        this.userId = data.userId;
        this.selectedUserFullName = data.fullName;
        this.userType = data.userType;

        // this.userservice.emmitSelectedUserId({ id: this.userId, type: this.userType })
        this.selectedUserType.next(data.userType)
      }
    } else {
      this.userId = 'all';
      this.selectedUserFullName = null;
      this.selectedUserType.next('all')
    }
  }


  setNotificationData(flow, status): void {
    if (flow === 'booking') {
      this.notificationModal.header = 'Bokning misslyckades';
      this.notificationModal.description = 'Tyvärr så kunde vi inte hitta en tillgänglig resurs för det valda datumet';
      this.notificationModal.type = 'danger';
    } else if (flow === 'swap') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Byte av resurs genomfördes';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Byte av resurs misslyckades! Kontrollera anställdas tillgänglighet';
        this.notificationModal.type = 'danger';
      }
    } else if (flow === 'remove') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Bokningen raderades';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Borttaggning av bokningen misslyckades! Var god försök igen';
        this.notificationModal.type = 'danger';
      }
    } else if (flow === 'availability') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Tillgänglighet uppdaterades';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Uppdatering av tillgänglighet misslyckades! Var god försök igen';
        this.notificationModal.type = 'danger';
      }
    } else if (flow === 'timelog') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Tidrapportering uppdaterades';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'TUppdatering av tidrapportering misslyckades! Var god och försök igen';
        this.notificationModal.type = 'danger';
      }
    } else if (flow === 'partialavl') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Tillgänglighet uppdaterades';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Uppdatering av tillgänglighet misslyckades! Var god försök igen';
        this.notificationModal.type = 'danger';
      }
    } else if (flow === 'assign') {
      if (status) {
        this.notificationModal.header = 'Lyckades';// Success
        this.notificationModal.description = 'Den anställde tilldelades förfrågan och e-post skickades. '; //'Employee was assigned successfully for inquiry and email sent.';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades'; //'Failed';
        this.notificationModal.description = 'Tilldelning av passet till den anställda misslyckades. Vänligen försök igen. '; //'Employee assign process failed! Please try again.';
        this.notificationModal.type = 'danger';
      }
    } else if (flow === 'inquiry') {
      if (status) {
          this.notificationModal.header = 'Lyckades';// Success
          this.notificationModal.description = 'Inquiry added successfully!';
          this.notificationModal.type = 'success';
      } else {
          this.notificationModal.header = 'Misslyckades'; //'Failed';
          this.notificationModal.description = 'Inquiry adding failed! Please try again.';
          this.notificationModal.type = 'danger';
      }
  }

    ($('#superClientNotificationModal') as any).modal('show');
    setTimeout(() => {
      ($('#superClientNotificationModal') as any).modal('hide');
    }, 3000);
  }


  scrollToHour(hour): void {
    const time = new Date();
    time.setHours(hour);
    time.setMinutes(0);
    time.setSeconds(0);
    time.setMilliseconds(0);

    const scheduler = $('#scheduler').data('kendoScheduler');
    const contentDiv = scheduler.element.find('div.k-scheduler-content');
    const rows = contentDiv.find('tr');

    for (let i = 0; i < rows.length; i++) {
      const element = $(rows[i]);
      const slot = scheduler.slotByElement(element);

      const slotTime = kendo.toString(slot.startDate, 'HH:mm');
      const targetTime = kendo.toString(time, 'HH:mm');

      if (targetTime === slotTime) {
        contentDiv.scrollTop(0);

        const elementTop = element.offset().top;
        const containerTop = contentDiv.offset().top;

        contentDiv.scrollTop( elementTop - containerTop );
      }
    }
  }

  bookFromPartialAvailability(partialAvailabilityData: PartialAvailabilityInfo): void {
    ($('#superClientPartialAvailabilityListModal') as any).modal('hide');
    this.allocationForm.patchValue({
      startDate: this.allocationTryDate,
      endDate: this.allocationTryDate,
      weekdayStart: partialAvailabilityData.fromTime,
      weekdayEnd: partialAvailabilityData.toTime,
      weekdayLunch: this.allocationTryLunch,
    });

    $('#kendo-end-date').data('kendoDatePicker').value(this.allocationTryDate);
    $('#kendo-end-date').data('kendoDatePicker').enable(false);
    $('#kendo-wd-start').data('kendoTimePicker').value(partialAvailabilityData.fromTime);
    $('#kendo-wd-start').data('kendoTimePicker').enable(false);
    $('#kendo-wd-end').data('kendoTimePicker').value(partialAvailabilityData.toTime);
    $('#kendo-wd-end').data('kendoTimePicker').enable(false);

    this.isBookingFromPartialAvailability = true;
    ($('#superClientrequestAllocationModal') as any).modal('show');
  }

  onResourceTypeChange(resourceTypeId: number): void {

    this.calendarService.emmitOnResourceTypeChange(resourceTypeId);
    //if (resourceTypeId!=='3') {
        this.selectedResourceTypeId = resourceTypeId;
    //}

    // this.selectedResourceTypeId  = resourceTypeId;
    // this.allocationForm.patchValue({
    //   resourceTypeId: resourceTypeId
    // });
    // this.availabilityCheckDates.forEach(x => x.resourceTypeId = resourceTypeId);
    // const scheduler = $('#scheduler').data('kendoScheduler');
    // scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
  }


  onCalendarChange(calendar:string): void {
    if (calendar==='inquiry') {
      $('.btn-calendar-booking').removeClass('btn-calendar-active')
      $('.btn-calendar-booking').addClass('btn-calendar-inactive')
      $('.btn-calendar-inquiry').removeClass('btn-calendar-inactive')
      $('.btn-calendar-inquiry').addClass('btn-calendar-active')
      this.isInquiryCalendarSelected=true
    } else {
      $('.btn-calendar-inquiry').removeClass('btn-calendar-active')
      $('.btn-calendar-inquiry').addClass('btn-calendar-inactive')
      $('.btn-calendar-booking').removeClass('btn-calendar-inactive')
      $('.btn-calendar-booking').addClass('btn-calendar-active')
      const scheduler = $('#scheduler').data('kendoScheduler');
      // scheduler.refresh();
      // this.schedulerDataBound(scheduler);
      // setTimeout(() => {
      //   this.schedulerDataBound(scheduler);
      // }, 700);
      this.isInquiryCalendarSelected=false
      this.selectedResourceTypeId = 1;
    }
  }

  onMobileTabSelect(event){
    if (event=='userlist') {
      $('#myTab').css('display', 'flex')
      $('#myTabContent').css('display', 'block')
    } else if (event=='bookings') {
      $('#myTab').css('display', 'none')
      $('#myTabContent').css('display', 'none')
      $('.btn-calendar-booking').click()
    } else if (event=='inquiry') {
      $('#myTab').css('display', 'none')
      $('#myTabContent').css('display', 'none')
      $('.btn-calendar-inquiry').click()
    }
  }

  onDrawerClose(event){
    if (event=='userlist') {
      $('#myTab').css('display', 'flex')
      $('#myTabContent').css('display', 'block')
    } else if (event=='bookings') {
      $('#myTab').css('display', 'none')
      $('#myTabContent').css('display', 'none')
    } else if (event=='inquiry') {
      $('#myTab').css('display', 'none')
      $('#myTabContent').css('display', 'none')
    }
  }

  setCommonTimes(item):void {
    this.allocationForm.patchValue({
      weekdayStart: item.startTime,
      weekdayEnd: item.endTime,
      weekdayLunch: item.lunch,
    });
    // $('#kendo-start-date').data('kendoDatePicker').value(eventStartDate);
    // $('#kendo-end-date').data('kendoDatePicker').value(item.endDate);
    $('#kendo-wd-start').data('kendoTimePicker').value(item.startTime);
    $('#kendo-wd-end').data('kendoTimePicker').value(item.endTime);
  }

  openProfileViewModal(event){
    this.employeeProfileId.next(event);
    ($('#employeeProfileViewModal') as any).modal('show');
  }

  addInquiryOnpartial(allocationData) {
    this.allocationForm.patchValue({
        clientId: this.userId,
        isInquiry: true
    });

    this.calendarService.requestSuperClientAllocations(this.allocationForm.value).subscribe(
        (allocationInfo: BookingInfo) => {
            if (allocationInfo != null) {
                ($('#superClientPartialAvailabilityListModal') as any).modal('hide');
                this.signalRService.broadcastCalendarUpdates();
                this.setNotificationData('inquiry', true);
                this.allocationForm.patchValue({
                    startDate: '',
                    endDate: '',
                    weekdayStart: '',
                    weekdayEnd: '',
                    weekdayLunch: 0,
                    employeeId: ''
                });
                this.userListAction.next('refresh');
            } else {
                this.setNotificationData('inquiry', false);
            }
            $('.k-scheduler-table td').removeClass('cell-celected');
            // this.onAllocationRequestComplete(allocationInfo, this.allocationForm.value);
        },
        (error: any) => this.errorMessage = error as any
    );
  }
  onClickMakeBooking(){
    this.calendarService.emmitOnClickMakeBooking();
    // this.clientCommonTimes = []
    //   if (this.isBookingSelected) {
    //     this.calendarService.getClientCommonTimes(this.userId).subscribe((response) => {
    //       this.clientCommonTimes = response;
    //       ($('#superClientrequestAllocationModal') as any).modal('show');
    //     })
    //   }else{
    //     ($('#superClientrequestAllocationModal') as any).modal('show');
    //   }
  }
  onViewHistoryBtnClick(allocationId: number) {
    this.toggleSendRequest();
    if (this.sendRequest) {
      this.calendarService.getAllocationHistory(allocationId).subscribe(
        (res) => {
            this.allocationHistory = res;
        },
        (error: any) => this.errorMessage = error as any
    );
    }
    
  }
  toggleSendRequest(){
    this.sendRequest = !this.sendRequest
  }

  getCurrentConfigData(): void {
    this.userService.getConfigData().subscribe((res) => {
      this.resourceTypes = res.resourceTypes 
      
    })
  }
}
