import { Component, OnInit, ElementRef, ViewChildren, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControlName, FormControl, AbstractControl } from '@angular/forms';
import { GenericValidator } from '../shared/generic-validator';
import { Observable, merge, fromEvent, Subscription, timer } from 'rxjs';
import { debounceTime, switchMap, map } from 'rxjs/operators';
import { IUserInfo } from './user-info';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from './user.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { CalendarService } from '../calendar/calendar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'wfm-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.css']
})
export class MyprofileComponent implements OnInit, AfterViewInit {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  userForm: FormGroup;
  resetPasswordForm: FormGroup;
  pageTitle: string;
  errorMessage: string;
  userTypes: any[];
  jobTypes: any[];
  userInfo: IUserInfo = {} as IUserInfo;
  file: any;
  private sub: Subscription;
  previewUrl: any;
  userImage = '';
  fileData: File = null;
  updateUserId: string;
  isResetPassword = false;
  notificationModal: any;
  isEditCounty: boolean = false
  selectedCounty: any
  imageChangedEvent: any = '';
  croppedImage: any = '';
  cropperVisible: boolean
  holdMunicipalityChange: boolean = false
  isAllMunicipalitySelected: boolean = false
  isSubmitInvalid: boolean = false
  resourceTypes: any[];
  matchingAlgorithnmFields: any[];
  isEnglishLanguage: boolean = true;;


  jsonCountyMunicipalities: any[] = [];
  countyMunicipalities: any[] = [];
  counties: any[] = [];
  municipalities: any[] = [{ value: null, label: '' }, { value: null, label: '' }];
  pharmacySystems: any[] = [];
  legitimations: any[] = [];
  experienceAreas: any[] = [];
  skillLevels: any[] = [];
  languageObjArray: any[] = [];
  languages: any[] = [
    { value: 1, label: 'English' },
    { value: 2, label: 'Swedish' }
  ];
  yearsOfExperiences: any[] = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 11, label: '10+' },
  ];
  yesNo: any[] = [
    { value: true, label: 'Ja ' },
    { value: false, label: 'Nej ' },
  ];

  userFiles: any[] = [
    { id: 1, name: 'Birth_certificate.docs', description: 'Birth Certificate' },
    { id: 2, name: 'Nic.pdf', description: 'Nic' },
  ];

  displayMessage: { [key: string]: string } = {};
  displayResetPwdMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;


  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private userService: UserService,
    private calendarService: CalendarService
  ) {

    this.validationMessages = {
      userTypeId: {
        required: 'anställningsform krävs'
      },
      firstName: {
        required: 'förnamn krävs',
        minlength: 'måste ha minst 2 tecken',
        maxlength: 'max 50 tecken'
      },
      lastName: {
        required: 'efternamn krävs',
        minlength: 'måste ha minst 2 tecken',
        maxlength: 'max 50 tecken'
      },
      address: {
        required: 'adress krävs',
        maxlength: 'max 100 tecken'
      },
      city: {
        required: 'stad krävs',
        maxlength: 'max 50 tecken'
      },
      zip: {
        required: 'postnummer krävs',
        maxlength: 'max 10 tecken'
      },
      email: {
        required: 'e-post krävs',
        email: 'e-posten ogiltig',
        maxlength: 'max 256 tecken'
      },
      phoneNumber: {
        required: 'telefonnummer krävs',
        maxlength: 'max 50 tecken'
      },
      emergancyContact: {
        required: 'telefonnummer krävs',
        maxlength: 'max 50 tecken'
      },
      jobCategoryId: {
        required: 'anställningsform krävs'
      },
      clientName: {
        required: 'apoteketsnamn krävs',
        maxlength: 'max 100 tecken'
      },
      pharmacyId: {
        required: 'apotek-ID krävs',
        maxlength: 'max 100 tecken'
      },
      userName: {
        required: 'användarnamn krävs',
        minlength: 'användarnamn måste ha minst 3 tecken'
      },
      password: {
        required: 'lösenord krävs',
        minlength: 'lösenord måste ha minst 6 tecken'
      },
      conpassword: {
        required: 'lösenord krävs',
        invalid: 'lösenord matchar inte'
      },
      resetpassword: {
        required: 'lösenord krävs',
        minlength: 'lösenord måste ha minst 6 tecken'
      },
      resetconpassword: {
        required: 'lösenord krävs',
        match: 'lösenord matchar inte'
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  setCustomVallidations() {
    const clientNameControl = this.userForm.get('clientName');
    const pharmacyIdControl = this.userForm.get('pharmacyId');
    const municipalityControl = this.userForm.get('municipality');

    if (this.userInfo.userTypeId === '3') {
      clientNameControl.setValidators([Validators.required, Validators.maxLength(100)]);
      pharmacyIdControl.setValidators([Validators.required, Validators.maxLength(100)]);
      municipalityControl.setValidators([Validators.required]);
    } else {
      clientNameControl.clearValidators();
      pharmacyIdControl.clearValidators();
      municipalityControl.clearValidators();
    }
    clientNameControl.updateValueAndValidity();
    pharmacyIdControl.updateValueAndValidity();
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  ngOnInit() {

    this.isEnglishLanguage = true;
    if (localStorage.getItem("isSwLanguage") == "false") {
      this.isEnglishLanguage = true
    } else {
      this.isEnglishLanguage = false
    }

    this.userForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      clientName: [''],
      pharmacyId: [],
      address: ['', [Validators.required, Validators.maxLength(100)]],
      city: ['', [Validators.required, Validators.maxLength(20)]],
      zip: ['', [Validators.required, Validators.maxLength(10)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(256)]],
      phoneNumber: ['', [Validators.required, Validators.maxLength(50)]],
      emergancyContact: ['', [Validators.required, Validators.maxLength(50)]],
      county: [[]],
      municipality: [[]],
      pharmacySystems: [],
      employeeNo: [''],
      isStaredEmployeesOnly: [Boolean],
      countyMunicipalities: [[]],
      legitimationId: [],
      experienceAreas: [],
      yearsOfExperience: [],
      isStdProceduresRead: [Boolean],
      aboutMe: [''],
      languages: [],
      cmJson: '',
      skillLevel: []
    });

    this.resetPasswordForm = this.fb.group({
      resetpassword: ['', Validators.required],
      resetconpassword: ['', Validators.required]
    }, { validator: resetPasswordCompare });

    this.notificationModal = {
      header: '',
      description: '',
      type: ''
    };

    this.userService.getInnitialMasterData().subscribe((data) => {
      this.pharmacySystems = data.pharmacySystems
      this.counties = data.counties
      this.experienceAreas = data.experienceAreas
      this.legitimations = data.legitimations
      this.skillLevels = data.skillLevels
      this.languages = data.languages


      for (let [key, value] of Object.entries(this.pharmacySystems)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
      for (let [key, value] of Object.entries(this.counties)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
      for (let [key, value] of Object.entries(this.experienceAreas)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
      for (let [key, value] of Object.entries(this.legitimations)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
      for (let [key, value] of Object.entries(this.skillLevels)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
      for (let [key, value] of Object.entries(this.languages)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }

    })

    this.getUser();
    this.getCurrentConfigData()
  }


  ngAfterViewInit(): void {
    // // Watch for the blur event from any input element on the form.
    // const controlBlurs: Observable<any>[] = this.formInputElements
    //   .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    // // Merge the blur event observable with the valueChanges observable
    // merge(this.userForm.valueChanges, ...controlBlurs).pipe(
    //   debounceTime(300)
    // ).subscribe(value => {
    //   this.displayMessage = this.genericValidator.processMessages(this.userForm);
    // });

    // // Merge the blur event observable with the valueChanges observable
    // merge(this.resetPasswordForm.valueChanges, ...controlBlurs).pipe(
    //   debounceTime(300)
    // ).subscribe(value => {
    //   this.displayResetPwdMessage = this.genericValidator.processMessages(this.resetPasswordForm);
    // });
  }

  changeLanguage(ev) {
    let value = ev.target.checked;
    this.isEnglishLanguage = value;
    if (value) {
      localStorage.setItem("isSwLanguage", "false");
      this.translate.setDefaultLang('en')
    } else {
      localStorage.setItem("isSwLanguage", "true");
      this.translate.setDefaultLang('sw')
    }
  }

  getUser(): void {
    this.userService.getMyProfile()
      .subscribe(
        (user: IUserInfo) => {
          if (user.countyMunicipalities && user.countyMunicipalities.length > 0) {
            this.userService.getMunicipalitiesByCountyId(user.countyMunicipalities[0].countyId).subscribe((data: []) => {
              this.municipalities = data

              for (let [key, value] of Object.entries(this.municipalities)) {
                value['value'] = value.id;
                value['label'] = value.name;
              }
              this.municipalities.unshift({ value: 0, id: 0, code: "0000", label: "Alla Kommuner", name: "Alla Kommuner" })
              this.displayUser(user)
            });
          } else {
            this.displayUser(user)
          }
        },
        (error: any) => this.errorMessage = error as any
      );
  }

  displayUser(user: IUserInfo): void {

    if (this.userForm) {
      this.userForm.reset();
    }
    this.userInfo = user;
    this.setCustomVallidations();
    let userImage = this.userInfo.image;
    this.userImage = '../../assets/images/userImages/'.concat(userImage);


    if (this.userInfo.cmJson) {
      this.jsonCountyMunicipalities = JSON.parse(this.userInfo.cmJson)
    }

    this.userForm.patchValue({
      firstName: this.userInfo.firstName,
      lastName: this.userInfo.lastName,
      clientName: this.userInfo.clientName,
      pharmacyId: this.userInfo.pharmacyId,
      address: this.userInfo.address,
      city: this.userInfo.city,
      zip: this.userInfo.zip,
      email: this.userInfo.email,
      phoneNumber: this.userInfo.phoneNumber,
      emergancyContact:this.userInfo.emergancyContact
    });

    if (this.userInfo.userTypeId == '2') {
      this.userForm.patchValue({
        county: this.userInfo.county,
        municipality: this.userInfo.municipality,
        pharmacySystems: this.userInfo.pharmacySystems,
        legitimationId: this.userInfo.legitimationId,
        experienceAreas: this.userInfo.experienceAreas,
        yearsOfExperience: this.userInfo.yearsOfExperience,
        isStdProceduresRead: this.userInfo.isStdProceduresRead,
        aboutMe: this.userInfo.aboutMe,
        // languages: this.userInfo.languages
      });
      // this.languageObjArray = this.userInfo.languages
      // this.countyMunicipalities = this.userInfo.countyMunicipalities

      this.languageObjArray = this.userInfo.languages ? this.userInfo.languages : []
      this.countyMunicipalities = this.userInfo.countyMunicipalities ? this.userInfo.countyMunicipalities : []

      if (this.userInfo.countyMunicipalities) {
        this.countyMunicipalities.forEach(obj => {
          this.counties.forEach(element => {
            if (element.value == obj.countyId) {
              element.disabled = true
            }
          });
        });
      }

    }
    if (this.userInfo.userTypeId == '3') {
      this.userForm.patchValue({
        aboutMe: this.userInfo.aboutMe,
        county: this.userInfo.countyMunicipalities ? this.userInfo.countyMunicipalities[0].countyId : '',
        municipality: this.userInfo.countyMunicipalities ? this.userInfo.countyMunicipalities[0].municipalityId : '',
        pharmacySystems: this.userInfo.pharmacySystems ? this.userInfo.pharmacySystems[0] : '',
        isStaredEmployeesOnly: this.userInfo.isStaredEmployeesOnly,
      });
    }
  }


  saveUser(): void {
    if (this.userForm.valid) {
      if (this.userForm.dirty || this.fileData) {

        if (this.userInfo.userTypeId == '2') {
          this.userForm.patchValue({
            countyMunicipalities: this.countyMunicipalities,
            cmJson: JSON.stringify(this.jsonCountyMunicipalities),
            languages: this.languageObjArray
          })

          this.userForm.value.languages = this.languageObjArray ? this.languageObjArray : []
          this.userForm.value.experienceAreas = this.userForm.value.experienceAreas ? this.userForm.value.experienceAreas : []
          this.userForm.value.pharmacySystems = this.userForm.value.pharmacySystems ? this.userForm.value.pharmacySystems : []

        } else if (this.userInfo.userTypeId == '3') {
          // this.userForm.patchValue({
          //   countyMunicipalities: [{ countyId: this.userForm.value.county, municipalityId: this.userForm.value.municipality }]
          // })
          if (this.userForm.value.county && this.userForm.value.municipality) {
            this.userForm.patchValue({
              countyMunicipalities: [{ countyId: this.userForm.value.county, municipalityId: this.userForm.value.municipality }],
              // pharmacySystems: [this.userForm.value.pharmacySystems]
            })
          } else {
            this.userForm.patchValue({
              countyMunicipalities: []
            })
          }
          this.userForm.value.pharmacySystems = this.userForm.value.pharmacySystems ? [this.userForm.value.pharmacySystems] : []
        }

        const u = { ...this.userInfo, ...this.userForm.value };
        u.image = this.userInfo.image;

        this.userService.updateMyProfile(u)
          .subscribe(
            () => this.onSaveComplete('update', true, u.userId),
            (error: any) => this.errorMessage = error as any
          );
      }
    } else {
      this.validateAllFormFields(this.userForm)
      this.validateAllFormFields(this.resetPasswordForm)
      this.errorMessage = 'Please correct the validation errors.';
      this.isSubmitInvalid = true
    }
  }

  onSaveComplete(flow: string, status: boolean, userId: string): void {
    if (this.fileData && userId != null) {
      const formData = new FormData();
      formData.append('file', this.fileData);
      formData.append('userId', userId);

      this.userService.uploadProfileImage(formData)
        .subscribe(
          (imagePath: string) => {
            if (imagePath != null && imagePath != '') {
              localStorage.removeItem('userImage');
              localStorage.setItem('userImage', imagePath);
            }
          },
          (error: any) => this.errorMessage = error as any
        );
    }
    //this.setNotificationData('update', status);

  }

  uploadImageClick(): void {
    $("#fileUploadBtn").click();
  }
  uploadFileClick(): void {
    $("#fileUploadBtn1").click();
  }

  // On file Select
  onChange(event) {
    this.file = event.target.files[0];
  }

  // OnClick of button Upload
  onUpload() {
    const formData = new FormData();
    formData.append('file', this.fileData);
    formData.append('userId', this.userInfo.userId);
    this.userService.uploadProfileImage(formData)
      .subscribe(
        (imagePath: string) => {
          if (imagePath != null && imagePath != '') {
            localStorage.removeItem('userImage');
            localStorage.setItem('userImage', imagePath);
          }
        },
        (error: any) => this.errorMessage = error as any
      );
  }

  // Cropper Functions
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.cropperVisible = true
    $('#upload-demo').css('display', 'block')
  }

  imageCropped(event: ImageCroppedEvent) {
    // For Preview
    this.croppedImage = event.base64;
    // prepare for upload
    const fileBeforeCrop = this.imageChangedEvent.target.files[0];
    var strImage = this.croppedImage.replace(/^data:image\/[a-z]+;base64,/, "");
    const imageBlob = this.dataURItoBlob(strImage);
    this.fileData = new File([imageBlob], fileBeforeCrop.name, { type: fileBeforeCrop.type }) as File
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  removeImage(): void {
    if (this.fileData) {
      this.fileData = null;
      this.previewUrl = null;
      this.croppedImage = ''
    }
    if (this.userImage) {
      this.userImage = '';
      this.userInfo.image = null;
      this.userInfo.imagePath = null;
    }
    this.cropperVisible = false
    $('#upload-demo').css('display', 'none')
  }
  cancelCrop() {
    this.fileData = null
    this.cropperVisible = false
    $('#upload-demo').css('display', 'none')
  }

  toggleCropper() {
    this.cropperVisible = !this.cropperVisible
    if (this.cropperVisible) {
      $('#upload-demo').css('display', 'block')
    } else {
      $('#upload-demo').css('display', 'none')
    }
  }

  showResetPasswordForm(): void {
    this.isResetPassword = !this.isResetPassword;
  }

  resetPassword(): void {
    const formData = new FormData();
    formData.append('pwd', this.resetPasswordForm.get('resetpassword').value);
    this.userService.resetProfilePassword(formData)
      .subscribe(
        (st: boolean) => {
          this.isResetPassword = !this.isResetPassword;
          this.setNotificationData('update', st);
        },
        (error: any) => this.errorMessage = error as any
      );
  }


  private preview() {
    if (this.fileData) {
      const mimeType = this.fileData.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      // tslint:disable-next-line: variable-name
      reader.onload = (_event) => {
        this.previewUrl = reader.result;
      };
    }
  }

  setNotificationData(flow, status): void {
    if (flow === 'update') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Användaren har uppdaterats';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Uppdatering av användare misslyckades! Var god försök igen';
        this.notificationModal.type = 'danger';
      }
    }
    ($('#notificationModal') as any).modal('show');
    setTimeout(() => {
      ($('#notificationModal') as any).modal('hide');
    }, 3000);
  }


  onCountyChange(): void {
    if (this.userForm.value.county > 0) {
      this.userForm.patchValue({
        municipality: []
      })
      this.userService.getMunicipalitiesByCountyId(this.userForm.value.county).subscribe((data: []) => {
        this.municipalities = data
        if (this.userInfo.userTypeId == '2') {
          this.municipalities.unshift({ value: 0, id: 0, code: "0000", label: "Alla Kommuner", name: "Alla Kommuner" })
        }
        for (let [key, value] of Object.entries(this.municipalities)) {
          value['value'] = value.id;
          value['label'] = value.name;
        }
      },
        (error: any) => this.errorMessage = error as any
      )
    }
  }

  handleMunicipalityChange() {
    if (!this.holdMunicipalityChange) {
      this.onMunicipalityChange()
    }
  }

  onMunicipalityChange() {
    let allMunicipalities = null
    if (this.isAllMunicipalitySelected && this.userForm.get('municipality').value.length > 1) {
      this.userForm.get('municipality').value.shift()
      this.isAllMunicipalitySelected = false
    }

    allMunicipalities = this.userForm.value.municipality.find((element) => { return element == '0' })

    if ((allMunicipalities == '0') && (this.userForm.get('municipality').value.length > 1)) {
      this.holdMunicipalityChange = true
      this.userForm.patchValue({
        municipality: [0]
      })
      this.holdMunicipalityChange = false
      this.isAllMunicipalitySelected = true
    }
  }

  onAddCountyMunicipals(): void {
    if (this.isEditCounty) {
      var county = this.selectedCounty.value
    } else {
      var county = this.userForm.value.county
    }

    this.counties.forEach(element => {
      if (element.value == county) {
        element.disabled = true
      }
    });

    var mArray = []
    if (this.countyMunicipalities && this.countyMunicipalities.length > 0) {
      var existingCounty = this.countyMunicipalities.find(e => e.countyId == county)
    }
    if (existingCounty) {
      this.onRemoveCounty(county)
    }

    if ((this.userForm.value.municipality.length > 0) && (this.userForm.value.municipality[0] != '0')) {
      this.userForm.value.municipality.forEach(element => {
        var obj = { countyId: county, municipalityId: element }
        this.countyMunicipalities.push(obj)
        mArray.push(this.municipalities.find(e => e.value == element))
      });
      var cArray: [] = this.counties.find(e => e.value == county)
      var Obj = { c: cArray, m: mArray, isAll: false }
      this.jsonCountyMunicipalities.push(Obj)

    } else {
      this.municipalities.forEach(element => {
        if (element.id != '0') {
          var obj = { countyId: county, municipalityId: element.id }
          this.countyMunicipalities.push(obj)
          mArray.push(this.municipalities.find(e => e.value == element.id))
        }
      });
      var cArray: [] = this.counties.find(e => e.value == county)
      var Obj = { c: cArray, m: mArray, isAll: true }
      this.jsonCountyMunicipalities.push(Obj)
    }
    this.userForm.patchValue({
      county: '',
      municipality: ''
    })
    this.isEditCounty = false;
  }

  onCancelCountyMunicipals() {
    this.userForm.patchValue({
      county: '',
      municipality: ''
    })
    this.isEditCounty = false
    this.isAllMunicipalitySelected = false
  }

  onRemoveCounty(countyId): void {
    var countyObj1 = this.jsonCountyMunicipalities.find(county => county.c.value == countyId)
    var countyObj1Index = this.jsonCountyMunicipalities.indexOf(countyObj1)
    this.jsonCountyMunicipalities.splice(countyObj1Index, 1)

    let objArr = []
    this.countyMunicipalities.forEach(element => {
      if (element.countyId == countyId) {
        objArr.push(element)
      }
    });

    objArr.forEach(ele => {
      let index = this.countyMunicipalities.indexOf(ele)
      this.countyMunicipalities.splice(index, 1);
    })
    // if (!this.isEditCounty) {
    //   this.userForm.patchValue({
    //     county: '',
    //     municipality: ''
    //   })
    // }

    if (!this.isEditCounty) {
      this.counties.forEach(element => {
        if (element.value == countyId) {
          element.disabled = false
        }
      });
    }

    this.isEditCounty = false
    this.isAllMunicipalitySelected = false
  }

  onEditCounty(countyId): void {
    var countyObj1 = this.jsonCountyMunicipalities.find(county => {
      return county.c.value == countyId
    })
    this.selectedCounty = countyObj1.c
    var municipalityArr: any[] = []
    if (countyObj1.isAll) {
      municipalityArr.push(0)
      this.isAllMunicipalitySelected = true
    } else {
      countyObj1.m.forEach(element => {
        municipalityArr.push(element.id)
      });
    }
    this.userService.getMunicipalitiesByCountyId(countyId).subscribe((data: []) => {
      this.municipalities = data
      this.municipalities.unshift({ value: 0, id: 0, code: "0000", label: "Alla Kommuner", name: "Alla Kommuner" })
      for (let [key, value] of Object.entries(this.municipalities)) {
        value['value'] = value.id;
        value['label'] = value.name;
      }
      this.userForm.patchValue({
        conuty: parseInt(countyId),
        municipality: municipalityArr
      })
      this.userForm.value.conuty = countyId
      this.isEditCounty = true;
    },
      (error: any) => this.errorMessage = error as any
    )
  }

  onAddLangSkills(): void {
    var langId = this.userForm.value.languages
    var skillLevelId = this.userForm.value.skillLevel

    var langObj = this.languageObjArray.find(element => element.languageId == langId)
    if (langObj) {
      this.onRemoveLangSkills(langId)
      var obj = { languageId: langId, skillLevelId: skillLevelId }
      this.languageObjArray.push(obj)
    } else {
      var obj = { languageId: langId, skillLevelId: skillLevelId }
      this.languageObjArray.push(obj)
    }
    this.userForm.patchValue({
      languages: '',
      skillLevel: ''
    })
  }

  getLangNameById(langId): string {
    var langObj = this.languages.find(element => element.value == langId)
    return langObj.label
  }
  getSkillLevelNameById(skillLevelId): string {
    var skillLevelObj = this.skillLevels.find(element => element.value == skillLevelId)
    return skillLevelObj.label
  }

  onRemoveLangSkills(langId): void {
    var langObj = this.languageObjArray.find(lang => lang.languageId == langId)
    var langObjIndex = this.languageObjArray.indexOf(langObj)
    this.languageObjArray.splice(langObjIndex, 1)
    this.userForm.patchValue({
      languages: '',
      skillLevel: ''
    })
  }

  getCurrentConfigData(): void {
    this.userService.getConfigData().subscribe((res) => {
      this.resourceTypes = res.resourceTypes
      this.matchingAlgorithnmFields = res.matchingAlgorithnmFields;
    })
  }

}


function resetPasswordCompare(c: AbstractControl): { [key: string]: boolean } | null {
  const pwdControl = c.get('resetpassword');
  const conPwdControl = c.get('resetconpassword');

  if (pwdControl.pristine || conPwdControl.pristine) {
    return null;
  }

  if (pwdControl.value !== conPwdControl.value) {
    return { 'match': true };
  }
  return null;
}

function passwordCompare(c: AbstractControl): { [key: string]: boolean } | null {
  const pwdControl = c.get('password');
  const conPwdControl = c.get('conpassword');

  if (pwdControl.pristine || conPwdControl.pristine) {
    return null;
  }

  if (pwdControl.value !== conPwdControl.value) {
    return { 'match': true };
  }
  return null;
}
