import { Component, OnInit, ElementRef, ViewChildren, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControlName, FormControl, AbstractControl } from '@angular/forms';
import * as jQuery from 'jQuery';
import { CalendarService } from './calendar.service';
import { AllocationInfo } from './allocation-info';
import { AvailabilityModel } from './availability-model';
import { AvailabilityInfo } from './availability-info';
import { AvailabilityUpdateModel } from './availability-update-model';
import { TimeLogModel } from './time-log-model';
import { BookingInfo } from './booking-info';
import { IUserInfo } from '../user/user-info';
import { UserService } from '../user/user.service';
import { GenericValidator } from '../shared/generic-validator';
import { Observable, merge, fromEvent, Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PartialAvailabilityModel } from './partial-availability-model';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ConfigService } from '../shared/services/config.service';
import { SignalrService } from '../shared/services/common/signalr.service';

declare var kendo: any;

@Component({
  selector: 'wfm-employee-calendar',
  templateUrl: './employee-calendar.component.html',
  styleUrls: ['./employee-calendar.component.css']
})
export class EmployeeCalendarComponent implements OnInit{
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  availabilityCheckDates: AvailabilityModel[];
  errorMessage: string;
  availabilityForm: FormGroup;
  availabilityUpdateModel: AvailabilityUpdateModel;
  allocation: BookingInfo;
  allocationTimeLogsForm: FormGroup;
  visibleTimeLogs = false;
  usersList: IUserInfo[];
  userFilter: any = { fullName: '' };
  userId: string = '';
  selectedUserFullName: string;
  currentDate = new Date();
  notificationModal: any;
  subscription: Subscription;
  partialAvailabilityForm: FormGroup;
  selectedPartialAvlDate: string;
  selectedCalendarMonth: Date = new Date();
  selectedUserInfo: IUserInfo;
  isInquiryCalendarSelected:boolean = false
  displayValidationMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  swedishHolidays:any
  holidayLastMonth:string
  allocationHistory:any;
  sendRequest:any;
  selectedUserData: any;
  newSelectedUserSub: Subscription;
  newSelectedMonthSub: Subscription;
  routeSubscription: Subscription;
  currentURL:any 
  selectedUserId: Subject<any> = new Subject<any>()
  bookingUnitName : any = '';

  deviceWidth:number = window.innerWidth;
  deviceHight:number = window.innerHeight;

  timePickerTimeList : any[]=[
    { value: '00:00', label: '00:00' },
    { value: '00:15', label: '00:15' },
    { value: '00:30', label: '00:30' },
    { value: '00:45', label: '00:45' },
    { value: '01:00', label: '01:00' },
    { value: '01:15', label: '01:15' },
    { value: '01:30', label: '01:30' },
    { value: '01:45', label: '01:45' },
    { value: '02:00', label: '02:00' },
    { value: '02:15', label: '02:15' },
    { value: '02:30', label: '02:30' },
    { value: '02:45', label: '02:45' },
    { value: '03:00', label: '03:00' },
    { value: '03:15', label: '03:15' },
    { value: '03:30', label: '03:30' },
    { value: '03:45', label: '03:45' },
    { value: '04:00', label: '04:00' },
    { value: '04:15', label: '04:15' },
    { value: '04:30', label: '04:30' },
    { value: '04:45', label: '04:45' },
    { value: '05:00', label: '05:00' },
    { value: '05:15', label: '05:15' },
    { value: '05:30', label: '05:30' },
    { value: '05:45', label: '05:45' },
    { value: '06:00', label: '06:00' },
    { value: '06:15', label: '06:15' },
    { value: '06:30', label: '06:30' },
    { value: '06:45', label: '06:45' },
    { value: '07:00', label: '07:00' },
    { value: '07:15', label: '07:15' },
    { value: '07:30', label: '07:30' },
    { value: '07:45', label: '07:45' },
    { value: '08:00', label: '08:00' },
    { value: '08:15', label: '08:15' },
    { value: '08:30', label: '08:30' },
    { value: '08:45', label: '08:45' },
    { value: '09:00', label: '09:00' },
    { value: '09:15', label: '09:15' },
    { value: '09:30', label: '09:30' },
    { value: '09:45', label: '09:45' },
    { value: '10:00', label: '10:00' },
    { value: '10:15', label: '10:15' },
    { value: '10:30', label: '10:30' },
    { value: '10:45', label: '10:45' },
    { value: '11:00', label: '11:00' },
    { value: '11:15', label: '11:15' },
    { value: '11:30', label: '11:30' },
    { value: '11:45', label: '11:45' },
    { value: '12:00', label: '12:00' },
    { value: '12:15', label: '12:15' },
    { value: '12:30', label: '12:30' },
    { value: '12:45', label: '12:45' },
    { value: '13:00', label: '13:00' },
    { value: '13:15', label: '13:15' },
    { value: '13:30', label: '13:30' },
    { value: '13:45', label: '13:45' },
    { value: '14:00', label: '14:00' },
    { value: '14:15', label: '14:15' },
    { value: '14:30', label: '14:30' },
    { value: '14:45', label: '14:45' },
    { value: '15:00', label: '15:00' },
    { value: '15:15', label: '15:15' },
    { value: '15:30', label: '15:30' },
    { value: '15:45', label: '15:45' },
    { value: '16:00', label: '16:00' },
    { value: '16:15', label: '16:15' },
    { value: '16:30', label: '16:30' },
    { value: '16:45', label: '16:45' },
    { value: '17:00', label: '17:00' },
    { value: '17:15', label: '17:15' },
    { value: '17:30', label: '17:30' },
    { value: '17:45', label: '17:45' },
    { value: '18:00', label: '18:00' },
    { value: '18:15', label: '18:15' },
    { value: '18:30', label: '18:30' },
    { value: '18:45', label: '18:45' },
    { value: '19:00', label: '19:00' },
    { value: '19:15', label: '19:15' },
    { value: '19:30', label: '19:30' },
    { value: '19:45', label: '19:45' },
    { value: '20:00', label: '20:00' },
    { value: '20:15', label: '20:15' },
    { value: '20:30', label: '20:30' },
    { value: '20:45', label: '20:45' },
    { value: '21:00', label: '21:00' },
    { value: '21:15', label: '21:15' },
    { value: '21:30', label: '21:30' },
    { value: '21:45', label: '21:45' },
    { value: '22:00', label: '22:00' },
    { value: '22:15', label: '22:15' },
    { value: '22:30', label: '22:30' },
    { value: '22:45', label: '22:45' },
    { value: '23:00', label: '23:00' },
    { value: '23:15', label: '23:15' },
    { value: '23:30', label: '23:30' },
    { value: '23:45', label: '23:45' },
]

  lunchTimeList:any[]=[
    { value: 0, label: '0' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
    { value: 45, label: '45' },
    { value: 60, label: '60' },
  ]


  constructor(private fb: FormBuilder, private calendarService: CalendarService, private userService: UserService, private router:Router,
              private signalRService: SignalrService,
              private configService : ConfigService) {
    this.validationMessages = {
      month: {
        required: 'month is required'
      },
      startDate: {
        required: 'start is required'
      },
      endDate: {
        required: 'end is required'
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);

    this.subscription = this.signalRService.receiveCalendarUpdates().subscribe(st => {
      if (st) {
        const scheduler = $('#scheduler').data('kendoScheduler');
        scheduler.dataSource.read({ userId: this.userId });
        scheduler.refresh();
      }
    });

    this.routeSubscription = this.router.events.subscribe((event: Event) => {
      this.currentURL = ''
      if (event instanceof NavigationStart ) {
        this.currentURL = ''
      }
      if (event instanceof NavigationEnd) {
        this.currentURL = event.url.split('?')[0]

        if (
          (this.currentURL == '/users/calendar/inquiries') ||
          (this.currentURL == '/clients/calendar/inquiries') ||
          (this.currentURL == '/bookings/inquiries')
        ) {
          this.isInquiryCalendarSelected = true
        } else {
          this.isInquiryCalendarSelected = false
        }
      }
    }); 
  }

  setCustomVallidations() {
    const month = this.availabilityForm.get('month');
    const start = this.availabilityForm.get('startDate');
    const end = this.availabilityForm.get('endDate');

    this.availabilityForm.get('changeTypeId').valueChanges
      .subscribe(changeType => {
        if (changeType === '1') {
          month.setValidators([Validators.required]);
          start.clearValidators();
          end.clearValidators();
        } else if (changeType === '2') {
          start.setValidators([Validators.required]);
          end.setValidators([Validators.required]);
          month.clearValidators();
        } else if (changeType === '3') {
          start.setValidators([Validators.required]);
          month.clearValidators();
          end.clearValidators();
        }
        month.updateValueAndValidity();
        start.updateValueAndValidity();
        end.updateValueAndValidity();
      });

  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.newSelectedUserSub.unsubscribe()
    this.routeSubscription.unsubscribe()
    this.newSelectedMonthSub.unsubscribe()

  }

  ngOnInit() {
    kendo.culture('sv-SE');
    this.currentDate.setHours(0, 0, 0, 0);
    // this.initalizaForms();

    this.allocation = {
      isExpAreaValidationFail:null,
      id: 0,
      title: '',
      sDate: '',
      eDate: '',
      lunch: 0,
      isPastDate: false,
      bookingTotal: 0,
      start: new Date(),
      end: new Date(),
      empName: '',
      empImage: '',
      empJobCategoryId: 0,
      empJobCategory: '',
      clientName: '',
      clientContactName: '',
      clientAddress: '',
      clientPhone: '',
      clientEmail: '',
      isTimeLogged: false,
      timeLogStartTime: '',
      timeLogEndTime: '',
      timeLogLunch: 0,
      timeLogTotal: 0,
      colorClass: 'slot-blue',
      timeLoggedClass: 'slot-no-check',
      empImagePath: '',
      clientImagePath: '',
      resourceTypeId: 1,
      resourceTypeClass: '',
      isInquiry:false,
      clientId:'',
      resourceTypeIconLetter:''
    };

    this.notificationModal = {
      header: '',
      description: '',
      type: ''
    };

    this.userService.getAllocatedUserList('Employee',0).subscribe(
      (res: IUserInfo[]) => {
        this.usersList = res;
        
      },
      (error: any) => this.errorMessage = error as any
    );

    this.newSelectedUserSub= this.userService.newSelectedUser.subscribe((data)=>{
      this.loadCalendar(data);
    })
    this.newSelectedMonthSub= this.calendarService.selectedMonth.subscribe((data)=>{
      this.selectedCalendarMonth = data
      this.getBookingsCount();
    })

    // this.setCustomVallidations();

    // this.partialAvailabilityForm.get('avlTypeId').valueChanges
      // .subscribe(avlType => {
      //   if (avlType === '1') {
      //     $('#kendo-prl-start').data('kendoTimePicker').value('');
      //     $('#kendo-prl-end').data('kendoTimePicker').value('');
      //     $('#kendo-prl-start').data('kendoTimePicker').enable(false);
      //     $('#kendo-prl-end').data('kendoTimePicker').enable(false);
      //   } else {
      //     $('#kendo-prl-start').data('kendoTimePicker').enable(true);
      //     $('#kendo-prl-end').data('kendoTimePicker').enable(true);
      //   }
      // });
    this.selectedUserInfo = this.userService.initializeUser();
    this.getBookingsCount();

    // $(window).resize(()=>{
    //   const scheduler = $('#scheduler').data('kendoScheduler');
    //   scheduler.dataSource.read({ userId: this.userId });
    //   scheduler.refresh();
    // })
  }



  getBookingsCount(): void {
    this.calendarService.getEmployeeBookingsCount(this.userId, this.selectedCalendarMonth).subscribe(
      (data: IUserInfo) => {
        this.selectedUserInfo = data;
      },
      (error: any) => this.errorMessage = error as any
    );
  }

  onClickAvailabilityUpdate(){
    this.calendarService.emmitOnClickAvailabilityUpdate();
  }

  updateAvailability(): void {
    ($('#updateAvailabilityModal') as any).modal('hide');

    const scheduler = $('#scheduler').data('kendoScheduler');
    const container = scheduler.view().element;
    const cells = container.find('td[role=gridcell]');
    this.availabilityCheckDates = [];

    for (let i = 0; i < cells.length; i++) {
      const cell = $(cells[i]);
      const slot = scheduler.slotByElement(cell);
      const avl = {} as AvailabilityModel;
      avl.category = scheduler.view().name;
      avl.startTime = slot.startDate;
      avl.endTime = slot.endDate;
      avl.startTimeString = avl.startTime.getFullYear() + '-' + (avl.startTime.getMonth() + 1) + '-' + avl.startTime.getDate();
      avl.endTimeString = avl.endTime.getFullYear() + '-' + (avl.endTime.getMonth() + 1) + '-' + avl.endTime.getDate();
      this.availabilityCheckDates.push(avl);

    }

    const avlForm = this.availabilityForm;

    this.availabilityUpdateModel = {} as AvailabilityUpdateModel;
    this.availabilityUpdateModel.changeType = avlForm.get('changeTypeId').value;
    this.availabilityUpdateModel.month = avlForm.get('month').value;
    this.availabilityUpdateModel.fromDate = avlForm.get('startDate').value;
    this.availabilityUpdateModel.toDate = avlForm.get('endDate').value;
    this.availabilityUpdateModel.availability = avlForm.get('availabilityId').value;
    this.availabilityUpdateModel.dateList = this.availabilityCheckDates;

    if (this.availabilityUpdateModel.changeType === '1') {
      const month = this.availabilityUpdateModel.month;
      this.availabilityUpdateModel.monthString = month.getFullYear() + '-' + (month.getMonth() + 1) + '-' + month.getDate();
    } else if (this.availabilityUpdateModel.changeType === '2') {
      const fromDate = this.availabilityUpdateModel.fromDate;
      const toDate = this.availabilityUpdateModel.toDate;
      this.availabilityUpdateModel.fromDateString = fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate();
      this.availabilityUpdateModel.toDateString = toDate.getFullYear() + '-' + (toDate.getMonth() + 1) + '-' + toDate.getDate();
    } else {
      const fromDate = this.availabilityUpdateModel.fromDate;
      this.availabilityUpdateModel.fromDateString = fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate();
    }

    this.calendarService.updateAvailability(this.availabilityUpdateModel).subscribe(
      (avlData: AvailabilityInfo[]) => {
        // this.initalizaForms();
        // this.setCustomVallidations();
        $('#kendo-month').data('kendoDatePicker').value('');
        $('#kendo-start-date').data('kendoDatePicker').value('');
        $('#kendo-end-date').data('kendoDatePicker').value('');
        this.setNotificationData('availability', true);
        scheduler.dataSource.read({ userId: this.userId });
        this.signalRService.broadcastCalendarUpdates();
      },
      (error: any) => this.errorMessage = error as any
    );

  }


  getJsonDateFormat(date:Date):string{
    return date.getFullYear()+''+(date.getMonth()+1>9? date.getMonth()+1:'0'+(date.getMonth()+1))
  }


  userSelect(userId: string, userType: string, fullName: string): void {
    this.selectedUserData = {
      userId,
      userType,
      fullName
    };
    
    $('.employee-wrapper').removeClass('employee-wrapper-active');
    $('#employee-wrapper-' + userId).addClass('employee-wrapper-active');
    
    this.userService.emmitNewSelectedUser(this.selectedUserData);
    this.getBookingsCount();
  }

  loadCalendar(data){
   
    if ( data==null || (this.userId == data.userId)) {
      this.userId = 'all';
      this.selectedUserFullName = null;
      // this.selectedUserId.next(null)
      // $('#employee-wrapper-' + data.userId).removeClass('employee-wrapper-active');
    } else {
      this.userId = data.userId;
      this.selectedUserFullName = data.fullName;
      // this.selectedUserId.next(this.userId)
    }
    // if(this.isInquiryCalendarSelected){
    //   const scheduler = $('#inquiry-scheduler').data('kendoScheduler');
    //   scheduler.dataSource.read({ userId: this.userId });
    //   scheduler.refresh();
    // }else{
    //   const scheduler = $('#scheduler').data('kendoScheduler');
    //   scheduler.dataSource.read({ userId: this.userId });
    //   scheduler.refresh();
    // }
  }

  setNotificationData(flow, status): void {
    if (flow === 'availability') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Tillgänglighet uppdaterades';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Uppdatering av tillgänglighet misslyckades! Var god försök igen';
        this.notificationModal.type = 'danger';
      }
    }
    if (flow === 'timelog') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Tidrapportering uppdaterades';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Uppdatering av tidrapportering misslyckades! Var god och försök igen';
        this.notificationModal.type = 'danger';
      }
    }
    if (flow === 'partialavl') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Tillgänglighet uppdaterades';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Uppdatering av tillgänglighet misslyckades! Var god försök igen';
        this.notificationModal.type = 'danger';
      }
    }
    ($('#notificationModal') as any).modal('show');
    setTimeout(() => {
      ($('#notificationModal') as any).modal('hide');
    }, 3000);
  }


  scrollToHour(hour): void {
    const time = new Date();
    time.setHours(hour);
    time.setMinutes(0);
    time.setSeconds(0);
    time.setMilliseconds(0);

    const scheduler = $('#scheduler').data('kendoScheduler');
    const contentDiv = scheduler.element.find('div.k-scheduler-content');
    const rows = contentDiv.find('tr');

    for (let i = 0; i < rows.length; i++) {
      const element = $(rows[i]);
      const slot = scheduler.slotByElement(element);

      const slotTime = kendo.toString(slot.startDate, 'HH:mm');
      const targetTime = kendo.toString(time, 'HH:mm');

      if (targetTime === slotTime) {
        contentDiv.scrollTop(0);

        const elementTop = element.offset().top;
        const containerTop = contentDiv.offset().top;

        contentDiv.scrollTop(elementTop - containerTop);
      }
    }
  }


  onCalendarChange(calendar:string): void {
    if (calendar==='inquiry') {
      $('.btn-calendar-booking').removeClass('btn-calendar-active')
      $('.btn-calendar-booking').addClass('btn-calendar-inactive')
      $('.btn-calendar-inquiry').removeClass('btn-calendar-inactive')
      $('.btn-calendar-inquiry').addClass('btn-calendar-active')
      this.isInquiryCalendarSelected=true
    } else {
      $('.btn-calendar-inquiry').removeClass('btn-calendar-active')
      $('.btn-calendar-inquiry').addClass('btn-calendar-inactive')
      $('.btn-calendar-booking').removeClass('btn-calendar-inactive')
      $('.btn-calendar-booking').addClass('btn-calendar-active')
      // const scheduler = $('#scheduler').data('kendoScheduler');
      // scheduler.refresh();
      // this.schedulerDataBound(scheduler);
      // setTimeout(() => {
      //   // this.schedulerDataBound(scheduler);
      //   scheduler.refresh()
      // }, 200);
      this.isInquiryCalendarSelected=false
      this.selectedCalendarMonth = new Date();
      this.getBookingsCount();
    }
  }

  onMobileTabSelect(event){
    if (event=='userlist') {
      $('#myTab').css('display', 'flex')
      $('#myTabContent').css('display', 'block')
    } else if (event=='bookings') {
      $('#myTab').css('display', 'none')
      $('#myTabContent').css('display', 'none')
      $('.btn-calendar-booking').click()
    } else if (event=='inquiry') {
      $('#myTab').css('display', 'none')
      $('#myTabContent').css('display', 'none')
      $('.btn-calendar-inquiry').click()
    }
  }

  closeDrawer(){
    if( $('.mob-bookings').hasClass('last-selection')){
     var tab = 'bookings'
    }else if($('.mob-inquiry').hasClass('last-selection')){
      var tab = 'inquiry'
    }
    $('.mob-tab').removeClass('btn-success')
    $('.mob-tab').addClass('tab-inactive')
    if (tab=='userlist') {
      $('.mob-userlist').addClass('btn-success')
      $('.mob-userlist').removeClass('tab-inactive')
    } else if (tab=='bookings') {
      $('.mob-bookings').addClass('btn-success')
      $('.mob-bookings').removeClass('tab-inactive')
    } else if (tab=='inquiry') {
      $('.mob-inquiry').addClass('btn-success')
      $('.mob-inquiry').removeClass('tab-inactive')
    }
    $('#myTab').css('display', 'none')
    $('#myTabContent').css('display', 'none')
    setTimeout(() => {
      $('.k-scheduler-navigation').css('pointer-events', 'all');
      $('.k-scheduler-navigation').css('opacity', '1');
    }, 500);
  }

  onViewHistoryBtnClick(allocationId: number) {
    this.toggleSendRequest();
    if (this.sendRequest) {
      this.calendarService.getAllocationHistory(allocationId).subscribe(
        (res) => {
            this.allocationHistory = res;
        },
        (error: any) => this.errorMessage = error as any
    );
    }
    
  }
  toggleSendRequest(){
    this.sendRequest = !this.sendRequest
  }

  getBookingUnit()
  {
      this.configService.getBookingUnits().subscribe(res => {
        if(res)
        {
          this.bookingUnitName = (res.bookingUnits.length > 0) ? res.bookingUnits[0].name : '';
        }
    });
  }
}
