import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UserService } from '../user/user.service';
import { IUserInfo } from '../user/user-info';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReportInfo } from './report-info';
import { ReportSearchCriteria } from './report-search-criteria';
import { CalendarService } from '../calendar/calendar.service';
import { ConfigService } from '../shared/services/config.service';

@Component({
  selector: 'wfm-user-report',
  templateUrl: './user-report.component.html',
  styleUrls: ['./user-report.component.css']
})
export class EmployeeReportComponent implements OnInit, AfterViewInit {

  userType = 'Employee';
  errorMessage: string;
  usersList: IUserInfo[];
  searchForm: FormGroup;
  reportInfo: ReportInfo = {} as ReportInfo;
  cashierReportInfo: ReportInfo = {} as ReportInfo;
  searchCriteria: ReportSearchCriteria = {} as ReportSearchCriteria;
  showNoRecordMessage: boolean;
  resourceTypes:any[]
  reportInfoList: any[];
  firstReportInfo: any;
  bookingUnitName : any = '';

  constructor(private fb: FormBuilder, 
    private userService: UserService,    
    private calendarService: CalendarService,
    private configService : ConfigService    ) { }

  ngOnInit() {
    this.searchForm = this.fb.group({
      userType: [],
      userId: [],
      month: []
    });
    this.reportInfo.timeRecords = [];
    this.cashierReportInfo.timeRecords = [];
    this.getCurrentConfigData();
    this.getBookingUnit();
  }

  ngAfterViewInit(): void {

    ($('#kendo-month') as any).kendoDatePicker({
      format: 'MMMM yyyy',
      start: 'year',
      depth: 'year',
      change: (val) => {
        this.searchForm.patchValue({
          month: val.sender._value
        });
      }
    });

    ($('.kendo-picker') as any).bind('focus', function () {
      $(this).data('kendoDatePicker').open();
    });
    $("#kendo-month").data("kendoDatePicker").value(new Date());
    this.searchForm.patchValue({
      month: new Date()
    });
  }


  generateReport(): void {
    const validator = ($('#reportSearchForm') as any).kendoValidator().data('kendoValidator');
    if (validator.validate()) {
      this.searchCriteria.month = this.searchForm.get('month').value;

      this.userService.getUserReportData(this.searchCriteria, this.userType).subscribe(
        (res) => {
          this.reportInfoList = res;
          this.showNoRecordMessage = true;
          for (const resourceTypeId in this.reportInfoList) {
            if (Object.prototype.hasOwnProperty.call(this.reportInfoList, resourceTypeId)) {
              const element = this.reportInfoList[resourceTypeId];
              if (resourceTypeId =='1' || resourceTypeId =='0') {
                this.firstReportInfo = element;
              }
                this.showNoRecordMessage = this.showNoRecordMessage && element.timeRecords.length==0;
            }
          }

          // this.reportInfo = res;
          // if (this.reportInfo.timeRecords.length === 0) {
          //   this.showNoRecordMessage = true;
          // } else {
          //   this.showNoRecordMessage = false;
          // }
        },
        (error: any) => this.errorMessage = error as any
      );
    }

  }

  getCurrentConfigData(): void {
    this.userService.getConfigData().subscribe((res) => {
       console.log(res);
      this.resourceTypes = res.resourceTypes;
    })
  }

  getResourceTypeName(id){
    return (this.resourceTypes.find(x => x.id == id)).displayName
  }
  
  checkItemExists(hourlyWorkedPecentages, percentage)
  {

    if(hourlyWorkedPecentages.length > 0)
    {
      var item = hourlyWorkedPecentages.filter(p =>p.typeOfPercentage == percentage);

      if(item.length > 0)
      {
        return item[0].workedHours ;
      }
    }
   
    return 0;
  }
  getBookingUnit()
  {
      this.configService.getBookingUnits().subscribe(res => {
        if(res)
        {
          this.bookingUnitName = (res.bookingUnits.length > 0) ? res.bookingUnits[0].name : '';
        }
    });
  }

}
