import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminCalendarComponent } from './admin-calendar.component';
import { SharedModule } from '../shared/shared.module';
import { ClientCalendarComponent } from './client-calendar.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EmployeeCalendarComponent } from './employee-calendar.component';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { SuperclientCalendarComponent } from './superclient-calendar/superclient-calendar.component';
import { EmployeeInquiryCalenderComponent } from './employee-inquiry-calender/employee-inquiry-calender.component';
import { UserModule } from '../user/user.module';
import { Select2Module } from 'ng-select2-component';
import { AdminInquiryCalendarComponent } from './admin-inquiry-calendar/admin-inquiry-calendar.component';
import { BookingCalendarComponent } from './booking-calendar/booking-calendar.component';
import { RouterModule, Routes } from '@angular/router';
import { LongBookingComponent } from './long-booking/long-booking.component';
import { TemplateListComponent } from './long-booking/template-list/template-list.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CalendarModule } from "primeng/calendar";
import { StarRatingModule } from 'angular-star-rating';
import { LongBookingListComponent } from './long-booking/longbooking-list/longbooking-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ShortBookingListComponent } from './booking-calendar/shortbooking-list/shortbooking-list.component';

@NgModule({
  declarations: [ShortBookingListComponent,LongBookingListComponent,TemplateListComponent, AdminCalendarComponent, ClientCalendarComponent, EmployeeCalendarComponent, SuperclientCalendarComponent, EmployeeInquiryCalenderComponent, AdminInquiryCalendarComponent, BookingCalendarComponent, LongBookingComponent],
  imports: [

    StarRatingModule.forRoot(),
    ReactiveFormsModule,
   // DateInputsModule,
    NgSelectModule,
    CommonModule,
    SharedModule,
    FormsModule,
    FilterPipeModule,
    UserModule,
    Select2Module,
    CalendarModule,
    RouterModule,
    NgxPaginationModule
  ],
  exports: [
    RouterModule
  ]
})
export class CalendarModuleA { }
