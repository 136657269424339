// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// npm install  @angular/cli@8.3.10

export const environment = {
  production: false,
  
  // tokenUrl: 'http://localhost:52241/wfm/token',
  // apiEndpoint: 'http://localhost:52241/wfm/api/',
  // signalRhub: 'http://localhost:52241/wfm/signalr'

  // Din Distribution
  // tokenUrl: 'http://uatapi.dindistribution.pharmmatch.se/token', 
  // apiEndpoint: 'http://uatapi.dindistribution.pharmmatch.se/api/',
  // signalRhub: 'http://uatapi.dindistribution.pharmmatch.se/signalr'

  //Teamplan  
  // tokenUrl: 'http://uatapi.teamplan.se/token',
  // apiEndpoint: 'http://uatapi.teamplan.se/api/',
  // signalRhub: 'http://uatapi.teamplan.se/signalr'

 // Pharmatch Teamplan
  tokenUrl: 'https://api.pharmmatch.teamplan.se/token',
  apiEndpoint: 'https://api.pharmmatch.teamplan.se/api/',
  signalRhub: 'https://api.pharmmatch.teamplan.se/signalr'

  // Demo Teamplan

  // tokenUrl: 'https://api.demo.teamplan.se/token',
  // apiEndpoint: 'https://api.demo.teamplan.se/api/',
  // signalRhub: 'https://api.demo.teamplan.se/signalr'


  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
