import { Injectable } from '@angular/core';
import { IUserInfo  } from './user-info';
import { of, Observable, throwError, Subject, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { IUserSearchCriteria } from './user-search-criteria';
import { IPagedResult } from '../shared/models/common/paged-result';
import { ReportInfo } from '../report/report-info';
import { ReportSearchCriteria } from '../report/report-search-criteria';
import { environment } from '../../environments/environment';
import { ApiActionName } from '../shared/constants/enums/action-names';

const API_URL = environment.apiEndpoint;

const httpOptions = {
  headers: new HttpHeaders ({
    'Content-Type': 'application/json'
  }),
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userInfo: IUserInfo = {} as IUserInfo;
  userInfoList: IUserInfo;
  private subject = new Subject<any>();
  selectedUserId: BehaviorSubject<any> = new BehaviorSubject<any>({ id: 'all', type: 'all' })
  newSelectedUser: BehaviorSubject<any> = new BehaviorSubject<any>(null)

  constructor(private http: HttpClient) {
    
   }

  createUser(user: IUserInfo): Observable<string> {
    user.userId = null;
    return this.http.post<string>(API_URL + 'user/create', user, httpOptions)
      .pipe(
        tap(data => {
        }),
        catchError(this.handleError)
      );
  }

  getEmployeeHourlyRateDetails(userId: string): Observable<any> {
    let url = "?userId=" + userId;
    return this.http.get<any>(API_URL + ApiActionName.GetEmployeeHourlyRate + url).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  updateEmployeeHourlyRateDetails(request: any): Observable<any> {
    return this.http.post<any>(API_URL + ApiActionName.UpdateEmployeeHourlyRate, request, httpOptions)
  }

  createSubClientUser(user: IUserInfo): Observable<string> {
    user.userId = null;
    return this.http.post<string>(API_URL + 'user/superclient/create', user, httpOptions)
      .pipe(
        tap(data => {
        }),
        catchError(this.handleError)
      );
  }

  updateUser(user: IUserInfo): Observable<IUserInfo> {
    return this.http.post<IUserInfo>(API_URL + 'user/update', user, httpOptions)
      .pipe(
        tap(data => {
        }),
        catchError(this.handleError)
      );
  }

  updateSubClientUser(user: IUserInfo): Observable<IUserInfo> {
    return this.http.post<IUserInfo>(API_URL + 'user/superclient/update', user, httpOptions)
      .pipe(
        tap(data => {
        }),
        catchError(this.handleError)
      );
  }

  updateMyProfile(user: IUserInfo): Observable<IUserInfo> {
    return this.http.post<IUserInfo>(API_URL + 'user/profile/update', user, httpOptions)
      .pipe(
        tap(data => {
        }),
        catchError(this.handleError)
      );
  }

  updateUserStatus(userId: string, status: number): Observable<boolean> {
    const headersS = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post<boolean>(API_URL + 'user/statusupdate?userId=' + userId + '&status=' + status , { headers: headersS })
      .pipe(
        tap(data => {
        }),
        catchError(this.handleError)
      );
  }

  getById(id: string, userTypeId = ""): Observable<IUserInfo> {
    if (id === '0') {
      return of(this.initializeUser(userTypeId));
    }
    const url = `${API_URL}/user?id=${id}`;
    return this.http.get<IUserInfo>(url)
      .pipe(
        tap(data => {
        }),
        catchError(this.handleError)
      );
  }

  getMyProfile(): Observable<IUserInfo> {
    const url = `${API_URL}/user/profile`;
    return this.http.get<IUserInfo>(url)
      .pipe(
        tap(data => {
        }),
        catchError(this.handleError)
      );
  }

  getByName(username: string): Observable<IUserInfo> {
    return this.http.get<IUserInfo>(API_URL + 'user?username=' + username, httpOptions).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  getAll(searchCriteria: IUserSearchCriteria): Observable<IPagedResult> {
    return this.http.post<IPagedResult>(API_URL + 'user/getall', searchCriteria,  httpOptions).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  getAllSubClients(searchCriteria: IUserSearchCriteria): Observable<IPagedResult> {
    return this.http.post<IPagedResult>(API_URL + 'user/superclient/getall', searchCriteria,  httpOptions).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  getUserList(userType: string): Observable<IUserInfo[]> {
    return this.http.get<IUserInfo[]>(API_URL + 'user/list?userType=' + userType,  httpOptions).pipe(
      tap(resp => {
        // resp.forEach((r) => {
        //   r.fullName = r.userType == 'Employee' ? (r.fullName) : (r.clientName);
        // });
      }),
      catchError(this.handleError)
    );
  }

  getReportUserList(month:String,userType:String): Observable<IUserInfo[]> {
    return this.http.get<IUserInfo[]>(API_URL + `user/listforreports?month=${month}&userType=${userType}`,  httpOptions).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  getSubClientUserList(userType: string): Observable<IUserInfo[]> {
    return this.http.get<IUserInfo[]>(API_URL + 'user/superclient/list?userType=' + userType,  httpOptions).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  getAdminUserList(userType: string, selectedMonth: Date, empSubType: number): Observable<IUserInfo[]> {
    if (selectedMonth === null) {
      selectedMonth = new Date();
    }
    const dateString = selectedMonth.getFullYear() + '-' + (selectedMonth.getMonth() + 1) + '-' + selectedMonth.getDate();
    const urlPart = 'user/calendar-list?userType=' + userType + '&selectedMonth=' + dateString + '&resourceTypeId='+empSubType;
    return this.http.get<IUserInfo[]>(API_URL + urlPart,  httpOptions).pipe(
      tap(resp => {
        // resp.forEach((r) => { 
        //   r.fullName = r.userType == 'Employee' ? (r.fullName) : (r.clientName);
        // });
      }),
      catchError(this.handleError)
    );
  }

  getSuperClientUserList(selectedMonth: Date, resourceTypeId: number): Observable<IUserInfo[]> {
    if (selectedMonth === null) {
      selectedMonth = new Date();
    }
    const dateString = selectedMonth.getFullYear() + '-' + (selectedMonth.getMonth() + 1) + '-' + selectedMonth.getDate();
    const urlPart = 'user/superclient/calendar-list?selectedMonth=' + dateString + '&resourceTypeId='+resourceTypeId;
    return this.http.get<IUserInfo[]>(API_URL + urlPart,  httpOptions).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  getAllocatedUserList(userType: string, resourceTypeId:number): Observable<IUserInfo[]> {
    return this.http.get<IUserInfo[]>(API_URL + 'user/list/allocated?userType=' + userType +'&resourceTypeId='+resourceTypeId,  httpOptions).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  getReportData(searchCriteria: ReportSearchCriteria): Observable<any> {
    return this.http.post<ReportInfo>(API_URL + 'reports/details', searchCriteria,  httpOptions).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  getSuperClientReportData(searchCriteria: ReportSearchCriteria): Observable<any> {
    return this.http.post<ReportInfo>(API_URL + 'reports/superclient/details', searchCriteria,  httpOptions).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  getUserReportData(searchCriteria: ReportSearchCriteria, userType: string): Observable<any> {
    const endpointUrl = userType === 'Employee' ? 'reports/employee' : 'reports/client';
    return this.http.post<ReportInfo>(API_URL + endpointUrl, searchCriteria,  httpOptions).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  updateSalaryInvoiceSentDetails(searchCriteria: ReportSearchCriteria): Observable<boolean> {
    return this.http.post<boolean>(API_URL + 'reports/reports/updatepayments', searchCriteria,  httpOptions).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  uploadUserImage(formData: FormData): Observable<boolean> {
    return this.http.post<boolean>(API_URL + 'user/update/image', formData).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  uploadProfileImage(formData: FormData): Observable<string> {
    return this.http.post<string>(API_URL + 'user/profile/image', formData).pipe(
      tap(resp => {
        this.subject.next({ path: resp });
      }),
      catchError(this.handleError)
    );
  }

  uploadUserFile(formData: FormData): Observable<string> {
    return this.http.post<string>(API_URL + 'user/profile/image', formData).pipe(
      tap(resp => {
        this.subject.next({ path: resp });
      }),
      catchError(this.handleError)
    );
  }

  removeUserFile(fileId: number): Observable<boolean> {
    return this.http.post<boolean>(API_URL + 'allocations/remove?allocationId=' + fileId, httpOptions).pipe(
        tap(resp => {

        }),
        catchError(this.handleError)
    );
}

getUserFiles(userId:string): Observable<any[]> {
  return this.http.get<any[]>(API_URL + 'user/list/allocated?userType=' + userId,  httpOptions).pipe(
    tap(resp => {
    }),
    catchError(this.handleError)
  );
}




  resetPassword(formData: FormData): Observable<boolean> {
    return this.http.post<boolean>(API_URL + 'user/update/pwd', formData).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  resetProfilePassword(formData: FormData): Observable<boolean> {
    return this.http.post<boolean>(API_URL + 'user/profile/resetpassword', formData).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  submitForgotPasswordEmail(formData:FormData): Observable<boolean> {
    return this.http.post<boolean>(API_URL + 'user/getforgotpasswordlink', formData).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }
  submitResetPassword(formData:FormData): Observable<boolean> {
    return this.http.post<boolean>(API_URL + 'user/resetpassword', formData).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }


  checkUsername(username: string, userId: string): Observable<boolean> {
    const aurl =  (userId && userId !== '0') ? ('user/checkusername?username=' + username + '&userId=' + userId)
    :  ('user/checkusername?username=' + username);
    return this.http.get<boolean>(API_URL + aurl,  httpOptions).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occured: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }

  reveiveImageUpdateNotification(): Observable<any> {
    return this.subject.asObservable();
  }

  initializeUser(userTypeId = ''): IUserInfo {
    return {
      userId: '0',
      userName: '',
      firstName: '',
      lastName: '',
      clientName: '',
      pharmacyId: '',
      subClients: [],
      address: '',
      city: '',
      zip: '',
      email: '',
      phoneNumber: '',
      image: '',
      jobCategoryId: '',
      jobCategory: '',
      resourceTypeId: '',
      resourceType: '',
      isActive: true,
      userTypeId: userTypeId,
      userType: '',
      fullName: '',
      imagePath: '',
      actualHoursBooked: null,
      totalCalculatedHours: null,
      county:'',
      municipality:'',
      countyMunicipalities:[],
      pharmacySystems:[],
      isStaredEmployeesOnly:null,
      employeeNo:'',
      legitimationId:null,
      experienceAreas:[],
      yearsOfExperience:null,
      isStdProceduresRead:null,
      aboutMe:'',
      languages:[],
      cmJson:'',
      favouriteEmployees:[]
    };
  }

  getNotAllocatedSubClients(): Observable<any> {
    return this.http.get<any>(API_URL + 'user/getnotallocatedsubclients',  httpOptions).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  
  getMunicipalitiesByCountyId(countyId:number): Observable<any> {
    return this.http.get<any>(API_URL+'user/municipalities?countyId='+countyId).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  getInnitialMasterData(): Observable<any> {
    return this.http.get<any>(API_URL+`user/initialdata?culture=sv-SE`).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  switchFavouriteEmployee(employeeId:string, isRemove:boolean): Observable<any> {
    var path:String = isRemove? 'removefavourites':'addfavourites'
    return this.http.get<any>(API_URL+`user/client/${path}?employeeId=${employeeId}`).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  getInquiryAssignUserList(allocationObj):Observable<any>{
    return this.http.post(API_URL + '/allocations/getemployeesForinquiry', allocationObj, httpOptions)
      .pipe(
        tap(data => {
        }),
        catchError(this.handleError)
      );
  }

  emmitSelectedUserId(data){
    this.selectedUserId.next(data);
  }

  emmitNewSelectedUser(data){
    this.newSelectedUser.next(data);
  }

  saveConfigurations(formData: FormData){
    return this.http.post<boolean>(API_URL + 'user/resourcetypes/update',  formData).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  getConfigData(){
    return this.http.get<any>(API_URL + 'user/resourcetypes').pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }


}
