
import { Component, OnInit, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CalendarService } from '../../calendar/calendar.service';
import { EMPTY, Subscription } from 'rxjs';
import { UserService } from '../../user/user.service';
import { ActivatedRoute } from '@angular/router';
import { SignalrService } from 'src/app/shared/services/common/signalr.service';
import { LongBookingTemplate } from 'src/app/shared/models/long-booking/long-booking-template.model';
import { LongBookingService } from 'src/app/shared/services/domain/booking/long-booking.service';
import { EmployeeAvailbleDates, LongBooking } from 'src/app/shared/models/long-booking/long-booking.model';
import { DatePipe } from '@angular/common';
import { DateDto } from 'src/app/shared/models/long-booking/date.model';
import { LongBookingShift } from 'src/app/shared/models/long-booking/long-booking-shift.model';
import { LongBookingShiftDay } from 'src/app/shared/models/long-booking/long-booking-shift-day';
import { LongBookingEmployeeModel } from 'src/app/shared/models/long-booking/long-booking-employee-model';
import { AlliocationModel } from '../alliocation-model';
import { catchError, filter } from 'rxjs/operators';
import { IUserInfo } from 'src/app/user/user-info';
import { LongBookingWeek } from 'src/app/shared/models/long-booking/long-booking-week.model';
import { ConfigService } from 'src/app/shared/services/config.service';
import { ToastrService } from 'ngx-toastr';

declare var kendo: any;

@Component({
    selector: 'wfm-long-booking',
    templateUrl: './long-booking.component.html',
    styleUrls: ['./long-booking.component.css']
})
export class LongBookingComponent implements OnInit, OnDestroy, AfterViewInit {

    title = 'FormArray SetValue & PatchValue Example';
    isMobile: boolean = window.innerWidth < 768 ? true : false;

    teachersForm: FormGroup;
    shiftTimeDetails: ShiftTimeDetails[] = []; // shiftTime week wise calculation
    uniqueShiftTimeDetails: ShiftTimeDetails[] = []; // unique shiftTime calculation

    selectedDates: Date[] = [];
    bookingsForm: FormGroup;
    role: string;
    invalidDevientDates: any[] = [];
    invalidExcludedDates: any[] = [];
    disableAvvikandeDagarButton: boolean = false;
    userType: string;
    workingDays: any[] = [];
    excludedDays: { id: number, date: Date }[] = [];
    isInvalidExcludeDate: boolean;
    invalidDevientDate: boolean;
    avvikandeDagarDatesDays: Date[] = [];
    overTimePeriods: any[] = [];
    timeSlots = [];
    enableAddShiftButton: boolean;
    disableAddTemplate: boolean = true;
    templateList: LongBookingTemplate[] = [];
    showAvvikandeDates: boolean = false;
    alliocationModel: AlliocationModel = new AlliocationModel();
    selectedLongBookingShift: any;
    disableSaveButton: boolean = true;
    showEditButton: boolean = false;
    selectedLongBookingId: number = 0;
    selectedTemplateId: number = 0;
    clientId: string;
    selectedLongBookingForEdit: LongBooking;
    selectedTemplate: any;
    employeeDropdownClicked: boolean;
    templateSelected: boolean;
    validShiftTime: boolean;
    shiftNumber: number = 2;
    shiftId: number = 1;
    // configClientLongBookingPeriod: number;
    configClientOverrideLongBookingDateRule: boolean;

    swapUserForm: FormGroup;
    buyTicketForm: FormGroup;
    allocationTimeLogsForm: FormGroup;
    allocationFormLongBooking: FormGroup;
    allocationFormForGetMatchedEmployees: FormGroup
    allocationTimeUpdateForm: FormGroup;
    selectedUserFullName: string;
    selectedUserAllocationTimes: string;
    notificationModal: any;
    availabilityForm: FormGroup;
    partialAvailabilityForm: FormGroup;

    clientCommonTimes: any[] = [];
    selectedCitiesIds: any[] = [];
    availableEmployees: any[] = []
    totalShiftTime: string;
    isWeekend = true;
    isBookingSelected = true;
    IsOnChangeEvent: boolean = false;
    onClickMakeLongBookingSub: Subscription;
    disableCreateButton: boolean;
    longbookIsCreating: boolean;
    avvikandeMinDate: Date;
    avvikandeMaxDate: Date;
    validLongBookingDates: boolean;
    shiftsTotalTimes: LongBookingShift[] = [];
    totalShiftTimeCalculated: number = 0;
    totalShiftTimeCalculatedText: string = '';


    lunchTimeList: any[] = [
        { value: 0, label: '0' },
        { value: 15, label: '15' },
        { value: 20, label: '20' },
        { value: 30, label: '30' },
        { value: 45, label: '45' },
        { value: 60, label: '60' },
    ];

    selectedEmployees: string[] = [];
    employeeNotAvailbleDates: EmployeeAvailbleDates[] = [];
    employees: any[] = [
        { value: '611ddd08-cac5-4546-abcb-26b69d7d9988', label: 'Daniel Lorrysjorn' },
        { value: 'f0c1765e-a37b-47d2-a1ec-c4cddd97417d', label: 'Harrald Steven' },
    ];
    en: any;

    newSelectedUserSub: Subscription;
    onResourceTypeChangeSub: Subscription;

    constructor(private datepipe: DatePipe,
        private toastr: ToastrService,
        private configService: ConfigService,
        private longBookingService: LongBookingService,
        private fb: FormBuilder, private calendarService: CalendarService,
        private signalRService: SignalrService, private userservice: UserService, private route: ActivatedRoute,
        private elementRef: ElementRef) {
        this.showEditButton = false;

        this.timeSlots = [
            { id: '1', name: '00:00' },
            { id: '2', name: '00:15' },
            { id: '3', name: '00:30' },
            { id: '4', name: '00:45' },
            { id: '5', name: '01:00' },
            { id: '6', name: '01:15' },
            { id: '7', name: '01:30' },
            { id: '8', name: '01:45' },
            { id: '9', name: '02:00' },
            { id: '10', name: '02:15' },
            { id: '11', name: '02:30' },
            { id: '12', name: '02:45' },
            { id: '13', name: '03:00' },
            { id: '14', name: '03:15' },
            { id: '15', name: '03:30' },
            { id: '16', name: '03:45' },
            { id: '17', name: '04:00' },
            { id: '18', name: '04:15' },
            { id: '19', name: '04:30' },
            { id: '20', name: '04:45' },
            { id: '21', name: '05:00' },
            { id: '22', name: '05:15' },
            { id: '23', name: '05:30' },
            { id: '24', name: '05:45' },
            { id: '25', name: '06:00' },
            { id: '26', name: '06:15' },
            { id: '27', name: '06:30' },
            { id: '28', name: '06:45' },
            { id: '29', name: '07:00' },
            { id: '30', name: '07:15' },
            { id: '31', name: '07:30' },
            { id: '32', name: '07:45' },
            { id: '33', name: '08:00' },
            { id: '34', name: '08:15' },
            { id: '35', name: '08:30' },
            { id: '36', name: '08:45' },
            { id: '37', name: '09:00' },
            { id: '38', name: '09:15' },
            { id: '39', name: '09:30' },
            { id: '40', name: '09:45' },
            { id: '41', name: '10:00' },
            { id: '42', name: '10:15' },
            { id: '43', name: '10:30' },
            { id: '44', name: '10:45' },
            { id: '45', name: '11:00' },
            { id: '46', name: '11:15' },
            { id: '47', name: '11:30' },
            { id: '48', name: '11:45' },
            { id: '49', name: '12:00' },
            { id: '50', name: '12:15' },
            { id: '51', name: '12:30' },
            { id: '52', name: '12:45' },
            { id: '53', name: '13:00' },
            { id: '54', name: '13:15' },
            { id: '55', name: '13:30' },
            { id: '56', name: '13:45' },
            { id: '57', name: '14:00' },
            { id: '58', name: '14:15' },
            { id: '59', name: '14:30' },
            { id: '60', name: '14:45' },
            { id: '61', name: '15:00' },
            { id: '62', name: '15:15' },
            { id: '63', name: '15:30' },
            { id: '64', name: '15:45' },
            { id: '65', name: '16:00' },
            { id: '66', name: '16:15' },
            { id: '67', name: '16:30' },
            { id: '68', name: '16:45' },
            { id: '69', name: '17:00' },
            { id: '70', name: '17:15' },
            { id: '71', name: '17:30' },
            { id: '72', name: '17:45' },
            { id: '73', name: '18:00' },
            { id: '74', name: '18:15' },
            { id: '75', name: '18:30' },
            { id: '76', name: '18:45' },
            { id: '77', name: '19:00' },
            { id: '78', name: '19:15' },
            { id: '79', name: '19:30' },
            { id: '80', name: '19:45' },
            { id: '81', name: '20:00' },
            { id: '82', name: '20:15' },
            { id: '83', name: '20:30' },
            { id: '84', name: '20:45' },
            { id: '85', name: '21:00' },
            { id: '86', name: '21:15' },
            { id: '87', name: '21:30' },
            { id: '88', name: '21:45' },
            { id: '89', name: '22:00' },
            { id: '90', name: '22:15' },
            { id: '91', name: '22:30' },
            { id: '92', name: '22:45' },
            { id: '93', name: '23:00' },
            { id: '94', name: '23:15' },
            { id: '95', name: '23:30' },
            { id: '96', name: '23:45' }
        ];
    }

    ngOnInit() {

        console.log("release 1.0.0")

        this.en = {
            firstDayOfWeek: 1,

        };

        const loggedInUserId = localStorage.getItem("loggedinUserId");
        //  this.getUserWiseAdminConfigurationDetails(loggedInUserId); // no need any more 
        this.subscribeToLongBookingEditDetails();
        this.selectedLongBookingId = null;
        this.selectedDates.push(new Date());
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        this.selectedDates.push(tomorrow);
        this.getSelectedClient();
        this.getEmployeeList()

        this.workingDays = [
            { id: 2, value: 2, label: 'Monday', name: 'Monday' },
            { id: 3, value: 3, label: 'Tuesday', name: 'Tuesday' },
            { id: 4, value: 4, label: 'Wednesday', name: 'Wednesday' },
            { id: 5, value: 5, label: 'Thursday', name: 'Thursday' },
            { id: 6, value: 6, label: 'Friday', name: 'Friday' },
            { id: 7, value: 7, label: 'Saturday', name: 'Saturday' },
            { id: 1, value: 1, label: 'Sunday', name: 'Sunday' },
        ];
        this.selectedCitiesIds = [
            { id: 1, name: 'Vilnius' },
            { id: 2, name: 'Kaunas' }
        ];
        this.notificationModal = {
            header: '',
            description: '',
            type: ''
        };
        this.notificationModal = {
            header: '',
            description: '',
            type: ''
        };
        this.initializeForms();
        this.patchValue2();
        this.getTemplates();
        this.onClickMakeLongBookingSub = this.calendarService.onClickMakeLongBooking.subscribe((res) => {
            this.allocationFormLongBooking.clearValidators();
            this.allocationFormLongBooking.reset();
            this.initializeKendoComponents();
            this.disableAvvikandeDagarButton = true;
            this.disableAddTemplate = true;
            this.employeeDropdownClicked = false;
            this.availableEmployees = [];
            this.invalidExcludedDates = [];
            this.invalidDevientDates = [];
            // this.ngOnInit();
            if (res == true) {
                this.onClickMakeLongBooking()
            }
        });
        this.longBooingFormOnChange();
        this.shiftFormOnChange();
        this.addAvvikandeDateFormOnChanges();
        //  this.onLongBookingFormDateChange();

    }

    getEmployeeList(): void {
        this.userservice.getUserList('Employee').subscribe(
            (res: IUserInfo[]) => {
                this.availableEmployees = res;
                for (let [key, value] of Object.entries(this.availableEmployees)) {
                    value['value'] = value.userId;
                    value['label'] = value.firstName + ' ' + value.lastName;
                }
            },
            // (error: any) => this.errorMessage = error as any
        );
    }

    onChangeState(event, index) {
    }

    subscribeToLongBookingEditDetails() {
        this.selectedLongBookingId = null;
        this.calendarService.onSelectLongBooking.subscribe(id => {
            this.selectedLongBookingId = id;
            this.disableAvvikandeDagarButton = false;
            this.getEmployeeList();
            setTimeout(() => {
                this.getLongBookingById()
            }, 1000);
        })
    }

    deleteLongBookingDateRange(): void {
      
        this.longBookingService.deleteLongBookingByDateRange(this.alliocationModel).subscribe((data) => {
            this.showNotification('Success', 'Long booking deleted', 'success');
            this.signalRService.broadcastCalendarUpdates();
            this.calendarService.emmitUserListAction('refresh');

                setTimeout(() => {
                    ($('#requestAllocationForLongBookinModal') as any).modal('hide');
                }, 3000);
        })
    }

    updateLongBooking(isInquiry: boolean): void {
        this.showNotification('Success', 'Please wait few seconds ...', 'success');
        let longBookingDto: LongBooking;
        this.disableCreateButton = true;
        this.longbookIsCreating = true;
        longBookingDto = this.getLongBookingDto();
        longBookingDto.createBookingsWithInquiries = isInquiry;
        longBookingDto.id = this.selectedLongBookingId;

        if (longBookingDto.id) {
            this.longBookingService.updateLongBooking(longBookingDto).subscribe((data) => {
                this.showEditButton = true;
                this.disableCreateButton = false;
                this.longbookIsCreating = false;
                this.selectedLongBookingId = null;
                this.clientId = null;
                ($('#matchedEmployeesForSaveLongBooking') as any).modal('hide');
                this.showNotification('Success', 'Long Booking Updated', 'success');

                const scheduler = $('#scheduler').data('kendoScheduler');
                scheduler.dataSource.read({ userId: this.clientId, userType: this.userType });

                this.signalRService.broadcastCalendarUpdates();
                this.calendarService.emmitUserListAction('refresh');

                setTimeout(() => {
                    ($('#requestAllocationForLongBookinModal') as any).modal('hide');
                }, 3000);
                location.reload();

            })
        } else {
            this.showNotification('Error', 'Long Booking Update failed', 'Error');
        }
    }

    getLongBookingById(): void {
        this.longBookingService.getLongBookingById(this.selectedLongBookingId).subscribe((data) => {
            this.showEditButton = true;
            if (data) {
                this.validLongBookingDates = true;
                let avvikandeDates = this.allocationFormLongBooking.get('avvikandeDates').value;
                let existingShifts = this.buyTicketForm['controls'].shifts.value;
                existingShifts.forEach((currentValue, index) => {
                    this.removeShifts(index);
                });
                avvikandeDates.forEach((currentValue, index) => {
                    this.removeAvvikandeDates(index);
                });

                this.employeeDropdownClicked = false;
                this.enableAddShiftButton = true;
                this.allocationFormLongBooking.reset();
                this.selectedLongBookingForEdit = data;
                this.selectedLongBookingId = data.id;
                this.clientId = data.clientId;
                this.selectedEmployees = [];
                this.selectedEmployees = this.selectedLongBookingForEdit.employees.map(x => x.employeeId);

                this.shifts.value.forEach((currentValue, index) => {
                    this.shifts.removeAt(index)
                });

                for (var i = 0; i < data.shifts.length; i++) {
                    let shiftDay = data.shifts[i].shiftDays;
                    this.shifts.push(this.createShift());
                }
                this.setShiftNumber();

                let startDate, endDate;
                startDate = this.datepipe.transform(data.startDate, 'yyyy-MM-dd');
                endDate = this.datepipe.transform(data.endDate, 'yyyy-MM-dd');

                this.validateStartAndEndDates(startDate, endDate)
                    this.alliocationModel.startDate = startDate,
                    this.alliocationModel.endDate = endDate,
                    this.alliocationModel.id = this.selectedLongBookingId;
                this.allocationFormLongBooking.patchValue({
                    longBookingStartDate: startDate,
                    longBookingEndDate: endDate,
                });

                let shifts: any[] = [];
                shifts = data.shifts;
                const sorter = {
                    "monday": 2,
                    "tuesday": 3,
                    "wednesday": 4,
                    "thursday": 5,
                    "friday": 6,
                    "saturday": 7,
                    "sunday": 1,
                }

                shifts.forEach(item => {
                    item.shiftDays.sort(function sortByDay(a, b) {
                        let day1 = a.day.toLowerCase();
                        let day2 = b.day.toLowerCase();
                        return sorter[day1] - sorter[day2];
                    });
                    item.shiftDays.forEach(day => {
                        if (day.id > 0) {
                            day.IsSelected = true;
                        } else {
                            day.IsSelected = false;
                        }
                    })
                })
                this.selectedLongBookingShift = shifts;

                this.buyTicketForm.patchValue({
                    shifts: shifts
                });
                let tempShifts: any;
                tempShifts = this.buyTicketForm['controls'].shifts;
                tempShifts.controls.forEach(item => {
                    let shift = item;
                    let isEmptyRecordsExisits: boolean = false;
                    let days = [];
                    days = item['controls'].shiftDays.controls;
                    days.forEach(day => {
                        day.value.IsSelected = false
                    })
                    shift.errors = "Please fill all the selected records"
                });

                let excludedDays: any[] = [];
                this.excludedDays = [];


                if (data.excludedDays) {
                    data.excludedDays.forEach(element => {
                        this.excludedDays.push({ id: 0, date: new Date(element) });
                    });
                }
                // this.allocationFormLongBooking.patchValue({
                //     excludedDays: excludedDays,
                // });

                let devientDates = [];
                devientDates = data.devientDates;
                devientDates.forEach(devientDate => {
                    devientDate.date = new Date(devientDate.date);
                    let startHour = new Date(devientDate.startTime).getHours().toString();
                    let startmin = new Date(devientDate.startTime).getMinutes().toString();

                    if (startHour == "0") {
                        startHour = "00"
                    }
                    else if (parseInt(startHour) < 10) {
                        startHour = "0".concat(startHour)
                    }

                    if (startmin == "0") {
                        startmin = "00"
                    }
                    else if (parseInt(startHour) < 10) {
                        // startHour = "0".concat(startHour)
                    }

                    let endHour = new Date(devientDate.endTime).getHours().toString();
                    let endMin = new Date(devientDate.endTime).getMinutes().toString();

                    if (endHour == "0") {
                        endHour = "00"
                    } else if (parseInt(endHour) < 10) {
                        endHour = "0".concat(endHour)
                    }
                    if (endMin == "0") {
                        endMin = "00"
                    } else if (parseInt(endMin) < 10) {
                        //   endHour = "0".concat(endHour)
                    }
                    devientDate.startTime = startHour.concat(":").concat(startmin);
                    devientDate.endTime = endHour.concat(":").concat(endMin);
                })
                let item = this.allocationFormLongBooking.get('avvikandeDates') as FormArray;

                item.value.forEach((currentValue, index) => {
                    item.removeAt(index);
                });

                for (var i = 1; i <= devientDates.length; i++) {
                    item.push(this.createAvvikandeDate());
                    this.invalidDevientDates.push(new Date(devientDates[i - 1].date))

                }
                this.allocationFormLongBooking.patchValue({
                    avvikandeDates: devientDates,
                });
            }
            this.calculateShiftTime();
            this.setShiftNumber();

        });

    }

    confirmDeleteTemplate() {
        this.longBookingService.deleteTemplate(this.selectedTemplateId).pipe(catchError(() => {
            this.notificationModal.header = 'Failed'; // Failed
            this.notificationModal.description = 'Long booking delete failed !!!'; //Inquiry adding failed! Please try again.
            this.notificationModal.type = 'danger';
            return EMPTY;
        })).subscribe((data) => {
            this.getTemplates();
            ($('#longBookingDeleteTemplateModal') as any).modal('hide');
            const msg = 'Template deleted success !!!';
            this.toastr.success(msg, 'Warnning!');
            ($('#editTemplateForLongBookinModal') as any).modal('show');
        });
    }

    openDeleteLongBookingConfirmModal(): void {
        //   ($('#editTemplateForLongBookinModal') as any).modal('hide');

        setTimeout(() => {
            ($('#longBookingDeleteModal') as any).modal('show');
        }, 100);
    }

    closeDeleteTemplate() {
        ($('#requestAllocationForLongBookinModal') as any).modal('show');
    }

    openDeleteTemplateConfirmModal(template: any): void {
        this.selectedTemplateId = template.id;
        ($('#editTemplateForLongBookinModal') as any).modal('hide');

        setTimeout(() => {
            ($('#longBookingDeleteTemplateModal') as any).modal('show');
        }, 100);
    }

    closeDeleteTemplateConfirmationModal() {

        ($('#longBookingDeleteTemplateModal') as any).modal('hide');
        ($('#editTemplateForLongBookinModal') as any).modal('show');

    }

    deleteLongBooking() {
        this.longBookingService.deleteLongBooking(this.selectedLongBookingId).pipe(catchError(() => {

            this.notificationModal.header = 'Failed'; // Failed
            this.notificationModal.description = 'Long booking delete failed !!!'; //Inquiry adding failed! Please try again.
            this.notificationModal.type = 'danger';
            return EMPTY;
        })).subscribe((data) => {
            ($('#allocationDeleteModal') as any).modal('hide');
            ($('#longBookingDeleteModal') as any).modal('hide');

            // this.notificationModal.header = 'Long booking deleted'; // Failed
            const msg = 'Long booking deleted success !!!';
            this.toastr.success(msg, 'WarLong booking deletednning!');

            setTimeout(() => {
                //  ($('#notificationModal') as any).modal('hide');
                ($('#requestAllocationForLongBookinModal') as any).modal('hide');

                const scheduler = $('#scheduler').data('kendoScheduler');
                scheduler.dataSource.read({ userId: this.clientId, userType: this.userType });

                this.signalRService.broadcastCalendarUpdates();
                this.calendarService.emmitUserListAction('refresh');
            }, 3000);
        });


    }

    createShift(shiftDays?: any[]): FormGroup {
        let days: any;
        this.shiftId = this.shiftId + 1;
        days = this.fb.array(
            [
                this.createBookingDay(0, true, this.shiftId, null, null, null, this.workingDays[0].name),
                this.createBookingDay(0, true, this.shiftId, null, null, null, this.workingDays[1].name),
                this.createBookingDay(0, true, this.shiftId, null, null, null, this.workingDays[2].name),
                this.createBookingDay(0, true, this.shiftId, null, null, null, this.workingDays[3].name),
                this.createBookingDay(0, true, this.shiftId, null, null, null, this.workingDays[4].name),
                this.createBookingDay(0, false, this.shiftId, null, null, null, this.workingDays[5].name),
                this.createBookingDay(0, false, this.shiftId, null, null, null, this.workingDays[6].name)]);

        return this.fb.group({
            id: this.shiftId,
            shiftDays: days,
            totalHours: 100
        })
    }

    toTime(timeString) {
        var timeTokens = timeString.split(':');
        return new Date(new Date().getFullYear(), 0, 1, timeTokens[0], timeTokens[1]);
    }

    getTimeDifference(startTime, endTime, lunch?): number {
        var diff = (endTime.getTime() - startTime.getTime()) / 60000;
        if (lunch) {
            diff = diff - lunch;
        }
        var days = Math.floor(diff / (60 * 60 * 24 * 1000));
        var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
        var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
        return diff;
    }

    validateShiftTime(startTime, endTime): boolean {
        let startdayTime = startTime.toString().split(":");
        let enddayTime = endTime.toString().split(":");

        let startHr = parseInt(startdayTime[0]);
        let startMin = parseInt(startdayTime[1]);

        let endHr = parseInt(enddayTime[0]);
        let endMin = parseInt(enddayTime[1]);

        let time1 = startHr * 60 + startMin;
        let time2 = endHr * 60 + endMin;
        if (time1 < time2) {
            return true;
        }
        return false;
    }

    createAvvikandeDate(): FormGroup {
        return this.fb.group({
            date: [null, Validators.required],
            startTime: ['09:00', Validators.required],
            endTime: ['18:00', Validators.required],
            id: 0,
            lunch: [45, Validators.required],
        })
    }

    createExcludedDate(): FormGroup {
        return this.fb.group({
            date: [null, Validators.required],
        })
    }

    get shifts(): FormArray {
        return <FormArray>this.buyTicketForm.get('shifts');
    }

    addShift() {
        let startDate = new Date(this.allocationFormLongBooking.get("longBookingStartDate").value);
        let endDate = new Date(this.allocationFormLongBooking.get("longBookingEndDate").value);
        let totalDays = Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
            - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())) / (1000 * 60 * 60 * 24));

        let numberOfWeeks = totalDays / 7;
        if (numberOfWeeks > this.shifts.length) {
            this.shifts.push(this.createShift());
            this.setShiftNumber();
            this.calculateShiftTime();

            setTimeout(() => {
                this.checkValidations();
            }, 1000);
        } else {
            ($('#longbookingAddShiftValidationErrorModal') as any).modal('show');
        }
    }

    deleteShift(shift: any) {
        this.shifts.removeAt(this.shifts.length - 1);
        this.setShiftNumber();
    }

    removeShift(index: any) {
        this.shifts.removeAt(index);
        this.setShiftNumber();
    }

    setShiftNumber() {
        this.shiftNumber = 0;
        this.shiftNumber = this.shifts.length + 1;
    }

    initializeForms(): void {
        this.buyTicketForm = this.fb.group(
            {
                startDate: [null, [Validators.required]],
                endDate: [null, [Validators.required]],
                shifts: this.fb.array([this.createShift()], Validators.required),
            }
        )
        this.allocationFormLongBooking = this.fb.group({
            bookings: this.fb.array([this.createBooking()]),
            templateId: ['', Validators.required],
            shifts: this.fb.array([this.createShift()], Validators.required),
            avvikandeDates: this.fb.array([this.createAvvikandeDate()], Validators.required),
            excludedDays: null,
            longBookingStartDate: ['', Validators.required],
            spareMall: '',
            longBookingEndDate: ['', Validators.required],
            clientId: [],
            resourceTypeId: 1,
            employeeIds: [],
            isPartialBooking: [false],
            selectedCitiesIds: []
        });

        this.swapUserForm = this.fb.group({
            allocationId: [],
            swapUserId: [],
            swapType: []
        });
        this.allocationTimeLogsForm = this.fb.group({
            allocationId: [],
            isTimeLogged: [],
            startTime: [],
            endTime: [],
            lunchTime: []
        });
        this.availabilityForm = this.fb.group({
            changeTypeId: ['1'],
            month: ['', Validators.required],
            startDate: [''],
            endDate: [''],
            availabilityId: ['1']
        });
        this.partialAvailabilityForm = this.fb.group({
            avlTypeId: [],
            avlDate: [],
            avlStart: [],
            avlEnd: []
        });
        this.allocationTimeUpdateForm = this.fb.group({
            allocationId: [],
            startDate: [],
            endDate: [],
            lunchTime: []
        })
    }
    get f() { return this.allocationFormLongBooking.controls; }

    longBooingFormOnChange() {
        this.allocationFormLongBooking.valueChanges.subscribe(x => {
            this.calculateShiftTime();
            this.checkValidations();
            // this.getMatchedEmployeesForSaveLongBooking();
        });

    }
    shiftFormOnChange() {
        this.buyTicketForm.get('shifts').valueChanges.subscribe(changes1 => {
            this.checkValidations();
            this.calculateShiftTime();
            /// this.getMatchedEmployeesForSaveLongBooking();


        });

        this.buyTicketForm.valueChanges.subscribe(x => {
            this.checkValidations();
            this.calculateShiftTime();
        });
    }

    setDefaultTimeForWeekends(data1: any, t: any) {
        let shifts: any;
        shifts = this.buyTicketForm.controls['shifts'];

        shifts.controls.forEach(element => {

            if (data1.value.shiftId == element.value.id || data1.value.shiftId == element.value.shiftDays[0].shiftId) {
                let shiftDays = element.controls['shiftDays'].controls;
                if (data1.value.day == 'Saturday') {
                    shiftDays.forEach(data => {
                        if (!data['controls'].IsSelected.value && data['controls'].day.value == 'Saturday') {
                            data['controls'].IsSelected.value = true;
                            data.value.IsSelected = true;
                            data['controls'].startTime.value = '10:00'
                            data['controls'].endTime.value = "15:00"
                            data.value.startTime = '10:00'
                            data.value.endTime = "15:00"
                        } else if (data['controls'].IsSelected.value && data['controls'].day.value == 'Saturday') {
                            data['controls'].IsSelected.value = false;
                            data.value.IsSelected = false;
                            data['controls'].startTime.value = null
                            data['controls'].endTime.value = null
                            data.value.startTime = null
                            data.value.endTime = null
                        }
                    })
                }
                else if (data1.value.day == 'Sunday') {
                    shiftDays.forEach(data => {
                        if (!data['controls'].IsSelected.value && data['controls'].day.value == 'Sunday') {
                            data['controls'].IsSelected.value = !data1.value.IsSelected;
                            data.value.IsSelected = !data1.value.IsSelected;
                            data['controls'].startTime.value = '10:00'
                            data['controls'].endTime.value = "15:00"
                            data.value.startTime = '10:00'
                            data.value.endTime = "15:00"
                        }
                        else if (data['controls'].IsSelected.value && data['controls'].day.value == 'Sunday') {
                            data['controls'].IsSelected.value = false;
                            data.value.IsSelected = false;
                            data['controls'].startTime.value = null
                            data['controls'].endTime.value = null
                            data.value.startTime = null
                            data.value.endTime = null
                        }
                    })
                } else {
                    shiftDays.forEach(data => {
                        if (!data['controls'].IsSelected.value && data['controls'].day.value == data1.value.day) {
                            data['controls'].IsSelected.value = !data1.value.IsSelected;
                            data.value.IsSelected = !data1.value.IsSelected;
                            data['controls'].startTime.value = '09:00'
                            data['controls'].endTime.value = "18:00"
                            data.value.startTime = '09:00'
                            data.value.endTime = "18:00"

                        }
                        else if (data['controls'].IsSelected.value && data['controls'].day.value == data1.value.day) {
                            data['controls'].IsSelected.value = false;
                            data.value.IsSelected = false;
                            data['controls'].startTime.value = null
                            data['controls'].endTime.value = null
                            data.value.startTime = null
                            data.value.endTime = null
                        }
                    })

                }
            }
        });






        this.buyTicketForm.patchValue({
            shifts: shifts.value
        });
    }

    checkValidations() {
        const ab = this.allocationFormLongBooking.controls["longBookingStartDate"];
        let shifts: any;
        this.validShiftTime = true;
        shifts = this.buyTicketForm.controls["shifts"];
        shifts.controls.forEach(item => {
            let shift = item;
            let isEmptyRecordsExisits: boolean = false;
            let days = item.controls["shiftDays"];
            days.controls.forEach(day => {
                let a = day.controls["validDay"];
                a.validator = "";
                if (day.controls["IsSelected"].value == true) {
                    if (day.controls["startTime"].value == "" || day.controls["startTime"].value == null || day.controls["endTime"].value == null || day.controls["endTime"].value == "") {
                        isEmptyRecordsExisits = true;
                    }
                    if (day.controls["startTime"].value != "" && day.controls["startTime"].value == day.controls["endTime"].value) {
                        let a = day.controls["validDay"];
                        a.validator = "Starttid och Sluttid kan ej vara samma";
                        this.validShiftTime = false;
                    }

                    if (day['controls'].startTime.value != null && day['controls'].startTime.value.length
                        && day['controls'].endTime.value != null && day['controls'].endTime.value) {
                        if (!this.validateShiftTime(day['controls'].startTime.value, day['controls'].endTime.value)) {
                            day['controls'].validTime.value = false;
                            this.validShiftTime = false;
                        }
                    }
                }

            })
            if (isEmptyRecordsExisits) {
                shift.errors = "Please fill all the selected records";
                this.validShiftTime = false;

            }
        })

        ab.setErrors({ passwordMismatch: true });
    }

    calculateShiftTime() {
        this.shiftTimeDetails = [];
        const pad = num => ("0" + num).slice(-2);
        const testDays = "sun,mo,tu,we,thu,fri,sat".split(",")
        const makeDate = d => `${pad(d.getMonth() + 1)}-${pad(d.getDate())}-${d.getFullYear()}`;
        let from1 = new Date("2022-10-01T08:08:20.794Z");
        const to1 = new Date("2022-10-31T08:08:20.794Z");

        let sDate = this.allocationFormLongBooking.get('longBookingStartDate').value;
        let eDate = this.allocationFormLongBooking.get('longBookingEndDate').value;
        let from = new Date(sDate + 'T08:08:20.794Z');
        const to = new Date(eDate + 'T08:08:20.794Z');



        const aDay = 24 * 60 * 60 * 1000;
        const aWeek = 7 * aDay;
        const weeks = [];
        const testArr = []
        for (let i = from.getTime(), n = to.getTime(); i <= n; i += aDay) {
            const d = new Date(i);
            if (d.getDay() === 1 || weeks.length === 0) { // from OR monday
                let b = makeDate(d)
                weeks.push([makeDate(d)]);
                testArr.push([testDays[d.getDay()]])
            }
            else if (d.getDay() === 0 || i === n) {
                weeks[weeks.length - 1].push(makeDate(d))
                testArr[testArr.length - 1].push(testDays[d.getDay()])
            }
        }
        let timeCalculation: TimeCalculation[] = [];
        weeks.forEach((week, i) => {
            let t: TimeCalculation = new TimeCalculation();
            t.weekStart = week[0];
            t.weekEnd = week[1];

            let fromdate = new Date(week[0]);
            let todate = new Date(week[1]);

            let dateRange = this.getDatesRange(fromdate, todate);
            let days: Day[] = [];

            dateRange.forEach(item => {
                let d: Day = new Day();
                d.day = item;
                d.dayName = new Date(item).toLocaleDateString('en-US', { weekday: 'long' });
                days.push(d);
            })
            t.days = [];
            t.days = days;
            timeCalculation.push(t);
        })
        let shifts: any[] = [];
        if (this.buyTicketForm) {
            shifts = this.buyTicketForm['controls'].shifts.value;
        }

        // shift wise time calculation 
        this.uniqueShiftTimeDetails = [];
        shifts.forEach((item, i) => {
            let selectedShiftDays: any[] = item.shiftDays.filter(x => x.IsSelected == true);

            let shiftTotalTime: number = 0;
            selectedShiftDays.forEach(consideringDate => {
                const event1 = new Date();
                event1.setHours(consideringDate.startTime.split(':')[0]);
                event1.setMinutes(consideringDate.startTime.split(':')[1]);
                event1.setMilliseconds(1);

                const event2 = new Date();
                event2.setHours(consideringDate.endTime.split(':')[0]);
                event2.setMinutes(consideringDate.endTime.split(':')[1]);
                event2.setMilliseconds(1);
                var timeDif = this.getTimeDifference(event1, event2, consideringDate.lunch);
                shiftTotalTime = shiftTotalTime + timeDif;
                consideringDate.totalTime = timeDif;
            })

            let t: ShiftTimeDetails = new ShiftTimeDetails();
            t.totalTimeInHours = this.minutesTOHourConvert(shiftTotalTime);
            t.id = item.shiftId;
            this.uniqueShiftTimeDetails.push(t)
        })


        // shift week wise calculation
        let shiftLength = shifts.length;
        let shiftNumber = 0;
        timeCalculation.forEach((item, i) => {
            let days: Day[] = item.days;
            item.shift = shifts[shiftNumber];
            shiftNumber = shiftNumber + 1;
            if (shiftNumber >= shiftLength) {
                shiftNumber = 0;
            }
            let shiftDays: any[] = item.shift.shiftDays;
            let selectedShiftDays: any[] = shiftDays.filter(x => x.IsSelected == true);
            selectedShiftDays.forEach(selectedShiftDay => {
                let consideringDate: Day = new Day();
                let dayOfWeek = selectedShiftDay.day;
                consideringDate = days.filter(a => a.dayName == dayOfWeek)[0];
                if (consideringDate) {
                    consideringDate.startTime = selectedShiftDay.startTime
                    consideringDate.endTime = selectedShiftDay.endTime
                    consideringDate.lunch = selectedShiftDay.lunch;
                    const event1 = new Date();
                    event1.setHours(consideringDate.startTime.split(':')[0]);
                    event1.setMinutes(consideringDate.startTime.split(':')[1]);
                    event1.setMilliseconds(1);

                    const event2 = new Date();
                    event2.setHours(consideringDate.endTime.split(':')[0]);
                    event2.setMinutes(consideringDate.endTime.split(':')[1]);
                    event2.setMilliseconds(1);
                    var timeDif = this.getTimeDifference(event1, event2, consideringDate.lunch);
                    consideringDate.totalMins = timeDif;

                    let shiftTImeDetailsExists =
                        this.shiftTimeDetails.find(shift => shift.id == selectedShiftDay.shiftId);

                    if (shiftTImeDetailsExists) {

                        // week wise total time 
                        shiftTImeDetailsExists.totalTimeInMins = shiftTImeDetailsExists.totalTimeInMins + timeDif;
                    } else {
                        let shiftTimeDetail: ShiftTimeDetails = new ShiftTimeDetails();
                        shiftTimeDetail.id = selectedShiftDay.shiftId;
                        // week wise total time 
                        shiftTimeDetail.totalTimeInMins = timeDif;
                        this.shiftTimeDetails.push(shiftTimeDetail)
                    }
                }
            })
        })
        //  console.log("time-", timeCalculation);
        this.totalShiftTimeCalculated = 0;
        this.shiftTimeDetails.forEach((x, index) => {
            x.id = index + 1;
            this.totalShiftTimeCalculated = this.totalShiftTimeCalculated + x.totalTimeInMins;
            x.totalTimeInHours = this.minutesTOHourConvert(x.totalTimeInMins);
        })
        this.totalShiftTimeCalculatedText = this.minutesTOHourConvert(this.totalShiftTimeCalculated);
        // console.log("shift time details-", this.shiftTimeDetails);

    }

    minutesTOHourConvert(n): string {
        var num = n;
        var hour = "timmar"
        var minute = "minutes"

        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        if (rminutes > 1) {
            minute = "minuter";
        }
        if (rhours > 1) {
            hour = "timmar"
        }
        return rhours + hour + " och " + rminutes + " " + minute;
    }

    calculateTimeDifference(time) {
        var arr = time.split('-').map(function (str) {
            var hours = parseInt(str.substr(0, 2), 10),
                minutes = parseInt(str.substr(2, 4), 10);
            if (minutes.toString() == Number.NaN.toString()) {
                minutes = 0;
            }
            var val = (hours * 60 + minutes) / 60;
            return Math.round((val + Number.EPSILON) * 100) / 100;

        });
        return arr[1] - arr[0];
    }

    getDatesInRange(startDate, endDate) {
        startDate = new Date(startDate);
        endDate = new Date(endDate);

        const date = new Date(startDate.getTime());
        const dates = [];

        while (date <= endDate) {

            let dObj = date;
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    }

    getWeeksDiff(startDate, endDate) {
        const msInWeek = 1000 * 60 * 60 * 24 * 7;
        return Math.round(Math.abs(endDate - startDate) / msInWeek);
    }

    getSelectedClient() {
        this.alliocationModel.resourceTypeId = 1;
        this.newSelectedUserSub = this.userservice.newSelectedUser.subscribe((data) => {
            if (data) {
                this.clientId = data.userId
                this.userType = data.userType
            }
        })
        this.onResourceTypeChangeSub = this.calendarService.onResourceTypeChange.subscribe((res: number) => {
            if (res) {
                this.alliocationModel.resourceTypeId = res
            }
        })
    }

    getUserWiseAdminConfigurationDetails(userId: string): void {
        this.configService.getUserWiseAdminCOnfigurations(userId).subscribe(data => {
            if (data.length) {

                // not in use anymore

                // if (data.find(a => a.title === 'configClientLongBookingPeriod')) {
                //     this.configClientLongBookingPeriod = data.find(a => a.title === 'configClientLongBookingPeriod').additionalValue1;
                // }

                // if (data.find(a => a.title === 'configClientOverrideLongBookingDateRule')) {
                //     this.configClientOverrideLongBookingDateRule = data.find(a => a.title === 'configClientOverrideLongBookingDateRule').isActive;
                // }
            }
        })
    }


    getAvailableEmployees() {
        this.alliocationModel.ClientId = this.clientId;
        // this.alliocationModel.resourceTypeId = -1;
        this.alliocationModel.IsPartial = false,
            this.calendarService.getAvailableEmployees(this.alliocationModel).subscribe((availableEmpList) => {

                if (availableEmpList) {
                    this.selectedEmployees = [];
                    this.availableEmployees = availableEmpList;

                    if (this.selectedLongBookingId) {

                        this.selectedLongBookingForEdit.employees.forEach(emp => {
                            let employee = this.availableEmployees.find(x => x.value == emp.employeeId);
                            if (employee) {
                                this.selectedEmployees.push(employee.value);
                            }
                        });

                        this.allocationFormLongBooking.patchValue({
                            employeeIds: this.selectedEmployees
                        });
                    }
                } else {

                    this.availableEmployees = [];
                    if (this.selectedLongBookingId) {
                        let bookedEmployees = [];
                        bookedEmployees = [...this.selectedLongBookingForEdit.employees];

                        bookedEmployees.forEach(item => {
                            let obj = { value: item.employeeId, label: item.name };
                            this.availableEmployees.push(obj);
                        });

                        bookedEmployees.forEach(emp => {
                            this.selectedEmployees.push(emp.employeeId);
                        });

                        this.allocationFormLongBooking.patchValue({
                            employeeIds: this.selectedEmployees
                        });
                    }
                }
            });
    }

    getDatesRange(startDate: Date, stopDate: Date): any[] {
        var dateArray = new Array();
        let currentDate = startDate;
        while (currentDate <= stopDate) {
            dateArray.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dateArray;
    }

    getMatchedEmployeesForSaveLongBooking() {
        let longbookingDto: any;
        let proceedDirectly: boolean = true;
        longbookingDto = this.getLongBookingDto();
        let longBookingStartDate = this.allocationFormLongBooking.get('longBookingStartDate').value;
        let longBookingendDate = this.allocationFormLongBooking.get('longBookingEndDate').value;
        let dateRange = this.getDatesRange(new Date(longBookingStartDate), new Date(longBookingendDate));

        this.longBookingService.getAvailabilities(longbookingDto).subscribe(data => {
            this.employeeNotAvailbleDates.forEach(item => {
                let employeeAvailbleDates = data.filter(el => el.userId == item.employee.value);
                let employeeNotAvailbleDates: any[] = [];

                dateRange.forEach(date => {
                    let isExists = employeeAvailbleDates.filter(x =>
                        this.datepipe.transform(new Date(x.date), 'yyyy-MM-dd') == this.datepipe.transform(new Date(date), 'yyyy-MM-dd'));
                    if (!isExists.length) {
                        employeeNotAvailbleDates.push(date);
                        proceedDirectly = false;
                    }
                })
                item.dates = employeeNotAvailbleDates;
            });

            //  console.log("not availble-", this.employeeNotAvailbleDates)
            if (!proceedDirectly) {
                ($('#matchedEmployeesForSaveLongBooking') as any).modal('show');
            } else if (this.selectedLongBookingId) {
                this.updateLongBooking(false);
            } else {
                this.saveLongBooking(false);
            }
        });
    }

    getLongBookingDto(): any {
        this.calculateShiftTime();
        let shiftsDays: any[] = [];
        let shifts: LongBookingShift[] = [];
        let devianDates = this.allocationFormLongBooking.get('avvikandeDates').value;
        devianDates = devianDates.filter(item => item.date != null);
        let longBookingStartDate = this.allocationFormLongBooking.get('longBookingStartDate').value;
        let longBookingendDate = this.allocationFormLongBooking.get('longBookingEndDate').value;
        shiftsDays = this.buyTicketForm.get('shifts').value;
        let templateId = this.allocationFormLongBooking.get('templateId').value;

        shiftsDays.forEach((item, i) => {
            let shift: LongBookingShift = new LongBookingShift();
            shift.ShiftDays = [];
            item.shiftDays.forEach((shiftDay, j) => {
                if (shiftDay.IsSelected && shiftDay.startTime != null && shiftDay.startTime.length) {
                    let day: LongBookingShiftDay = new LongBookingShiftDay();
                    day.StartTime = shiftDay.startTime,
                        day.EndTime = shiftDay.endTime,
                        day.Lunch = parseInt(shiftDay.lunch),
                        //day.Date= new Date(),
                        day.Day = shiftDay.day,
                        day.Id = shiftDay.id,
                        //day.LongBookingShiftId= 1
                        shift.ShiftDays.push(day);
                }
            });

            shift.Id = item.id,
                shift.ShiftNumber = i + 1;
            shift.ShiftName = 'Shift ' + (i + 1);
            shift.ShiftTotalTime = 12;
            shifts.push(shift);
        });

        let longBooking: LongBooking = new LongBooking();
        let deviantDate: DateDto[] = [];
        deviantDate = [{ Date: new Date(), EndTime: new Date().toDateString(), StartTime: new Date().toDateString(), Lunch: 45 }]

        let dates: Date[] = [];
        let excludedDays = this.allocationFormLongBooking.get('excludedDays').value;

        if (this.excludedDays) {
            this.excludedDays.forEach(item => {
                dates.push(item.date);
            });
        }

        longBooking.excludedDays = dates;

        this.allocationFormLongBooking.patchValue({
            devianDates: this.avvikandeDagarDatesDays
        });

        this.allocationFormLongBooking.patchValue({
            employeeIds: this.selectedEmployees
        });

        longBooking.startDate = new Date(longBookingStartDate);
        longBooking.endDate = new Date(longBookingendDate);

        let emplList: LongBookingEmployeeModel[] = [];
        this.employeeNotAvailbleDates = [];
        this.selectedEmployees.forEach(item => {
            let emp: LongBookingEmployeeModel = new LongBookingEmployeeModel();
            let empDateObj: EmployeeAvailbleDates = new EmployeeAvailbleDates();
            let employee = this.availableEmployees.filter(el => el.value == item)[0];
            empDateObj.employee = employee;
            this.employeeNotAvailbleDates.push(empDateObj);
            emp.employeeId = item;
            emplList.push(emp);
        });

        longBooking.employees = emplList;
        longBooking.shifts = shifts;
        longBooking.devientDates = devianDates; //deviantDate;
        longBooking.isPartialBooking = false;
        longBooking.resourceTypeId = 1;
        longBooking.clientId = this.clientId;
        return longBooking;
    }

    saveLongBooking(isInquiry: boolean) {
        let longBooking = this.getLongBookingDto();
        longBooking.createBookingsWithInquiries = isInquiry;
        this.disableCreateButton = true;
        this.longbookIsCreating = true;
        ($('#matchedEmployeesForSaveLongBooking') as any).modal('show');

        this.longBookingService.saveLongBooking(longBooking).pipe(
            catchError((err) => {
                this.showNotification('Error', 'Long Booking Creation failed', 'failed');
                this.disableCreateButton = false;
                this.longbookIsCreating = false;
                return EMPTY
            })
        ).subscribe(data => {
            ($('#matchedEmployeesForSaveLongBooking') as any).modal('hide');
            this.showNotification('Success', 'Long Booking Created', 'success');
            const scheduler = $('#scheduler').data('kendoScheduler');
            scheduler.dataSource.read({ userId: this.clientId, userType: this.userType });

            this.signalRService.broadcastCalendarUpdates();
            this.calendarService.emmitUserListAction('refresh');
            this.disableCreateButton = false;
            this.longbookIsCreating = false;
            setTimeout(() => {
                ($('#requestAllocationForLongBookinModal') as any).modal('hide');
            }, 3000);
            location.reload();
        });
    }

    validateTemplateName(name: string) {
        if (name) {
            this.disableAddTemplate = false;
        } else {
            this.disableAddTemplate = true;
        }
    }

    deleteLongBookingTemplate(deleteItem: LongBookingTemplate) {
        this.templateList = this.templateList.filter(item => item.Id != deleteItem.Id);
    }

    checkDateRangeIsValid(date: Date): boolean {
        let longBookingStartDate = new Date(this.allocationFormLongBooking.get('longBookingStartDate').value);
        let longBookingendDate = new Date(this.allocationFormLongBooking.get('longBookingEndDate').value);

        if (date > longBookingStartDate && date < longBookingendDate) {
            return true;
        }
        return false;
    }

    saveTemplate() {
        let newTemplate = new LongBookingTemplate();
        let templateName = this.allocationFormLongBooking.get('spareMall').value;

        if (this.templateList.findIndex(item => item.label == templateName) == -1) {

            newTemplate.label = templateName;
            newTemplate.Name = templateName;
            newTemplate.CreatedDate = new Date();

            let shiftsDays: any[] = [];
            let shifts: LongBookingShift[] = [];

            shiftsDays = this.buyTicketForm.get('shifts').value;
            let templateId = this.allocationFormLongBooking.get('templateId').value;

            shiftsDays.forEach((item, i) => {
                let shift: LongBookingShift = new LongBookingShift();
                shift.ShiftDays = [];

                item.shiftDays.forEach((shiftDay, j) => {
                    if (shiftDay.IsSelected && shiftDay.startTime != null && shiftDay.startTime.length) {
                        let day: LongBookingShiftDay = new LongBookingShiftDay();
                        day.StartTime = shiftDay.startTime,
                            day.EndTime = shiftDay.endTime,
                            day.Lunch = parseInt(shiftDay.lunch),
                            day.Day = shiftDay.day,
                            shift.ShiftDays.push(day);
                    }
                });

                shift.ShiftNumber = i + 1;
                shift.ShiftName = 'Shift ' + (i + 1);
                shift.ShiftTotalTime = 12;
                shifts.push(shift);
            });

            newTemplate.Shifts = shifts;
            this.templateList.push(newTemplate);
            this.allocationFormLongBooking.get('spareMall').setValue('');
            this.disableAddTemplate = true;
            this.longBookingService.saveLongBookingTemplate(newTemplate).subscribe(data => {
                this.templateList = [...data];
                this.showNotification('Lyckades', 'Mallen har sparats', 'success');
            });

        } else {
            this.showNotification('Misslyckades', 'This template already exisits !', 'danger');
        }
    }

    closeEditTemplateModal() {
        ($('#editTemplateForLongBookinModal') as any).modal('hide');
    }

    editTemplate() {
        this.showNotification('Lyckades', 'Användaren inaktiverad', 'success');
    }

    showManageTemplateModal() {
        this.templateList.forEach(item => {
            item.showEdit = false;
        });
        ($('#editTemplateForLongBookinModal') as any).modal('show');

    }

    onClickEditTemplate() {
        //  ($('#editTemplateForLongBookinModal') as any).modal('show');
        let longBookingTemplateDto: any;
        longBookingTemplateDto = {
            Id: this.selectedTemplate.id,
            Shifts: this.buyTicketForm.get('shifts').value
        }
        // longBookingTemplateDto.Id = this.selectedTemplate.id;
        // longBookingTemplateDto.Shifts = this.buyTicketForm.get('shifts').value;
        this.saveTemplate1();
        // this.longBookingService.updateLongBookingTemplate(longBookingTemplateDto).subscribe(data => {
        //     this.getTemplates();
        // })

    }

    saveTemplate1() {
        let newTemplate = new LongBookingTemplate();
        let templateName = this.allocationFormLongBooking.get('spareMall').value;

        newTemplate.label = templateName;
        newTemplate.Id = this.selectedTemplate.id;
        newTemplate.CreatedDate = new Date();

        let shiftsDays: any[] = [];
        let shifts: LongBookingShift[] = [];

        shiftsDays = this.buyTicketForm.get('shifts').value;
        let templateId = this.allocationFormLongBooking.get('templateId').value;

        shiftsDays.forEach((item, i) => {
            let shift: LongBookingShift = new LongBookingShift();
            shift.ShiftDays = [];

            item.shiftDays.forEach((shiftDay, j) => {
                if (shiftDay.IsSelected && shiftDay.startTime != null && shiftDay.startTime.length) {
                    let day: LongBookingShiftDay = new LongBookingShiftDay();
                    day.StartTime = shiftDay.startTime,
                        day.EndTime = shiftDay.endTime,
                        day.Lunch = parseInt(shiftDay.lunch),
                        day.Day = shiftDay.day,
                        shift.ShiftDays.push(day);
                }
            });

            shift.ShiftNumber = i + 1;
            shift.ShiftName = 'Shift ' + (i + 1);
            shift.ShiftTotalTime = 12;
            shifts.push(shift);
        });

        newTemplate.Shifts = shifts;
        this.templateList.push(newTemplate);
        this.allocationFormLongBooking.get('spareMall').setValue('');
        this.disableAddTemplate = true;
        this.longBookingService.updateLongBookingTemplate(newTemplate).subscribe(data => {
            //  this.templateList = [...data];
        });
        this.showNotification('Lyckades', 'Användaren inaktiverad', 'success');
    }

    resetShiftForm(): void {
        this.buyTicketForm.reset();
        this.buyTicketForm.clearValidators();
        this.buyTicketForm = this.fb.group(
            {
                startDate: [null, [Validators.required]],
                endDate: [null, [Validators.required]],
                shifts: this.fb.array([this.createShift()], Validators.required),
            }
        )
        this.shiftNumber = 2;
    }

    deleteExistingShifts() {
        this.shifts.controls = [];
    }

    onTemplateListChange(ev) {
        this.disableAddTemplate = true;

        this.employeeDropdownClicked = false;
        this.templateSelected = false;
        let existingShifts = this.buyTicketForm['controls'].shifts.value;

        // dfbdg
        if (ev) {
            this.allocationFormLongBooking.get('spareMall').setValue(ev.name);
            existingShifts.forEach((currentValue, index) => {
                this.removeShifts(index);
            });
            this.deleteExistingShifts();
            this.disableAddTemplate = false;
            let shiftsDays: any[] = [];
            let shiftCount = ev.shifts.length;
            for (var i = 0; i < shiftCount; i++) {
                this.shifts.push(this.createShift());
            }
            ev.shifts.forEach(item => {
                item.shiftDays.forEach(day => {
                    day.IsSelected = true;
                })
            })

            const sorter = {
                "monday": 1,
                "tuesday": 2,
                "wednesday": 3,
                "thursday": 4,
                "friday": 5,
                "saturday": 7,
                "sunday": 7,
            }

            ev.shifts.forEach(item => {
                item.shiftDays.sort(function sortByDay(a, b) {
                    let day1 = a.day.toLowerCase();
                    let day2 = b.day.toLowerCase();
                    return sorter[day1] - sorter[day2];
                });
                item.shiftDays.forEach(day => {
                    if (day.id > 0) {
                        day.IsSelected = true;
                    } else {
                        day.IsSelected = false;
                    }
                })
            })

            this.buyTicketForm.patchValue({
                shifts: ev.shifts
            });
            this.shifts.value.forEach(element => {
                element.shiftDays.forEach(day => {
                    if (!day.id) {
                        day.IsSelected = false;
                    }
                })
            });
            shiftsDays = this.buyTicketForm.get('shifts').value;
            this.templateSelected = true;
            this.calculateShiftTime();
        } else {
            this.allocationFormLongBooking.get('spareMall').setValue('');
            existingShifts.forEach((currentValue, index) => {
                this.removeShifts(index);
            });
            this.deleteExistingShifts();
            this.shifts.push(this.createShift());

            //this.resetShiftForm();
            if (this.selectedLongBookingId) {
                this.buyTicketForm.patchValue({
                    shifts: this.selectedLongBookingShift
                });
            }
            this.calculateShiftTime();

        }
        this.selectedTemplate = ev;
        this.setShiftNumber();
    }

    getTemplates() {
        this.longBookingService.getLongBookingTemplates().subscribe((data) => {
            if (data) {
                this.templateList = [...data];
                let shiftsDays: any[] = [];
                shiftsDays = this.buyTicketForm.get('shifts').value;
                this.calculateShiftTime();
                shiftsDays.forEach(item => {
                    item.shiftDays.forEach(shiftDay => {
                        //   shiftDay.validTime = false;
                    })
                })

            }
        })
    }

    onSelectEmployees(ev) {
        this.employeeDropdownClicked = true;
    }

    removeSelecytedEmployee(emp) {
        this.employees.push(emp);
        this.selectedEmployees = this.selectedEmployees.filter(item => item !== emp.value)
    }

    onStartTimeChange(ev) {

    }

    onEndTimeChange(ev) {

    }

    onUpdateExcludeDate() {
        let day = this.allocationFormLongBooking.get('excludedDays').value
        this.excludedDays.push({ id: 1, date: day });
        this.invalidExcludedDates.push(day);
        this.invalidDevientDates.push(day);

        this.allocationFormLongBooking.patchValue({
            excludedDays: null
        });
    }

    addTimePeriod(timeperiod) {

        // this.bookings().push(this.createTimePeriod(timeperiod));
    }
    addAvvikandeDateFormOnChanges() {
        this.disableAvvikandeDagarButton = true;
        this.allocationFormLongBooking.get('avvikandeDates').valueChanges.subscribe(changes => {
            let item = this.allocationFormLongBooking.get('avvikandeDates') as FormArray;
            if (item.value.length && item.valid) {
                this.disableAvvikandeDagarButton = false;
            } else if (item.value.length && !item.valid) {
                this.disableAvvikandeDagarButton = true;
            }
        })
    }



    addAvvikandeDates() {
        this.showAvvikandeDates = true;
        let item: any;
        item = this.allocationFormLongBooking.get('avvikandeDates') as FormArray;
        if (item.value.length && item.valid) {
            // if (this.checkDateRangeIsValid(item.value[item.length - 1].date)) {
            this.invalidDevientDate = false;
            this.invalidDevientDates.push(new Date(item.value[item.value.length - 1].date))
            this.invalidExcludedDates.push(new Date(item.value[item.value.length - 1].date))
            item.push(this.createAvvikandeDate());

            //  } else {
            //      this.invalidDevientDate = true;
            //  }
        } else if (item.value.length == 0) {
            item.push(this.createAvvikandeDate());
        }
    }

    get avvikandeDates(): FormArray {
        return <FormArray>this.allocationFormLongBooking.controls.avvikandeDates;
    }

    get getShifts(): FormArray {
        return <FormArray>this.buyTicketForm.controls.shifts;
    }

    get getEMployees(): FormArray {
        let b = <FormArray>this.allocationFormLongBooking.controls.employeeIds;
        return <FormArray>this.allocationFormLongBooking.controls.employeeIds;
    }

    removeEmployees(index) {
        this.getEMployees.removeAt(index);
    }

    removeAvvikandeDates(index) {
        this.avvikandeDates.removeAt(index);
        let removingDate = this.invalidDevientDates[index];
        this.invalidDevientDates.splice(index, 1);
        this.invalidExcludedDates =
            this.invalidExcludedDates.filter(a => this.datepipe.transform(a, 'yyyy-MM-dd') != this.datepipe.transform(removingDate, 'yyyy-MM-dd'));

    }

    removeShifts(index) {
        this.getShifts.removeAt(index);
    }
    addShifts(index) {
        // this.getShifts.push();
    }

    onDaySelect(day: any, t: any, event: any) {
        this.setDefaultTimeForWeekends(day, t);
    }

    removeExcludedDate(date: any) {
        this.excludedDays = this.excludedDays.filter(item => this.datepipe.transform(item.date, 'yyyy-MM-dd') != this.datepipe.transform(date, 'yyyy-MM-dd'));
        this.invalidExcludedDates =
            this.invalidExcludedDates.filter(a => this.datepipe.transform(a, 'yyyy-MM-dd') != this.datepipe.transform(date, 'yyyy-MM-dd'));
        this.invalidDevientDates =
            this.invalidDevientDates.filter(a => this.datepipe.transform(a, 'yyyy-MM-dd') != this.datepipe.transform(date, 'yyyy-MM-dd'));
    }


    bookings(): FormArray {
        return this.allocationFormLongBooking.get("bookings") as FormArray
    }

    addBooking() {
        this.bookings().push(this.createBooking());
    }


    removeBooking(ti) {
        this.bookings().removeAt(ti);
    }


    /** batches */

    days(ti): FormArray {
        return this.bookings().at(ti).get("shiftDays") as FormArray
    }

    addDay(ti: number) {
        //  this.days(ti).push(this.createBookingDay());
    }

    removeDay(ti: number, bi: number) {
        this.days(ti).removeAt(ti);
    }

    createBooking(id = 0, timePeriodId = null, startTime = null, endTime = null, lunchTime = null, percentage = null, workingDay = null) {
        return this.fb.group({
            id: [id == null ? 0 : id],
            timePeriodId: [timePeriodId == null ? 0 : timePeriodId],
            workingDay: [workingDay, Validators.required],
            shiftDays: this.fb.array([this.createBookingDay(0, true, null, null, null, null, null)]),
        })
    }

    createBookingDay(id, isSelect, shiftId?, startTime?, endTime?, lunchTime?, workingDay = null, shiftDays?) {
        let isSelected = false;
        lunchTime = 0;
        if (isSelect) {
            lunchTime = 45;
            startTime = '09:00';
            endTime = '18:00'
        }

        if (this.selectedLongBookingId) {
            if (startTime && endTime) {
                isSelected = true;
            }

            return this.fb.group({
                id: [id == null ? 0 : id],
                IsSelected: isSelected,
                shiftId: [shiftId == null ? 0 : shiftId],
                startTime: [startTime ? startTime : '', Validators.required],
                endTime: [endTime ? endTime : '', Validators.required],
                lunch: [0, Validators.required],
                validDay: true,
                validTime: true,
                day: [workingDay, Validators.required],
            });
        } else {


            let ba = this.fb.group({
                id: [id == null ? 0 : id],
                IsSelected: isSelected,
                shiftId: [shiftId == null ? 0 : shiftId],
                startTime: [startTime ? startTime : '', Validators.required],
                endTime: [endTime ? endTime : '', Validators.required],
                lunch: [0, Validators.required],
                validDay: true,
                validTime: true,
                day: [workingDay, Validators.required],
            }).value;

            return this.fb.group({
                id: [id == null ? 0 : id],
                IsSelected: isSelect,
                shiftId: [shiftId == null ? 0 : shiftId],
                startTime: [startTime ? startTime : '', Validators.required],
                endTime: [endTime ? endTime : '', Validators.required],
                lunch: [lunchTime, Validators.required],
                validDay: true,
                validTime: true,
                day: [workingDay, Validators.required],
            });
        }

    }

    onSubmit() {
    }

    patchValue2() {

        var data = {
            test: "hii",
            bookingDays: [
                {
                    workingDay: 'Sunday',
                    days: [

                        { workingDay: 'Monday', isSelected: true, weekdayStart: '', weekdayEnd: new Date(), weekdayLunch: 45 },
                        { workingDay: 'Tuesday', isSelected: true, weekdayStart: '', weekdayEnd: new Date(), weekdayLunch: 45 },
                        { workingDay: 'Wednesday', isSelected: true, weekdayStart: '', weekdayEnd: new Date(), weekdayLunch: 45 },
                        { workingDay: 'Thursday', isSelected: true, weekdayStart: '', weekdayEnd: new Date(), weekdayLunch: 45 },
                        { workingDay: 'Friday', isSelected: true, weekdayStart: '', weekdayEnd: new Date(), weekdayLunch: 45 },
                        { workingDay: 'Saturday', isSelected: false, weekdayStart: '', weekdayEnd: new Date(), weekdayLunch: 0 },
                        { workingDay: 'Sunday', isSelected: false, weekdayStart: '', weekdayEnd: new Date(), weekdayLunch: 0 },
                    ]
                }
            ]
        }

        this.clearFormArray();

        data.bookingDays.forEach((t, i) => {
            var booking: FormGroup = this.createBooking(0, null, null, null, null, null, t.workingDay);

            this.bookings().push(booking);

            t.days.forEach((d, index) => {

                var day = this.createBookingDay(index, d.isSelected, null, d.weekdayStart, d.weekdayEnd, d.weekdayLunch, d.workingDay);

                //let bookingsArray = (booking.get("shiftDays") as FormArray);
                (this.bookings().at(i).get("shiftDays") as FormArray).push(day);
                //bookingsArray.push(day);

                //  Object.keys(overTimeRulesArray['controls']).forEach((element, index) => {
                //     const control = overTimeRulesArray.get(element);


                //       }
                //   });
                // b.students.forEach(s => {
                //   (day.get("students") as FormArray).push(this.newStudent())
                // })

            });
        });

        data.test = "hii"
        this.allocationFormLongBooking.patchValue(data);
        this.allocationFormLongBooking.patchValue(this.createAvvikandeDate())
    }

    onTagChanged(time, data, rowIndex): void {
        let validTimePeriod = true;
        let startTime = time.get('startTime').value;
        let endTime = time.get('endTime').value;
        let items = time.get('workingDay').value;

        if (startTime != '' && endTime != '') {
            if (data.length != 0) {
                let selectedValue = [];
                selectedValue.push(data[data.length - 1]); //get the last value selected
                let updatedValues = [];


                if (!validTimePeriod) {
                    alert("Invalid Time Entry : duplicated or overritten time periods occurred.");
                    time.get('workingDay').setValue(updatedValues);
                    if (updatedValues.length == 0) {
                        //if(isStarting)
                        time.get('startTime').setValue('');
                        //else
                        time.get('endTime').setValue('');
                    }
                }
            }
        }
    }

    onExcludedTagChanged(time, data, rowIndex): void {
        let validTimePeriod = true;

        let startTime = time.get('startTime').value;
        let endTime = time.get('endTime').value;
        let items = time.get('excludedDay').value;

        if (startTime != '' && endTime != '') {
            if (data.length != 0) {
                let selectedValue = [];
                selectedValue.push(data[data.length - 1]); //get the last value selected
                let updatedValues = [];

                if (!validTimePeriod) {
                    alert("Invalid Time Entry : duplicated or overritten time periods occurred.");
                    time.get('excludedDay').setValue(updatedValues);
                    if (updatedValues.length == 0) {
                        //if(isStarting)
                        time.get('startTime').setValue('');
                        //else
                        time.get('endTime').setValue('');
                    }
                }
            }
        }
    }

    clearFormArray() {
        //Angular 8 +
        this.bookings().clear();
    }


    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.onClickMakeLongBookingSub.unsubscribe();
    }

    ngAfterViewInit() {

        this.initializeKendoComponents();
        $('.k-scheduler-tools').hide();
        $('.admin-booking-scheduler .k-scheduler-views').append('<li class="k-state-default  k-exportpdf" data-name="Export"><a role="button" href="#" class="k-link mobile-export">Skriv ut</a></li>')
        $('.admin-booking-scheduler .mobile-export').click(() => {
            $('.admin-booking-scheduler .k-pdf').click();
        });
        if (kendo.support.mobileOS) {
            $('.k-sm-date-format').css('font-size', '100%');
        }
        //  this.buyTicketForm.reset();
        // this.allocationFormLongBooking.reset();

    }

    validateAvvikandeDateAndExclunderaDate(date: Date, type: string): boolean {
        let itemCount = 0;
        if (type == 'Avvikande') {
            itemCount = this.excludedDays.filter(item => item.date.toLocaleDateString() == date.toLocaleDateString()).length;
        } else {
            itemCount = this.avvikandeDagarDatesDays.filter(item => item.toLocaleDateString() == date.toLocaleDateString()).length;
        }
        if (itemCount) {
            this.allocationFormLongBooking.patchValue(
                {
                    longBookingStartDate: null,
                    longBookingendDate: null
                }
            )
            alert('excluded dates and avvikande dates can not be same')
            return false;
        }
        return true;
    }

    // onLongBookingFormDateChange(): void {
    //     this.allocationFormLongBooking.get('longBookingStartDate').valueChanges.subscribe(changes1 => {
    //         this.onLongBookingDateChange();
    //     });
    //     this.allocationFormLongBooking.get('longBookingEndDate').valueChanges.subscribe(changes1 => {
    //         this.onLongBookingDateChange();
    //     });
    // }

    onLongBookingDateChange(): void {


        let startDate = this.allocationFormLongBooking.get("longBookingStartDate").value;
        let endDate = this.allocationFormLongBooking.get("longBookingEndDate").value;

        this.allocationFormLongBooking.patchValue({
            longBookingStartDate: this.datepipe.transform(startDate, 'yyyy-MM-dd'),
        });

        this.allocationFormLongBooking.patchValue({
            longBookingEndDate: this.datepipe.transform(endDate, 'yyyy-MM-dd'),
        });

        if (startDate && endDate) {
            this.alliocationModel.endDate = endDate,
                this.alliocationModel.startDate = startDate,
                // this.getAvailableEmployees();
                this.validateStartAndEndDates(startDate, endDate);
        }

        this.enableAddShiftButton = false
        if (startDate != null && endDate != null) {
            this.enableAddShiftButton = true
        }
    }

    // getNumberOfWeeks(start_date: Date, end_date: Date): boolean {
    //     const diff_in_milliseconds = end_date.getTime() - start_date.getTime();
    //     const diff_in_weeks = Math.floor(diff_in_milliseconds / (1000 * 60 * 60 * 24 * 7));

    //     if (this.configClientLongBookingPeriod >= diff_in_weeks + 1) {
    //         const msg = 'Bokning måste vara minst ' + this.configClientLongBookingPeriod + ' vecka lång, start måndag till fredag eller helgen tack!'; //Inquiry adding failed! Please try again.
    //         this.toastr.warning(msg, 'Warnning!');
    //         return false
    //     } else {
    //         return true;
    //     }
    // }

    initializeKendoComponents(): void {

        let x: Date = new Date();
        let y = new Date(x.setDate(x.getDate() + 3));
        x.setDate(x.getDate() + 3);

        ($('#kendo-end-date-long-booking') as any).kendoDatePicker({
            format: 'yyyy-MM-dd',
           // min: new Date(), commented bcoz long booking range delete
            change: (val) => {
                let x = val.sender._value.getDay();
                // if (this.configClientOverrideLongBookingDateRule) {
                //     this.isWeekend = true;
                //     x = 1;
                // } else {
                //     this.isWeekend = (val.sender._value.getDay() === 6) || (val.sender._value.getDay() === 0);

                // }
                // if (!this.isWeekend) {
                //     setTimeout(() => {
                //         this.allocationFormLongBooking.patchValue({
                //             longBookingEndDate: null
                //         });
                //     }, 1);
                //     return 0;
                // } else {

                this.isWeekend = (val.sender._value.getDay() === 6) || (val.sender._value.getDay() === 0);

                const startTime = (this.isWeekend ? '10:00' : '09:00');
                const endTime = (this.isWeekend ? '15:00' : '18:00');
                const lunch = (this.isWeekend ? 0 : 45);
                this.allocationFormLongBooking.patchValue({
                    longBookingEndDate: this.datepipe.transform(val.sender._value, 'yyyy-MM-dd'),
                });
                let startDate = this.allocationFormLongBooking.get("longBookingStartDate").value;
                let endDate = this.allocationFormLongBooking.get("longBookingEndDate").value;

                if (startDate && endDate) {
                    this.alliocationModel.startDate = startDate,
                        this.alliocationModel.endDate = endDate,
                        // this.getAvailableEmployees();
                        this.validateStartAndEndDates(startDate, endDate)
                }

                this.enableAddShiftButton = false
                if (startDate != null && endDate != null) {
                    this.enableAddShiftButton = true
                }

            }
            //  }
        });



        ($('#kendo-start-date-long-booking') as any).kendoDatePicker({
            format: 'yyyy-MM-dd',
          //  min: new Date(), commented bcoz this efffects long booking range delete unable to select previous date
            change: (val) => {
                let x = val.sender._value.getDay();
                this.isWeekend = (val.sender._value.getDay() === 6) || (val.sender._value.getDay() === 0);
                const startTime = (this.isWeekend ? '10:00' : '09:00');
                const endTime = (this.isWeekend ? '15:00' : '18:00');
                const lunch = (this.isWeekend ? 0 : 45);

                // if (this.configClientOverrideLongBookingDateRule) {
                //     this.isWeekend = false;
                //     x = 1;
                // }

                // if ((this.isWeekend || x != 1)) {
                //     //   console.log("invalid start date")
                //     setTimeout(() => {
                //         this.allocationFormLongBooking.patchValue({
                //             longBookingStartDate: null
                //         });
                //     }, 1);
                //     return 0;
                // } else {

                this.allocationFormLongBooking.patchValue({
                    longBookingStartDate: this.datepipe.transform(val.sender._value, 'yyyy-MM-dd'),
                });
                let startDate = this.allocationFormLongBooking.get("longBookingStartDate").value;
                let endDate = this.allocationFormLongBooking.get("longBookingEndDate").value;
                if (startDate && endDate) {
                    this.alliocationModel.endDate = endDate,
                        this.alliocationModel.startDate = startDate,
                        // this.getAvailableEmployees();
                        this.validateStartAndEndDates(startDate, endDate);
                }

                this.enableAddShiftButton = false
                if (startDate != null && endDate != null) {
                    this.enableAddShiftButton = true
                }
                // $('#kendo-wd-start-long-booking').data('kendoTimePicker').value(startTime);
                // $('#kendo-end-date-long-booking').data('kendoTimePicker').value(endTime);
            }
            // }
        });

        ($('#avvikandera-date') as any).kendoDatePicker({
            format: 'yyyy-MM-dd',
            min: new Date(),
            change: (val) => {

                if (val.sender._value) {
                    this.isWeekend = (val.sender._value.getDay() === 6) || (val.sender._value.getDay() === 0);
                    const startTime = (this.isWeekend ? '10:00' : '09:00');
                    const endTime = (this.isWeekend ? '15:00' : '18:00');
                    const lunch = (this.isWeekend ? 0 : 45);

                    if (this.validateAvvikandeDateAndExclunderaDate(val.sender._value, 'Avvikande')) {
                        this.disableAvvikandeDagarButton = false;
                        this.avvikandeDagarDatesDays.push(val.sender._value)
                    } else {
                        this.disableAvvikandeDagarButton = true;
                    }
                    val = null;

                    // this.allocationFormLongBooking.patchValue({
                    //    startDate: val.sender._value,
                    //     endDate: val.sender._value,
                    //     weekdayStart: startTime,
                    //     weekdayEnd: endTime,
                    //     weekdayLunch: lunch
                    // });

                    // $('#kendo-wd-start-long-booking').data('kendoTimePicker').value(startTime);
                    //  $('#kendo-wd-end-long-booking').data('kendoTimePicker').value(endTime);
                }
            }
        });

        ($('#excludera-date') as any).kendoDatePicker({
            format: 'yyyy-MM-dd',
            min: new Date(),
            change: (val) => {
                this.isWeekend = (val.sender._value.getDay() === 6) || (val.sender._value.getDay() === 0);
                const startTime = (this.isWeekend ? '10:00' : '09:00');
                const endTime = (this.isWeekend ? '15:00' : '18:00');
                const lunch = (this.isWeekend ? 0 : 45);

                if (this.validateAvvikandeDateAndExclunderaDate(val.sender._value, 'ExcluderaDate')) {

                    if (this.checkDateRangeIsValid(val.sender._value)) {
                        this.excludedDays.push({ id: 1, date: val.sender._value });
                        this.isInvalidExcludeDate = false;
                        val = null;
                    } else {
                        this.isInvalidExcludeDate = true;
                    }
                    let item = this.fb.array(this.excludedDays);
                    this.allocationFormLongBooking.patchValue({
                        excludedDays: null
                    });

                }
            }
        });

    }

    get isValidLongBooking(): boolean {

        if (this.selectedEmployees.length && !this.disableAvvikandeDagarButton && this.validShiftTime) {
            return true
        }
        return false;
    }

    validateStartAndEndDates(startDate: any, endDate: any) {
        this.validLongBookingDates = false;
        if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
            let bookingEndDate: any = this.allocationFormLongBooking.controls["longBookingEndDate"];
            bookingEndDate.errors = "Invalid End Date";
            this.disableAvvikandeDagarButton = true;

        } else {
            this.avvikandeMinDate = new Date(startDate);
            this.avvikandeMaxDate = new Date(endDate);
            this.validLongBookingDates = true;
            this.disableAvvikandeDagarButton = false;
            this.calculateShiftTime();
            this.changeExcludedDatesAndDevientDates();
            this.getEmployeeList();

            // const validNoOfWeeks = this.getNumberOfWeeks(new Date(startDate), new Date(endDate))
            // if (validNoOfWeeks) {
            //     this.getEmployeeList();
            // }
            // this.getMatchedEmployeesForSaveLongBooking()
        }
    }

    changeExcludedDatesAndDevientDates(): void {
        let longBookingStartDate = this.allocationFormLongBooking.get('longBookingStartDate').value;
        let longBookingendDate = this.allocationFormLongBooking.get('longBookingEndDate').value;
        let dateRange: Date[] = [];
        dateRange = this.getDatesRange(new Date(longBookingStartDate), new Date(longBookingendDate));
        this.excludedDays.forEach(item => {
            // let b = this.datepipe.transform(item.date, 'yyyy-MM-dd')
            let index = dateRange.findIndex(date => this.datepipe.transform(date, 'yyyy-MM-dd') == this.datepipe.transform(item.date, 'yyyy-MM-dd'));
            if (index < 0) {
                this.excludedDays = this.excludedDays.filter(ex => ex.date != item.date);
            }
        })

        let devianDates = this.allocationFormLongBooking.get('avvikandeDates').value;
        if (devianDates) {
            devianDates.forEach((currentValue, index) => {
                let i = dateRange.findIndex(date => this.datepipe.transform(date, 'yyyy-MM-dd') == this.datepipe.transform(currentValue.date, 'yyyy-MM-dd'));
                if (i < 0) {
                    this.removeAvvikandeDates(index);
                }
            });
        }

    }

    setEndTimeMin(dt, option?) {
        if (option == 'tl') {
            var endTimepicker = $("#kendo-tl-end").data("kendoTimePicker");
        } else if (option == 'tu') {
            var endTimepicker = $("#kendo-tu-end").data("kendoTimePicker");
        } else if (option == 'au') {
            var endTimepicker = $("#kendo-au-end").data("kendoTimePicker");
        } else {
            var endTimepicker = $("#kendo-wd-end").data("kendoTimePicker");
        }
        // endTimepicker.min(new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), (dt.getMinutes() == 45 ? dt.getHours()+1:dt.getHours()), (dt.getMinutes() == 45 ? 0:dt.getMinutes()+15 ), 0));
    }

    setStartTimeMax(dt, option?) {
        if (option == 'tl') {
            var startTimepicker = $("#kendo-tl-start").data("kendoTimePicker");
        } else if (option == 'tu') {
            var startTimepicker = $("#kendo-tu-start").data("kendoTimePicker");
        } else if (option == 'au') {
            var startTimepicker = $("#kendo-au-start").data("kendoTimePicker");
        } else {
            var startTimepicker = $("#kendo-wd-start").data("kendoTimePicker");
        }
        // startTimepicker.max(new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), (dt.getMinutes() == 0 ? dt.getHours()-1:dt.getHours()), (dt.getMinutes() == 0 ? 45:dt.getMinutes()-15 ), 0));
    }

    onClickMakeLongBooking() {
        this.calendarService.emmitOnClickMakeLongBooking("reset");
        this.allocationFormLongBooking.reset();

        this.allocationFormLongBooking.patchValue({
            excludedDays: null
        });
        this.clientCommonTimes = []
        if (this.isBookingSelected) {
            this.enableAddShiftButton = false;
            this.excludedDays = [];
            this.showEditButton = false;
            this.selectedTemplate = null;
            let avvikandeDates: any;
            avvikandeDates = this.allocationFormLongBooking.get('avvikandeDates').value;
            this.allocationFormLongBooking.patchValue({
                clientId: this.clientId,
                employeeIds: []
            });
            this.availableEmployees = []

            avvikandeDates.forEach((currentValue, index) => {
                this.removeAvvikandeDates(index);
            });
            let avvikandeDatesItem = this.allocationFormLongBooking.get('avvikandeDates') as FormArray;

            this.resetShiftForm();

            this.allocationFormLongBooking.patchValue({
                longBookingStartDate: this.datepipe.transform(null, 'yyyy-MM-dd'),
                longBookingEndDate: this.datepipe.transform(null, 'yyyy-MM-dd'),
                templateId: null,
                avvikandeDates: []
            });
            this.calendarService.getClientCommonTimes(this.clientId).subscribe((response) => {
                this.clientCommonTimes = response;
                ($('#requestAllocationForLongBookinModal') as any).modal('show');
            })
        } else {
            ($('#requestAllocationForLongBookinModal') as any).modal('show');
        }
    }

    setAvailableEmployeesForLongBooking() {
        const validator = ($('#allocationFormContentForLongBooking') as any).kendoValidator({
            rules: {
                matches: function (input) {
                    if (input.data('greaterthan') == 'kendo-wd-start') {
                        var startTimeStr = $('#kendo-wd-start').val() as string;
                        var endTimeStr = $('#kendo-wd-end').val() as string;

                        var startWDTime = new Date(2000, 0, 0, parseInt(startTimeStr.slice(0, 2)), parseInt(startTimeStr.slice(3, 5)), 0);
                        var endWDTime = new Date(2000, 0, 0, parseInt(endTimeStr.slice(0, 2)), parseInt(endTimeStr.slice(3, 5)), 0);

                        if (startWDTime < endWDTime) {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return true
                    }
                }
            },
        }).data('kendoValidator');
        if (validator.validate()) {
            if (!this.IsOnChangeEvent) {
                if (this.userType && this.userType === 'Client' && this.clientId != 'all' && this.isBookingSelected) {
                    this.allocationFormLongBooking.patchValue({
                        clientId: this.clientId,
                        isInquiry: !this.isBookingSelected
                    });
                    if (this.role == 'Admin') {
                        this.calendarService.getAvailableEmployees(this.allocationFormLongBooking.value).subscribe((availableEmpList) => {
                            if (availableEmpList) {
                                this.availableEmployees = availableEmpList
                                if (availableEmpList.length > 0) {
                                    var preSystemSelectedEmployee = availableEmpList.find(element => {
                                        return element.isDefaultMatched == true
                                    });
                                }
                                if (preSystemSelectedEmployee) {
                                    //   setTimeout(() => {
                                    this.allocationFormLongBooking.get("employeeId").setValue(preSystemSelectedEmployee.value);
                                    //   }, 200);
                                }
                            } else {
                                this.allocationFormLongBooking.patchValue({
                                    employeeId: '',
                                });
                                this.availableEmployees = []
                            }
                            ($('#requestAllocationForLongBookinModal') as any).modal('show');
                        })
                    } else {
                        ($('#requestAllocationForLongBookinModal') as any).modal('show');
                    }

                } else {
                    ($('#requestAllocationForLongBookinModal') as any).modal('show');
                }
            }
        }
        this.IsOnChangeEvent = false;
    }

    showNotification(header: string, description: string, type: string) {

        if (type == 'success') {
            this.toastr.success(description, header);
        } else if (type == 'danger') {
            this.toastr.error(description, header);
        } else {
            this.toastr.warning(description, header);
        }
    }

}

export class TimeCalculation {
    weekStart: any;
    weekEnd: any;
    shift: any;
    days: Day[];
}

export class Day {
    day: string;
    dayName: string;
    startTime: any;
    endTime: any;
    lunch: any;
    totalMins: any;
    isSelected: boolean;
}

export class ShiftTimeDetails {
    id: number;
    totalTimeInMins: number;
    totalTimeInHours: string;
}
