import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { Router, Event, NavigationEnd, NavigationStart } from '@angular/router';
import { SecurityService } from '../security/security.service';
import { AppUserAuth } from '../security/app-user-auth';
import { Subscription, Subject } from 'rxjs';
import { UserService } from '../user/user.service';
import { ConfigService } from './services/config.service';

@Component({
  selector: 'wfm-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit, OnDestroy {

  @Output() tabSelected: EventEmitter<{}> = new EventEmitter<{}>();
  @Input() userType?: Subject<string>

  username: string;
  role: string;
  userImage: string = '';
  securityObject: AppUserAuth;
  subscription: Subscription;
  routeSubscription: Subscription;
  showMobileNav: boolean = true
  currentURL: string
  selectedUserType: string = ''
  userTypeSubscription: Subscription

  deviceWidth: number = window.innerWidth;
  deviceHight: number = window.innerHeight;
  logoImageUrl: string;

  constructor(private router: Router, private securityService: SecurityService, private userService: UserService, private configService: ConfigService) {

    this.subscription = this.userService.reveiveImageUpdateNotification().subscribe(st => {
      if (st) {
        this.userImage = '../../assets/images/userImages/'.concat(st.path);
      }
    });

    this.routeSubscription = this.router.events.subscribe((event: Event) => {
      this.currentURL = ''
      if (event instanceof NavigationStart) {
        this.currentURL = ''
      }
      if (event instanceof NavigationEnd) {
        this.currentURL = event.url.split('?')[0]

        if (
          (this.currentURL == '/users/calendar') ||
          (this.currentURL == '/users/calendar/bookings') ||
          (this.currentURL == '/users/calendar/inquiries') ||
          (this.currentURL == '/clients/calendar') ||
          (this.currentURL == '/clients/calendar/bookings') ||
          (this.currentURL == '/clients/calendar/inquiries') ||
          (this.currentURL == '/bookings') ||
          (this.currentURL == '/bookings/bookings') ||
          (this.currentURL == '/bookings/inquiries') ||
          (this.currentURL == '/calendar')
        ) {
          this.showMobileNav = true
        } else {
          this.showMobileNav = false
        }
      }
    })
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.routeSubscription.unsubscribe()
    if (this.userTypeSubscription) {
      this.userTypeSubscription.unsubscribe()
    }
  }

  ngOnInit() {
    this.username = localStorage.getItem('username');
    this.role = localStorage.getItem('role');
    let img = localStorage.getItem('userImage');
    this.userImage = '../../assets/images/userImages/'.concat(img);

    //  this.logoImageUrl ="https://teamplanstorage.blob.core.windows.net/prodimages/logo.png";
    // this.logoImageUrl ="https://teamplanstorage.blob.core.windows.net/prodimages/demo top navbar img.png";

    this.configService.getConfigData().subscribe((res) => {
      localStorage.setItem("logoImageUrl", res.configuration.logoImagePath);
      this.logoImageUrl = '../../assets/images/'.concat(res.configuration.logoImagePath);
    })


    this.securityObject = this.securityService.securityObject;
    if (this.userType) {
      this.userTypeSubscription = this.userType.subscribe((res) => {
        this.selectedUserType = res
      })
    }

    // Close menu on outside click
    document.body.addEventListener('click', function (e) {
      if ($("#navbarSupportedContent")) {
        const { classList } = e.target as HTMLSpanElement;
        if (!(classList[0] == 'navbar-toggler-btn')) {
          $("#navbarSupportedContent").removeClass('show')
        }
      }
    }, true);
  }

  logOut(): void {
    this.securityService.logout();
    this.router.navigate(['/login']);
    this.userService.emmitNewSelectedUser(null)
  }

  logoClick(): void {
    if (this.securityService.hasClaim('CanAccessAdminFeatures', 'true')) {
      this.router.navigate(['/users/calendar']);
    } else if (this.securityService.hasClaim('CanAccessEmployeeFeatures', 'true')) {
      this.router.navigate(['/bookings']);
    } else if (this.securityService.hasClaim('CanAccessClientFeatures', 'true')) {
      this.router.navigate(['/calendar']);
    } else if (this.securityService.hasClaim('CanAccessSuperClientFeatures', 'true')) {
      this.router.navigate(['/clients/calendar']);
    }
  }

  onClickTab(tab: string) {

    if ((this.deviceWidth < 768)) {


      $('.mob-tab').removeClass('btn-success')
      $('.mob-tab').addClass('tab-inactive')
      if (tab != 'userlist') {
        $('.mob-tab').removeClass('last-selection')
      }
      if (tab == 'userlist') {
        $('.mob-userlist').addClass('btn-success')
        $('.mob-userlist').removeClass('tab-inactive')
      } else if (tab == 'bookings') {
        $('.mob-bookings').addClass('btn-success')
        $('.mob-bookings').addClass('last-selection')
        $('.mob-bookings').removeClass('tab-inactive')
        $('.nav-link').removeClass('nav_active');
        $('.nav-link.link-booking').addClass('nav_active');

      } else if (tab == 'inquiry') {
        $('.mob-inquiry').addClass('btn-success')
        $('.mob-inquiry').addClass('last-selection')
        $('.mob-inquiry').removeClass('tab-inactive')
        $('.nav-link').removeClass('nav_active');
        $('.nav-link.link-inquiry').addClass('nav_active');
      }
      this.tabSelected.emit(tab)
    }
  }

  inquiryClick() {
    setTimeout(() => {
      $('.mob-inquiry').click()
      $('.mob-inquiry').addClass('outside-click');
      $('.nav-link').removeClass('nav_active');
      $('.nav-link.link-inquiry').addClass('nav_active');
    }, 100);

    $('.navbar-collapse.collapse.show').removeClass('show')
  }

  bookingClick() {
    setTimeout(() => {
      $('.mob-bookings').click()
    }, 100);

    $('.navbar-collapse.collapse.show').removeClass('show')
  }

  skapaBookingClick() {
    setTimeout(() => {
      this.makeBookingClick()
    }, 700);

    $('.navbar-collapse.collapse.show').removeClass('show')
  }

  agendaClick() {
    // setTimeout(() => {
    $('.k-view-agenda').click()
    $('.nav-link').removeClass('nav_active');
    $('.nav-link.link-agenda').addClass('nav_active');
    // }, 200);
    this.onClickTab('bookings');

    $('.navbar-collapse.collapse.show').removeClass('show')
  }

  bookingLinkClick() {
    // setTimeout(() => {
    $('.k-view-month').click()
    $('.nav-link').removeClass('nav_active');
    $('.nav-link.link-booking').addClass('nav_active');
    // }, 200);

    $('.navbar-collapse.collapse.show').removeClass('show')
  }
  updateAvailability() {
    setTimeout(() => {
      $('.updateAvailabilityAdmBtn').click()
    }, 700);

    $('.navbar-collapse.collapse.show').removeClass('show')
  }

  updateAvailabilityClick() {
    $('.updateAvailabilityAdmBtn').click()
    $('.navbar-collapse.collapse.show').removeClass('show')
  }

  makeBookingClick() {
    $('.makeBookingAdmBtn').click()
    $('.navbar-collapse.collapse.show').removeClass('show')
  }

  makeBookingSCClick() {
    $('.makeBookingSClientBtn').click()
    $('.navbar-collapse.collapse.show').removeClass('show')
  }
  // updateAvailabilityEmpClick(){
  //   $('.updateAvailabilityEmpBtn').click()
  //   $('.navbar-collapse.collapse.show').removeClass('show')
  // }
  // agendaClick(){
  //   $('.k-view-agenda').click()
  //   $('.navbar-collapse.collapse.show').removeClass('show')
  // }
}
