import { Component, OnInit, AfterViewInit, ViewChildren, ElementRef } from '@angular/core';
import { FormGroup, FormControlName, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { GenericValidator } from './../../shared/generic-validator';
import { Subscription, timer, Observable, merge, fromEvent } from 'rxjs';
import { switchMap, map, debounceTime } from 'rxjs/operators';
import { IUserInfo } from './../user-info';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from './../user.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
@Component({
  selector: 'wfm-superclient-user-edit',
  templateUrl: './superclient-user-edit.component.html',
  styleUrls: ['./superclient-user-edit.component.css']
})
export class SuperclientUserEditComponent implements OnInit, AfterViewInit {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

  userForm: FormGroup;
  resetPasswordForm: FormGroup;
  pageTitle: string;
  errorMessage: string;
  userTypes: any[];
  userInfo: IUserInfo = {} as IUserInfo;
  previewUrl: any;
  userImage = '';
  fileData: File = null;
  updateUserId: string;
  isResetPassword = false;
  displayMessage: { [key: string]: string } = {};
  displayResetPwdMessage: { [key: string]: string } = {};
  imageChangedEvent: any = '';
  croppedImage: any = '';
  cropperVisible:boolean
  isSubmitInvalid:boolean

  private sub: Subscription;
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;

  countyMunicipalities: any[] = [];
  counties: any[] = [];
  municipalities: any[] = [{value:null, label: ''}, {value:null, label: ''}];
  pharmacySystems: any[] = [];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
    ) {
      this.userTypes = [
        { id: '3', name: 'Client', sename: 'Enhet' }
      ];

      this.validationMessages = {
        userTypeId: {
          required: 'anställningsform krävs'
        },
        firstName: {
          required: 'förnamn krävs',
          minlength: 'måste ha minst 2 tecken',
          maxlength: 'max 50 tecken'
        },
        lastName: {
          required: 'efternamn krävs',
          minlength: 'måste ha minst 2 tecken',
          maxlength: 'max 50 tecken'
        },
        address: {
          required: 'adress krävs',
          maxlength: 'max 100 tecken'
        },
        city: {
          required: 'stad krävs',
          maxlength: 'max 50 tecken'
        },
        zip: {
          required: 'postnummer krävs',
          maxlength: 'max 10 tecken'
        },
        email: {
          required: 'e-post krävs',
          email: 'e-posten ogiltig',
          maxlength: 'max 256 tecken'
        },
        phoneNumber: {
          required: 'telefonnummer krävs',
          maxlength: 'max 50 tecken'
        },
        jobCategoryId: {
          required: 'anställningsform krävs'
        },
        clientName: {
          required: 'apoteketsnamn krävs',
          maxlength: 'max 100 tecken'
        },
        pharmacyId: {
          required: 'apotek-ID krävs',
          maxlength: 'max 100 tecken'
        },
        userName: {
          required: 'användarnamn krävs',
          minlength: 'användarnamn måste ha minst 3 tecken'
        },
        password: {
          required: 'lösenord krävs',
          minlength: 'lösenord måste ha minst 6 tecken'
        },
        conpassword: {
          required: 'lösenord krävs',
          invalid: 'lösenord matchar inte'
        },
        resetpassword: {
          required: 'lösenord krävs',
          minlength: 'lösenord måste ha minst 6 tecken'
        },
        resetconpassword: {
          required: 'lösenord krävs',
          match: 'vlösenord matchar inte'
        }
      };

      this.genericValidator = new GenericValidator(this.validationMessages);
    }

  setCustomVallidations() {
    const usernameControl = this.userForm.get('userName');
    const passwordControl = this.userForm.get('passwords.password');
    const conpasswordControl = this.userForm.get('passwords.conpassword');
    const municipalityControl = this.userForm.get('municipality');
    const pharmacySystemsControl = this.userForm.get('pharmacySystems');
    const countyControl = this.userForm.get('county');

    usernameControl.setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(20)]);
    municipalityControl.setValidators([Validators.required]);
    //pharmacySystemsControl.setValidators([Validators.required]);
    countyControl.setValidators([Validators.required]);

    if (this.updateUserId == "0") {
      passwordControl.setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(50)]);
      conpasswordControl.setValidators([Validators.required]);
      usernameControl.setAsyncValidators(this.functionloginAsyncValidator(this.userService));
    }
    else{
      usernameControl.setAsyncValidators(this.functionloginEditAsyncValidator(this.userService));
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  ngOnInit() {
    this.userForm = this.fb.group({
      userId: [''],
      userTypeId: [''],
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      clientName: ['', [Validators.required, Validators.maxLength(100)]],
      pharmacyId: ['', [Validators.required, Validators.maxLength(100)]],
      address: ['', [Validators.required, Validators.maxLength(100)]],
      city: ['', [Validators.required, Validators.maxLength(20)]],
      zip: ['', [Validators.required, Validators.maxLength(10)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(256)]],
      phoneNumber: ['', [Validators.required, Validators.maxLength(50)]],
      userName: ['', null],
      passwords: this.fb.group({
        password: [''],
        conpassword: [''],
      }, { validator: passwordCompare }),
      password: [],
      conpassword: [],
      isActive: [true],
      county: [[],[Validators.required]],
      municipality: ['',[Validators.required]],
      //pharmacySystems: [[],[Validators.required]],
      isStaredEmployeesOnly: [null],
      countyMunicipalities: [[]]
    });

    this.resetPasswordForm = this.fb.group({
      resetpassword: ['', Validators.required],
      resetconpassword: ['', Validators.required]
    }, { validator: resetPasswordCompare });

    // Read the product Id from the route parameter
    this.sub = this.route.paramMap.subscribe(
      params => {
        const id = params.get('id');
        this.updateUserId = id;
        this.getSubClientUser(id);
      }
    );

    this.userService.getInnitialMasterData().subscribe((data) => {

      this.pharmacySystems = data.pharmacySystems
      this.counties = data.counties

      for (let [key, value] of Object.entries(this.pharmacySystems)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
      for (let [key, value] of Object.entries(this.counties)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }

    })

    this.setCustomVallidations();
  }

  functionloginAsyncValidator(authService: UserService, time: number = 500) {
    return (input: FormControl) => {
      return timer(time).pipe(
        switchMap(() => authService.checkUsername(input.value, this.updateUserId)),
        map(res => {
          return res ? { loginExist: true } : null  ;
        })
      );
    };
  }

  functionloginEditAsyncValidator(authService: UserService, time: number = 500) {
    return (input: FormControl) => {
      return timer(time).pipe(
        switchMap(() => authService.checkUsername(input.value, this.updateUserId)),
        map(res => {
          return res ? { loginExist: true } : null  ;
        })
      );
    };
  }

  ngAfterViewInit(): void {
    // // Watch for the blur event from any input element on the form.
    // const controlBlurs: Observable<any>[] = this.formInputElements
    //   .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    // // Merge the blur event observable with the valueChanges observable
    // merge(this.userForm.valueChanges, ...controlBlurs).pipe(
    //   debounceTime(300)
    // ).subscribe(value => {
    //   this.displayMessage = this.genericValidator.processMessages(this.userForm);
    // });

    // // Merge the blur event observable with the valueChanges observable
    // merge(this.resetPasswordForm.valueChanges, ...controlBlurs).pipe(
    //   debounceTime(300)
    // ).subscribe(value => {
    //   this.displayResetPwdMessage = this.genericValidator.processMessages(this.resetPasswordForm);
    // });
  }

  isUserExists(): boolean {
    return this.userForm.get('userName').hasError('loginExist');
  }

  getSubClientUser(id: string): void {
    let userTypeId = this.userTypes[0].id;
    this.userService.getById(id, userTypeId)
      .subscribe(
        (user: IUserInfo) =>{
          if (user.countyMunicipalities && user.countyMunicipalities.length>0) {
            this.userService.getMunicipalitiesByCountyId(user.countyMunicipalities[0].countyId).subscribe((data: []) => {
              this.municipalities = data
              for (let [key, value] of Object.entries(this.municipalities)) {
                value['value'] = value.id;
                value['label'] = value.name;
              }
              this.displayUser(user)
            });
          } else {
            this.displayUser(user)
          }
         
        },
        (error: any) => this.errorMessage = error as any
      );
  }

  displayUser(user: IUserInfo): void {
    if (this.userForm) {
      this.userForm.reset();
    }
    this.userInfo = user;

    if (this.userInfo.userId === '0') {
      this.pageTitle = 'Ange Användarinformation';
    } else {
      this.userImage = this.userInfo.imagePath;
      this.pageTitle = `Redigera Användarinformation: ${this.userInfo.firstName}`;
      const userTypeControl = this.userForm.get('userTypeId');
      userTypeControl.disable();
    }
    // Update the data on the form
    this.userForm.patchValue({
      userId: this.userInfo.userId,
      userTypeId: this.userInfo.userTypeId,
      firstName: this.userInfo.firstName,
      lastName: this.userInfo.lastName,
      clientName: this.userInfo.clientName,
      pharmacyId: this.userInfo.pharmacyId,
      address: this.userInfo.address,
      city: this.userInfo.city,
      zip: this.userInfo.zip,
      email: this.userInfo.email,
      phoneNumber: this.userInfo.phoneNumber,
      isActive: this.userInfo.isActive.toString(),
      userName: this.userInfo.userName
    });

    
    if (this.userInfo.userTypeId == '3') {
      this.userForm.patchValue({
        county: this.userInfo.countyMunicipalities && this.userInfo.countyMunicipalities[0]? this.userInfo.countyMunicipalities[0].countyId:'',
        municipality: this.userInfo.countyMunicipalities && this.userInfo.countyMunicipalities[0]? this.userInfo.countyMunicipalities[0].municipalityId:'',
        pharmacySystems:  this.userInfo.pharmacySystems? this.userInfo.pharmacySystems[0]:'',
        isStaredEmployeesOnly:   this.userInfo.isStaredEmployeesOnly,
        // favouriteEmployees: this.userInfo.favouriteEmployees
      });
      this.userForm.value.municipality = this.userInfo.countyMunicipalities && this.userInfo.countyMunicipalities[0]? this.userInfo.countyMunicipalities[0].municipalityId:''
      // this.userForm.value.favouriteEmployees = this.userInfo.favouriteEmployees
    }
  }

  saveUser(): void {
    if (this.userForm.valid) {
      if (this.userForm.dirty) {
        if (this.userForm.value.county && this.userForm.value.municipality) {
          this.userForm.patchValue({
            countyMunicipalities: [{ countyId: this.userForm.value.county, municipalityId: this.userForm.value.municipality }],
            // pharmacySystems: [this.userForm.value.pharmacySystems]
          })
        } else {
          this.userForm.patchValue({
            countyMunicipalities: []
          })
        }
        this.userForm.value.pharmacySystems = this.userForm.value.pharmacySystems? [this.userForm.value.pharmacySystems]:[]
        this.userForm.value.favouriteEmployees =this.userForm.value.favouriteEmployees? this.userForm.value.favouriteEmployees:[]


        const u = { ...this.userInfo, ...this.userForm.value };
        if (u.userId === '0') {
          u.password = this.userForm.get('passwords.password').value;
          u.isActive = true;
          u.userType = this.userTypes.filter((element, index, array) => {
            return element.id === u.userTypeId;
          })[0].name;
          this.userService.createSubClientUser(u)
            .subscribe(
              (userId) => {
                this.onSaveComplete('add', true, userId);
              },
              (error: any) => this.errorMessage = error as any
            );
        } else {
          u.image = this.userInfo.image;
          this.userService.updateSubClientUser(u)
            .subscribe(
              () => this.onSaveComplete('update', true, u.userId),
              (error: any) => this.errorMessage = error as any
            );
        }
      } else {
        this.onSaveComplete('update', true, this.userForm.get('userId').value);
      }
    } else {
      this.validateAllFormFields(this.userForm)
      this.validateAllFormFields(this.resetPasswordForm)
      this.errorMessage = 'Please correct the validation errors.';
      this.isSubmitInvalid = true
    }
  }

  onSaveComplete(flow: string, status: boolean, userId: string): void {
    if (this.fileData && userId != null) {
      const formData = new FormData();
      formData.append('file', this.fileData);
      formData.append('userId', userId);

      this.userService.uploadUserImage(formData)
        .subscribe(
          (st: boolean) => {
            this.userForm.reset();
            this.router.navigate(['/clients/users'], { state: { flow, status } });
          },
          (error: any) => this.errorMessage = error as any
        );
    } else {
      this.userForm.reset();
      this.router.navigate(['/clients/users'], { state: { flow, status } });
    }
  }


  uploadImageClick(): void {
    $("#fileUploadBtn").click();
  }

  // Cropper Functions
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.cropperVisible = true
    $('#upload-demo').css('display','block')
  }
  imageCropped(event: ImageCroppedEvent) {
    // For Preview
    this.croppedImage = event.base64;
    // prepare for upload
    const fileBeforeCrop = this.imageChangedEvent.target.files[0];
    var strImage = this.croppedImage.replace(/^data:image\/[a-z]+;base64,/, "");
    const imageBlob = this.dataURItoBlob(strImage);
    this.fileData = new File([imageBlob], fileBeforeCrop.name, {type: fileBeforeCrop.type}) as File
  }
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });    
    return blob;
 }

  removeImage(): void {
    if (this.fileData) {
      this.fileData = null;
      this.previewUrl = null;
      this.croppedImage = ''
    }
    if (this.userImage) {
      this.userImage = '';
      this.userInfo.image = null;
      this.userInfo.imagePath = null;
    }
    this.cropperVisible = false
    $('#upload-demo').css('display','none')
  }
  cancelCrop(){
    this.fileData = null
    this.cropperVisible = false
    $('#upload-demo').css('display','none')
  }

  toggleCropper(){
    this.cropperVisible = !this.cropperVisible
    if ( this.cropperVisible) {
      $('#upload-demo').css('display','block')
    } else {
      $('#upload-demo').css('display','none')
    }
  }

  showResetPasswordForm(): void {
    this.isResetPassword = !this.isResetPassword;
  }

  resetPassword(): void {
    const formData = new FormData();
    formData.append('userId', this.updateUserId);
    formData.append('pwd', this.resetPasswordForm.get('resetpassword').value);
    this.userService.resetPassword(formData)
      .subscribe(
        (st: boolean) => {
          this.resetPasswordForm.reset();
          this.router.navigate(['/clients/users'], { state: { flow: 'update', status: st } });
        },
        (error: any) => this.errorMessage = error as any
      );
  }

  private preview() {
    if (this.fileData) {
      const mimeType = this.fileData.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = (_event) => {
        this.previewUrl = reader.result;
      };
    }
  }

  onCountyChange(): void {
    if (this.userForm.value.county > 0) {
      this.userForm.patchValue({
        municipality: []
      })
      this.userService.getMunicipalitiesByCountyId(this.userForm.value.county).subscribe((data: []) => {
        this.municipalities = data
        for (let [key, value] of Object.entries(this.municipalities)) {
          value['value'] = value.id;
          value['label'] = value.name;
        }
      },
        (error: any) => this.errorMessage = error as any
      )
    }
  }
}

function resetPasswordCompare(c: AbstractControl): { [key: string]: boolean } | null {
  const pwdControl = c.get('resetpassword');
  const conPwdControl = c.get('resetconpassword');

  if (pwdControl.pristine || conPwdControl.pristine) {
    return null;
  }

  if (pwdControl.value !== conPwdControl.value) {
    return { 'match': true };
  }
  return null;
}

function passwordCompare(c: AbstractControl): { [key: string]: boolean } | null {
  const pwdControl = c.get('password');
  const conPwdControl = c.get('conpassword');

  if (pwdControl.pristine || conPwdControl.pristine) {
    return null;
  }

  if (pwdControl.value !== conPwdControl.value) {
    return { 'match': true };
  }
  return null;
}
