import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AlliocationModel } from './alliocation-model';
import { Observable, Subject, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AllocationInfo } from './allocation-info';
import { AvailabilityModel } from './availability-model';
import { AvailabilityInfo } from './availability-info';
import { AvailabilityUpdateModel } from './availability-update-model';
import { TimeLogModel } from './time-log-model';
import { SwapEmployeeModel } from './swap-employee-model';
import { BookingInfo } from './booking-info';
import { ClientBookingInfo } from './client-booking-info';
import { IUserInfo } from '../user/user-info';
import { environment } from '../../environments/environment';
import { PartialAvailabilityModel } from './partial-availability-model';
import { PartialAvailabilityInfo } from './partial-availability-info';
import SwedishHolidays from '../shared/constants/swedishHolidays';

const API_URL = environment.apiEndpoint;

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    }),
};

@Injectable({
    providedIn: 'root'
})
export class CalendarService {

    swedishHolidays: typeof SwedishHolidays;

    constructor(private http: HttpClient) {
        this.swedishHolidays = SwedishHolidays;
        this.getConfigData().subscribe((res) => {
            this.resourceTypes = res.resourceTypes;
        })
    }

    public onClickMakeBooking = new Subject<any>();
    public onClickMakeLongBooking = new Subject<any>();
    public onClickMakeInquiry = new Subject<any>();

    public OnClickAvailabilityUpdate = new Subject<any>();
    public onResourceTypeChange = new Subject<any>();
    public onSelectLongBooking = new Subject<any>();

    public selectedMonth = new Subject<any>();
    public userListAction = new Subject<any>();
    // public swedishHolidays
    public resourceTypes


    getAllocations(userId: string, role: string, viewStartDate: Date, viewEndDate: Date): Observable<BookingInfo[]> {

        let url = ''
        let fromDate = viewStartDate.toISOString()
        let toDate = viewEndDate.toISOString()
        if (role == 'Admin') {
            url = 'allocations';
        } else if (role == 'SuperClient') {
            url = 'allocations/superclient/getallocations';
        } else if (role == 'Employee') {
            url = 'allocations/employee';
        }
        let queryParamStr = ''
        queryParamStr += '?fromDate=' + fromDate + '&toDate=' + toDate
        queryParamStr += (userId === '' || userId === 'all') ? '' : '&userId=' + userId

        return this.http.get<BookingInfo[]>(API_URL + url + queryParamStr, httpOptions).pipe(
            tap(resp => {
                // resp.reverse();
                // resp.length = 10;
                resp.forEach((r) => {
                    r.start = new Date(r.sDate);
                    r.end = new Date(r.eDate);

                    if (r.start.toDateString() !== new Date().toDateString() && r.start < new Date()) {
                        if (r.isTimeLogged) {
                            r.colorClass = 'slot-blue';
                            r.timeLoggedClass = 'slot-has-check';
                        } else {
                            r.colorClass = 'slot-yellow';
                            r.timeLoggedClass = 'slot-no-check';
                        }
                    } else {
                        if (r.isTimeLogged) {
                            r.colorClass = 'slot-blue';
                            r.timeLoggedClass = 'slot-has-check';
                        } else {
                            r.colorClass = 'slot-blue';
                            r.timeLoggedClass = 'slot-no-check';
                        }

                    }
                    if (r.isInquiry) {
                        r.colorClass = 'slot-orange';
                    }
                    if (r.resourceTypeId === 1) {
                        r.resourceTypeClass = 'F';
                    } else {
                        r.resourceTypeClass = 'K';
                    }
                    var letter = (this.resourceTypes.find(x => x.id == r.resourceTypeId)).displayLetter;
                    r.resourceTypeIconLetter = letter;
                });
            }),
            catchError(this.handleError)
        );
    }

    superClientGetAllocations(userId: string): Observable<BookingInfo[]> {
        const url = (userId === '' || userId === 'all') ? 'allocations/superclient/getallocations' : 'allocations/superclient/getallocations?userId=' + userId;
        return this.http.get<BookingInfo[]>(API_URL + url, httpOptions).pipe(
            tap(resp => {
                resp.forEach((r) => {
                    r.start = new Date(r.sDate);
                    r.end = new Date(r.eDate);

                    if (r.start.toDateString() !== new Date().toDateString() && r.start < new Date()) {
                        if (r.isTimeLogged) {
                            r.colorClass = 'slot-blue';
                            r.timeLoggedClass = 'slot-has-check';
                        } else {
                            r.colorClass = 'slot-yellow';
                            r.timeLoggedClass = 'slot-no-check';
                        }
                    } else {
                        if (r.isTimeLogged) {
                            r.colorClass = 'slot-blue';
                            r.timeLoggedClass = 'slot-has-check';
                        } else {
                            r.colorClass = 'slot-blue';
                            r.timeLoggedClass = 'slot-no-check';
                        }

                    }
                    if (r.isInquiry) {
                        r.colorClass = 'slot-orange';
                    }
                    if (r.resourceTypeId === 1) {
                        r.resourceTypeClass = 'F';
                    } else {
                        r.resourceTypeClass = 'K';
                    }
                    var letter = (this.resourceTypes.find(x => x.id == r.resourceTypeId)).displayName.slice(0, 1);
                    r.resourceTypeIconLetter = letter;
                });
            }),
            catchError(this.handleError)
        );
    }

    requestClientAllocations(allocationModel: AlliocationModel): Observable<ClientBookingInfo> {
        const startDate = allocationModel.startDate;
        const endDate = allocationModel.endDate;

        allocationModel.startDateString = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
        allocationModel.endDateString = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();
        return this.http.post<ClientBookingInfo>(API_URL + 'allocations/client/add', allocationModel, httpOptions)
            .pipe(
                tap(data => {
                    if (data != null) {
                        data.start = new Date(data.sDate);
                        data.end = new Date(data.eDate);
                    }
                }),
                catchError(this.handleError)
            );
    }

    requestAdminAllocations(allocationModel: AlliocationModel): Observable<BookingInfo> {
        const startDate = allocationModel.startDate;
        const endDate = allocationModel.endDate;

        allocationModel.startDateString = startDate.getFullYear() + '-' + ((startDate.getMonth() + 1) > 9 ? '' + (startDate.getMonth() + 1) : '0' + (startDate.getMonth() + 1)) + '-' + startDate.getDate();
        allocationModel.endDateString = endDate.getFullYear() + '-' + ((endDate.getMonth() + 1) > 9 ? '' + (endDate.getMonth() + 1) : '0' + (endDate.getMonth() + 1)) + '-' + endDate.getDate();
        return this.http.post<BookingInfo>(API_URL + 'allocations/admin/add', allocationModel, httpOptions)
            .pipe(
                tap(data => {
                    if (data != null) {
                        data.start = new Date(data.sDate);
                        data.end = new Date(data.eDate);
                    }
                }),
                catchError(this.handleError)
            );
    }

    requestSuperClientAllocations(allocationModel: AlliocationModel): Observable<BookingInfo> {
        const startDate = allocationModel.startDate;
        const endDate = allocationModel.endDate;

        allocationModel.startDateString = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
        allocationModel.endDateString = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();
        return this.http.post<BookingInfo>(API_URL + 'allocations/superclient/add', allocationModel, httpOptions)
            .pipe(
                tap(data => {
                    if (data != null) {
                        data.start = new Date(data.sDate);
                        data.end = new Date(data.eDate);
                    }
                }),
                catchError(this.handleError)
            );
    }

    requestAllocationByEmployee(allocationModel: AlliocationModel): Observable<BookingInfo> {
        // employee self booking
        const startDate = allocationModel.startDate;
        const endDate = allocationModel.endDate;

        allocationModel.startDateString = startDate.getFullYear() + '-' + ((startDate.getMonth() + 1) > 9 ? '' + (startDate.getMonth() + 1) : '0' + (startDate.getMonth() + 1)) + '-' + startDate.getDate();
        allocationModel.endDateString = endDate.getFullYear() + '-' + ((endDate.getMonth() + 1) > 9 ? '' + (endDate.getMonth() + 1) : '0' + (endDate.getMonth() + 1)) + '-' + endDate.getDate();
        return this.http.post<BookingInfo>(API_URL + 'allocations/employeeselfbooking/add', allocationModel, httpOptions)
            .pipe(
                tap(data => {
                    if (data != null) {
                        data.start = new Date(data.sDate);
                        data.end = new Date(data.eDate);
                    }
                }),
                catchError(this.handleError)
            );
    }

    getClientAllocations(userId: string, viewStartDate: Date, viewEndDate: Date): Observable<ClientBookingInfo[]> {

        let url = 'allocations/client'
        let fromDate = viewStartDate.toISOString()
        let toDate = viewEndDate.toISOString()

        let queryParamStr = ''
        queryParamStr += '?fromDate=' + fromDate + '&toDate=' + toDate
        queryParamStr += (userId === '' || userId === 'all') ? '' : '&userId=' + userId

        return this.http.get<ClientBookingInfo[]>(API_URL + url + queryParamStr, httpOptions).pipe(
            tap(resp => {
                resp.forEach((r) => {
                    r.start = new Date(r.sDate);
                    r.end = new Date(r.eDate);
                    if (r.resourceTypeId === 1) {
                        r.description = 'F';
                    } else {
                        r.description = 'K';
                    }

                    if (!r.isInquiry) {
                        r.colorClass = 'slot-blue';
                    } else {
                        r.colorClass = 'slot-orange';
                    }
                    var letter = (this.resourceTypes.find(x => x.id == r.resourceTypeId)).displayName.slice(0, 1);
                    r.resourceTypeIconLetter = letter;


                });
            }),
            catchError(this.handleError)
        );
    }


    checkClientAvailability(data: AvailabilityModel[]): Observable<AvailabilityInfo[]> {
        return this.http.post<AvailabilityInfo[]>(API_URL + 'allocations/client/availability', data, httpOptions).pipe(
            tap(resp => {
                resp.forEach((r) => {
                    r.startTime = new Date(r.start);
                    r.endTime = new Date(r.end);
                });
            }),
            catchError(this.handleError)
        );
    }


    getEmployeeAllocations(userId: string): Observable<BookingInfo[]> {
        const url = (userId === '' || userId === 'all') ? 'allocations/employee' : 'allocations/employee?userId=' + userId;
        return this.http.get<BookingInfo[]>(API_URL + url, httpOptions).pipe(
            tap(resp => {
                resp.forEach((r) => {
                    r.start = new Date(r.sDate);
                    r.end = new Date(r.eDate);
                    if (r.start.toDateString() !== new Date().toDateString() && r.start < new Date()) {
                        if (r.isTimeLogged) {
                            r.colorClass = 'slot-blue';
                            r.timeLoggedClass = 'slot-has-check';
                        } else {
                            r.colorClass = 'slot-yellow';
                            r.timeLoggedClass = 'slot-no-check';
                        }
                    } else {
                        if (r.isTimeLogged) {
                            r.colorClass = 'slot-blue';
                            r.timeLoggedClass = 'slot-has-check';
                        } else {
                            r.colorClass = 'slot-blue';
                            r.timeLoggedClass = 'slot-no-check';
                        }

                    }
                });
            }),
            catchError(this.handleError)
        );
    }

    checkEmployeeAvailability(data: AvailabilityModel[]): Observable<AvailabilityInfo[]> {
        return this.http.post<AvailabilityInfo[]>(API_URL + 'allocations/employee/availability', data, httpOptions).pipe(
            tap(resp => {
                resp.forEach((r) => {
                    r.startTime = new Date(r.start);
                    r.endTime = new Date(r.end);
                });
            }),
            catchError(this.handleError)
        );
    }


    updateAvailability(data: AvailabilityUpdateModel): Observable<AvailabilityInfo[]> {
        return this.http.post<AvailabilityInfo[]>(API_URL + 'allocations/employee/availability/update', data, httpOptions).pipe(
            tap(resp => {
                resp.forEach((r) => {
                    r.startTime = new Date(r.start);
                    r.endTime = new Date(r.end);
                });
            }),
            catchError(this.handleError)
        );
    }

    updateComment(data: any): Observable<any> {
        return this.http.post<any[]>(API_URL + 'allocations/employee/booking/addcomment', data, httpOptions).pipe(
            tap(resp => {
                resp.forEach((r) => {
                    r.startTime = new Date(r.start);
                    r.endTime = new Date(r.end);
                });
            }),
            catchError(this.handleError)
        );
    }

    updateVacationAndLeave(data: any): Observable<any> {
        return this.http.post<any[]>(API_URL + 'allocations/employee/vacationAndLeave/update', data, httpOptions).pipe(
            tap(resp => {
                resp.forEach((r) => {
                    r.startTime = new Date(r.start);
                    r.endTime = new Date(r.end);
                });
            }),
            catchError(this.handleError)
        );
    }

    updateSingleAvailability(date: Date, userId: string): Observable<boolean> {
        const dateString = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        const url = userId !== '' ? ('allocations/availability/date?date=' + dateString + '&userId=' + userId)
            : ('allocations/availability/date?date=' + dateString);
        return this.http.post<boolean>(API_URL + url, httpOptions).pipe(
            tap(resp => {
            }),
            catchError(this.handleError)
        );
    }

    updateTimeLogs(data: TimeLogModel): Observable<boolean> {
        if (!data.distance) {
            data.distance = "0";
        }
        if (!data.travelTime) {
            data.travelTime = "0";
        }
        return this.http.post<boolean>(API_URL + 'allocations/employee/timelog/update', data, httpOptions).pipe(
            tap(resp => {
            }),
            catchError(this.handleError)
        );
    }

    checkAllAvailability(data: AvailabilityModel[], role?: string): Observable<AvailabilityInfo[]> {
        let endPoint = 'allocations/availability'
        if (role == 'Employee') {
            endPoint = 'allocations/employee/availability'
        }
        return this.http.post<AvailabilityInfo[]>(API_URL + endPoint, data, httpOptions).pipe(
            tap(resp => {
                resp.forEach((r) => {
                    r.startTime = new Date(r.start);
                    r.endTime = new Date(r.end);
                });
            }),
            catchError(this.handleError)
        );
    }
    checkSuperClientAllAvailability(data: AvailabilityModel[]): Observable<AvailabilityInfo[]> {
        return this.http.post<AvailabilityInfo[]>(API_URL + 'allocations/superclient/availability', data, httpOptions).pipe(
            tap(resp => {
                resp.forEach((r) => {
                    r.startTime = new Date(r.start);
                    r.endTime = new Date(r.end);
                });
            }),
            catchError(this.handleError)
        );
    }

    getAllocationSwapUserList(allocationId: number): Observable<IUserInfo[]> {
        return this.http.get<IUserInfo[]>(API_URL + 'allocations/swap/employees?allocationId=' + allocationId, httpOptions).pipe(
            tap(resp => {

                resp.forEach((r) => {
                });
            }),
            catchError(this.handleError)
        );
    }

    swapAllocationEmployee(data: SwapEmployeeModel): Observable<boolean> {
        return this.http.post<boolean>(API_URL + 'allocations/swap/employees/update', data, httpOptions).pipe(
            tap(resp => {

            }),
            catchError(this.handleError)
        );
    }

    getSameDaySwapEmployeesList(allocationData): Observable<any> {
        return this.http.post<boolean>(API_URL + 'allocations/getbookingsforswap', allocationData, httpOptions).pipe(
            tap(resp => {

            }),
            catchError(this.handleError)
        );
    }

    onSameDaySwapEmployees(allocationData): Observable<any> {
        return this.http.post<boolean>(API_URL + 'allocations/swapemployeewithbooking', allocationData, httpOptions).pipe(
            tap(resp => {

            }),
            catchError(this.handleError)
        );
    }


    assignInquiryEmployee(allocationId: number): Observable<boolean> {
        return this.http.post<boolean>(API_URL + 'allocations/swap/employees/updatebyemployee?allocationId=' + allocationId, httpOptions).pipe(
            tap(resp => {
            }),
            catchError(this.handleError)
        );
    }


    getEmployeeInquiryList(userId: string): Observable<BookingInfo[]> {

        let url = 'allocations/employee/inquiries'
        // let fromDate = viewStartDate.toISOString()
        // let toDate = viewEndDate.toISOString()
        let queryParamStr = ''

        // queryParamStr +='?fromDate='+fromDate+'&toDate='+toDate
        queryParamStr += (userId === '' || userId === 'all') ? '' : '?clientId=' + userId

        return this.http.get<BookingInfo[]>(API_URL + url + queryParamStr, httpOptions).pipe(
            tap(resp => {
                resp.forEach((r) => {
                    r.start = new Date(r.sDate);
                    r.end = new Date(r.eDate);
                    r.colorClass = 'slot-orange';
                    r.timeLoggedClass = 'slot-no-check';
                });
            }),
            catchError(this.handleError)
        );
    }

    getAdminInquiryList(startDate: string, userId: string, userType: string, role: string): Observable<BookingInfo[]> {

        var url
        // let fromDate = viewStartDate.toISOString()
        // let toDate = viewEndDate.toISOString()

        if (role == 'SuperClient') {
            url = 'allocations/superclient/inquiries'
        } else if (role == 'Admin') {
            url = 'allocations/inquiries'
        }
        let queryParamStr = ''
        queryParamStr += '?startDate=' + (startDate ? startDate : new Date().toISOString())
        // queryParamStr +='&fromDate='+fromDate+'&toDate='+toDate

        if (userType && userId) {
            if (userType == 'Employee' && userId && userId != 'all') {
                queryParamStr += '&userId=' + userId
            }
            if (userType == 'Client' && userId && userId != 'all') {
                queryParamStr += '&clientId=' + userId
            }
        }
        return this.http.get<BookingInfo[]>(API_URL + url + queryParamStr, httpOptions).pipe(
            tap(resp => {
                resp.forEach((r) => {
                    r.start = new Date(r.sDate);
                    r.end = new Date(r.eDate);
                    r.colorClass = 'slot-orange';
                    r.timeLoggedClass = 'slot-no-check';

                    if (r.resourceTypeId === 1) {
                        r.resourceTypeClass = 'F';
                    } else {
                        r.resourceTypeClass = 'K';
                    }
                    var letter = (this.resourceTypes.find(x => x.id == r.resourceTypeId)).displayName.slice(0, 1);

                    r.resourceTypeIconLetter = letter;
                });
            }),
            catchError(this.handleError)
        );
    }

    removeAllocation(allocationId: number): Observable<boolean> {
        return this.http.post<boolean>(API_URL + 'allocations/remove?allocationId=' + allocationId, httpOptions).pipe(
            tap(resp => {

            }),
            catchError(this.handleError)
        );
    }

    superClientRemoveAllocation(allocationId: number): Observable<boolean> {
        return this.http.post<boolean>(API_URL + 'allocations/superclient/remove?allocationId=' + allocationId, httpOptions).pipe(
            tap(resp => {

            }),
            catchError(this.handleError)
        );
    }

    removeClientAllocation(allocationId: number): Observable<boolean> {
        return this.http.post<boolean>(API_URL + 'allocations/client/remove?allocationId=' + allocationId, httpOptions).pipe(
            tap(resp => {

            }),
            catchError(this.handleError)
        );
    }
    checkSingleAvailability(userId: string, sDate: string, role: string): Observable<AvailabilityInfo> {
        let endPoint = ''
        if (role == 'Employee') {
            endPoint = 'allocations/employee/checksingleavailability?date=' + sDate
        } else {
            endPoint = 'allocations/checksingleavailability?userId=' + userId + '&date=' + sDate
        }
        return this.http.get<AvailabilityInfo>(API_URL + endPoint, httpOptions).pipe(
            tap(resp => {
            }),
            catchError(this.handleError)
        );
    }

    // checkEmployeeSingleAvailability(sDate: string): Observable<AvailabilityInfo> {
    //     return this.http.get<AvailabilityInfo>(API_URL + 'allocations/employee/checksingleavailability?date='
    //         + sDate, httpOptions).pipe(
    //             tap(resp => {
    //             }),
    //             catchError(this.handleError)
    //         );
    // }

    updatePartialAvailability(data: PartialAvailabilityModel, role: string): Observable<boolean> {
        let endPoint = ''
        if (role == 'Employee') {
            endPoint = 'allocations/employee/partial_availability/update'
        } else {
            endPoint = 'allocations/partial_availability/update'
        }
        return this.http.post<boolean>(API_URL + endPoint, data, httpOptions).pipe(
            tap(resp => {
            }),
            catchError(this.handleError)
        );
    }

    // updateEmployeePartialAvailability(data: PartialAvailabilityModel): Observable<boolean> {
    //     return this.http.post<boolean>(API_URL + 'allocations/employee/partial_availability/update', data, httpOptions).pipe(
    //         tap(resp => {
    //         }),
    //         catchError(this.handleError)
    //     );
    // }

    getPartialAvailabilityList(allocationModel: AlliocationModel): Observable<PartialAvailabilityInfo[]> {
        const startDate = allocationModel.startDate;
        const endDate = allocationModel.endDate;

        allocationModel.startDateString = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
        allocationModel.endDateString = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();
        return this.http.post<PartialAvailabilityInfo[]>(API_URL + 'allocations/partial_availability/list', allocationModel, httpOptions)
            .pipe(
                tap(data => {
                }),
                catchError(this.handleError)
            );
    }

    getEmployeeBookingsCount(userId: string, selectedMonth: Date): Observable<IUserInfo> {
        if (selectedMonth === null) {
            selectedMonth = new Date();
        }
        if (!userId || userId === 'all') {
            userId = null;
        }

        const dateString = selectedMonth.getFullYear() + '-' + (selectedMonth.getMonth() + 1) + '-' + selectedMonth.getDate();
        const urlPart = userId !== null ? 'allocations/employee/bookingcount?selectedMonth=' + dateString + '&userId=' + userId
            : 'allocations/employee/bookingcount?selectedMonth=' + dateString;

        return this.http.get<IUserInfo>(API_URL + urlPart, httpOptions)
            .pipe(
                tap(data => {
                }),
                catchError(this.handleError)
            );
    }

    getSwedishHolidays() {
        var json = null;
        $.ajax({
            'async': false,
            'global': false,
            'url': "assets/swedisholidays.json",
            'dataType': "json",
            'success': function (data) {
                json = data;
            }
        });
        return JSON.parse(JSON.stringify(json));
    }

    isHoliday(date) {
        this.swedishHolidays = SwedishHolidays;
        var slotDateJsonFormat = this.getJsonDateFormat(date);

        var holidayArr = this.swedishHolidays[slotDateJsonFormat]
        if (holidayArr) {
            var holidayObj = holidayArr.find(e => e.date == date.getDate())
            if (holidayObj) {
                return true
            }
        }
        return false
    }

    getJsonDateFormat(date: Date): string {
        return date.getFullYear() + '' + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1))
    }

    getWeekNumber(d) {
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        return [d.getUTCFullYear(), weekNo];
    }

    getAvailableEmployees(allocationModel: AlliocationModel): Observable<any> {
        return this.http.post(API_URL + 'allocations/matchedemployees', allocationModel, httpOptions)
            .pipe(
                tap(data => {
                }),
                catchError(this.handleError)
            );
    }

    getClientCommonTimes(userId): Observable<any> {

        let queryStr = userId ? '?clientId=' + userId : ''
        return this.http.get(API_URL + 'allocations/commonbookingdata' + queryStr, httpOptions)
            .pipe(
                tap(data => {

                }),
                catchError(this.handleError)
            );
    }

    updateAllocationTime(timeObj): Observable<any> {
        return this.http.post(API_URL + 'allocations/updateallocationtime', timeObj, httpOptions)
            .pipe(
                tap(data => {
                }),
                catchError(this.handleError)
            );
    }

    updateAllocationMissingTimeLogs(holidayArr): Observable<any> {
        return this.http.post(API_URL + '/reports/updateallmissingtimelogs', holidayArr, httpOptions)
            .pipe(
                tap(data => {
                }),
                catchError(this.handleError)
            );
    }

    getAllocationHistory(allocationId: number): Observable<any> {
        return this.http.get(API_URL + 'allocations/viewhistory?allocationId=' + allocationId, httpOptions)
            .pipe(
                tap(data => {
                }),
                catchError(this.handleError)
            );
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occured: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        return throwError(errorMessage);
    }

    getConfigData() {
        return this.http.get<any>(API_URL + 'user/resourcetypes').pipe(
            tap(resp => {
            }),
            catchError(this.handleError)
        );
    }

    emmitOnClickMakeBooking() {
        this.onClickMakeBooking.next(true)
    }

    emmitOnClickMakeInquiry() {
        this.onClickMakeInquiry.next(true)
    }


    emmitOnClickMakeLongBooking(value: any) {
        this.onClickMakeLongBooking.next(value)
    }

    emmitOnResourceTypeChange(data) {
        this.onResourceTypeChange.next(data)
    }
    emmitSelectedMonth(data) {
        this.selectedMonth.next(data)
    }
    emmitUserListAction(data) {
        this.userListAction.next(data)
    }

    emmitLongBookingDetails(data: any) {
        this.onSelectLongBooking.next(data)
    }

    emmitOnClickAvailabilityUpdate() {
        this.OnClickAvailabilityUpdate.next(true)
    }

}
