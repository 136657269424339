import { LongBookingShiftDay } from "./long-booking-shift-day";

export class LongBookingShift
{
    Id:number;
    Day:string;
    From:Date;
    To:Date;
    LongBookingId: number;
    ShiftNumber: number;
    WeekNumber: number;
    ShiftName: string;
    ShiftTotalTime: number;
    ShiftDays :LongBookingShiftDay[]
}