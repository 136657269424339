import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigComponent } from './config.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { PharmacySystemComponent } from './pharmacy-system/pharmacy-system.component';
import { LegitimationsComponent } from './legitimations/legitimations.component';
import { ExperienceAreaComponent } from './experience-area/experience-area.component';
import { TimePeriodsComponent } from './time-periods/time-periods.component'
import { Select2Module, Select2Data, Select2Option } from "ng-select2-component";
//import { Select2Module } from 'ng2-select2';
import { NgSelectModule } from '@ng-select/ng-select';
import { BookingUnitComponent } from './booking-unit/booking-unit.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ImageCropperModule } from 'ngx-image-cropper';
//import { Select2Module } from 'ng2-select2';


@NgModule({
  declarations: [ConfigComponent, FileUploadComponent, PharmacySystemComponent, LegitimationsComponent, ExperienceAreaComponent, TimePeriodsComponent, BookingUnitComponent],
  imports: [
    CommonModule,
    ColorPickerModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    ImageCropperModule,
    Select2Module,
    NgSelectModule
  ]
})
export class ConfigModule{}
