import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UserService } from 'src/app/user/user.service';

@Component({
  selector: 'wfm-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  code: string;
  userId: string;
  resetPasswordForm: FormGroup;
  isResetSuccess: Boolean;
  isResetFailed: Boolean;
  logoImageUrl : string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService
  ) { }

  ngOnInit() {

    this.resetPasswordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confpassword: ['', [Validators.required, Validators.minLength(6)]],
      code: [],
      userId: []
    }, { validators: this.checkPasswords })

    this.route.queryParams.subscribe(params => {
      this.code = params.code;
      this.userId = params.userId;
    })
    //this.logoImageUrl ="https://teamplanstorage.blob.core.windows.net/24transport/logo.png"// localStorage.getItem('logoImageUrl');
    this.logoImageUrl = localStorage.getItem('logoImageUrl');
  }

  public get password() {
    return this.resetPasswordForm.get('password');
  }
  public get confpassword() {
    return this.resetPasswordForm.get('confpassword');
  }
  checkPasswords(c: AbstractControl) {
    let password = c.get('password').value;
    let confpassword = c.get('confpassword').value;
    return password === confpassword ? null : { notSame: true }
  }

  onSubmit() {
    this.resetPasswordForm.updateValueAndValidity();
    if (this.resetPasswordForm.valid) {
      const formData = new FormData();
      formData.append('userId', this.userId)
      formData.append('code', this.code)
      formData.append('pwd', this.resetPasswordForm.get('password').value)
      this.userService.submitResetPassword(formData).subscribe((status) => {
        if (status) {
          this.isResetFailed = false
          this.isResetSuccess = true;
          setTimeout(() => {
            this.router.navigate(['/login'])
          }, 2000);
        } else{
          this.isResetFailed = true
        }
      })
    }
  }

}
