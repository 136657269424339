
import { Component, OnInit, ElementRef, ViewChildren, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControlName, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { ConfigService } from '../../shared/services/config.service';
import { UserService } from '../../user/user.service';
import * as internal from 'assert';
//import { Select2OptionData } from 'ng2-select2';

@Component({
  selector: 'wfm-time-periods',
  templateUrl: './time-periods.component.html',
  styleUrls: ['./time-periods.component.css']
})
export class TimePeriodsComponent implements OnInit {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  public overTimeRuleForm : FormGroup;
  isSubmitInvalid : boolean = false;
  notificationModal: any;
  timeSlots = [];

   OTTimeRulesArray = this.fbp.array([]);

  //workingDay : any[] = [];
  workingDays : any[] = [];
  //workingDays2 : any[] = [];
  experienceAreas: any[] = [];

  tempWorkingDay : any[] =[];
  tempWorkingDays2 : any[] = [];
  tempExperienceAreas: any[] = [];

  listOfWorkingDays: any[]  = [];
  listOfWorkingDays2:  any[] = [];
  listOfExperienceAreas: any[] = [];

  overTimeRulesArr : any[]= []; //Temp array for storing data
  tempDaysDictionary : { key : number, value : number[] } [] = [];
  overTimePeriods : any[] = [];
  useRegularOverTimeRulesForHolidays = false;
  enableBeforeHolidayoverTimeRules = false;
  disableAddBeforeHolidayButton = false;

  selectedCitiesIds : any[] =[];
  isCitiesControlVisible = true;
  cities: any[] = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys (Disabled)', disabled: true },
    { id: 4, name: 'Pabradė' },
   ];


  constructor(private fbp : FormBuilder, private configService : ConfigService, private userService: UserService) {  

    this.timeSlots = [
      { id: '1', name: '00:00'},
      { id: '2', name: '00:15'},
      { id: '3', name: '00:30'},
      { id: '4', name: '00:45'},
      { id: '5', name: '01:00'},
      { id: '6', name: '01:15'},
      { id: '7', name: '01:30'},
      { id: '8', name: '01:45'},
      { id: '9', name: '02:00'},
      { id: '10', name: '02:15'},
      { id: '11', name: '02:30'},
      { id: '12', name: '02:45'},
      { id: '13', name: '03:00'},
      { id: '14', name: '03:15'},
      { id: '15', name: '03:30'},
      { id: '16', name: '03:45'},
      { id: '17', name: '04:00'},
      { id: '18', name: '04:15'},
      { id: '19', name: '04:30'},
      { id: '20', name: '04:45'},
      { id: '21', name: '05:00'},
      { id: '22', name: '05:15'},
      { id: '23', name: '05:30'},
      { id: '24', name: '05:45'},
      { id: '25', name: '06:00'},
      { id: '26', name: '06:15'},
      { id: '27', name: '06:30'},
      { id: '28', name: '06:45'},
      { id: '29', name: '07:00'},
      { id: '30', name: '07:15'},
      { id: '31', name: '07:30'},
      { id: '32', name: '07:45'},
      { id: '33', name: '08:00'},
      { id: '34', name: '08:15'},
      { id: '35', name: '08:30'},
      { id: '36', name: '08:45'},
      { id: '37', name: '09:00'},
      { id: '38', name: '09:15'},
      { id: '39', name: '09:30'},
      { id: '40', name: '09:45'},
      { id: '41', name: '10:00'},
      { id: '42', name: '10:15'},
      { id: '43', name: '10:30'},
      { id: '44', name: '10:45'},
      { id: '45', name: '11:00'},
      { id: '46', name: '11:15'},
      { id: '47', name: '11:30'},
      { id: '48', name: '11:45'},
      { id: '49', name: '12:00'},
      { id: '50', name: '12:15'},
      { id: '51', name: '12:30'},
      { id: '52', name: '12:45'},
      { id: '53', name: '13:00'},
      { id: '54', name: '13:15'},
      { id: '55', name: '13:30'},
      { id: '56', name: '13:45'},
      { id: '57', name: '14:00'},
      { id: '58', name: '14:15'},
      { id: '59', name: '14:30'},
      { id: '60', name: '14:45'},
      { id: '61', name: '15:00'},
      { id: '62', name: '15:15'},
      { id: '63', name: '15:30'},
      { id: '64', name: '15:45'},
      { id: '65', name: '16:00'},
      { id: '66', name: '16:15'},
      { id: '67', name: '16:30'},
      { id: '68', name: '16:45'},
      { id: '69', name: '17:00'},
      { id: '70', name: '17:15'},
      { id: '71', name: '17:30'},
      { id: '72', name: '17:45'},
      { id: '73', name: '18:00'},
      { id: '74', name: '18:15'},
      { id: '75', name: '18:30'},
      { id: '76', name: '18:45'},
      { id: '77', name: '19:00'},
      { id: '78', name: '19:15'},
      { id: '79', name: '19:30'},
      { id: '80', name: '19:45'},
      { id: '81', name: '20:00'},
      { id: '82', name: '20:15'},
      { id: '83', name: '20:30'},
      { id: '84', name: '20:45'},
      { id: '85', name: '21:00'},
      { id: '86', name: '21:15'},
      { id: '87', name: '21:30'},
      { id: '88', name: '21:45'},
      { id: '89', name: '22:00'},
      { id: '90', name: '22:15'},
      { id: '91', name: '22:30'},
      { id: '92', name: '22:45'},
      { id: '93', name: '23:00'},
      { id: '94', name: '23:15'},
      { id: '95', name: '23:30'},
      { id: '96', name: '23:45'},
      { id: '97', name: '24:00'}
    ];

    this.workingDays = [
      { id: 1, value: 1, label : 'Sunday', name : 'Sunday'},
      { id: 2, value: 2, label: 'Monday' , name : 'Monday'},
      { id: 3, value: 3, label: 'Tuesday' , name : 'Tuesday'},
      { id: 4, value: 4, label: 'Wednesday' , name : 'Wednesday'},
      { id: 5, value: 5, label: 'Thursday' , name : 'Thursday'},
      { id: 6, value: 6, label: 'Friday' , name : 'Friday'},
      { id: 7, value: 7, label: 'Saturday' , name : 'Saturday'},
      { id: 8, value: 8, label: 'Public Holiday' , name : 'Public Holiday'},
      { id: 9, value: 9, label: 'Day Before Holiday' , name : 'Day Before Holiday'},
      { id: 10, value: 10, label: 'Day After Holiday' , name : 'Day After Holiday'}
    ];

    // this.workingDays2 = [
    //   { id: 1, name : 'Sunday'},
    //   { id: 2, name : 'Monday'},
    //   { id: 3, name : 'Tuesday'},
    //   { id: 4, name : 'Wednesday'},
    //   { id: 5 , name : 'Thursday'},
    //   { id: 6, name : 'Friday'},
    //   { id: 7, name : 'Saturday'},
    //   { id: 8, name : 'Public Holiday'},
    //   { id: 9, name : 'Day Before Holiday'},
    //   { id: 10, name : 'Day After Holiday'}
    // ];
  }

  ngOnInit() {
    this.overTimeRuleForm = this.fbp.group({
      overTimeRules: this.fbp.array([this.createTimePeriod()]),
      //experienceArea: [],
      selectedCitiesIds : []
    });

    this.selectedCitiesIds = [
      { id: 1, name: 'Vilnius' },
      { id: 2, name: 'Kaunas' }
    ];

    this.notificationModal = {
      header: '',
      description: '',
      type: ''
    };

    this.userService.getInnitialMasterData().subscribe((data) => {
      //console.log("getInnitialMasterData");
      //console.log(data);
      //console.log( data.experienceAreas);
      this.experienceAreas = data.experienceAreas

      for (let [key, value] of Object.entries(this.experienceAreas)) {
        // value['value'] = value.id;
        value['label'] = value.name;
        value['name'] = value.name;
      }
    })


    this.getOverTimeRules();
  
  }

  createTimePeriod(id=0, timePeriodId = null, startTime = null, endTime= null, percentage = null, workingDay= null, ruleName = null)
  {
    return this.fbp.group({
      id:[id==null ? 0 : id],
      timePeriodId : [timePeriodId == null ? 0 : timePeriodId],
      startTime: [startTime ? startTime : '', Validators.required],
      endTime: [endTime ? endTime : '', Validators.required],
      percentage :[percentage, [Validators.required, Validators.maxLength(3)]],
      workingDay : [ workingDay , Validators.required],  
      ruleName :[ruleName ? ruleName :'',] 
    })
  }

  createContactsCtrl(days)
  {
    const formArray = [];
    if(days)
    {
      for(let itemNumber of days)
      {
        formArray.push(
          this.fbp.group({
            itemNumber
          })
        )
      }
    }
    
    console.log(formArray);

    return formArray;
  }

  get overTimeRules() : FormArray {
    return this.overTimeRuleForm.get('overTimeRules') as FormArray;
  }

  myFormArray() {
    return this.overTimeRuleForm.get('overTimeRules') as FormArray;
  }

  addTimePeriod(timeperiod) {
    this.overTimeRules.push(this.createTimePeriod(timeperiod));
  }

  removeTimePeriod(index) {
    this.overTimeRules.removeAt(index);
  }

  validateFormFields() {
    //console.log("Validating Controls");
    const overTimeRulesArray = this.overTimeRuleForm.get('overTimeRules') as FormArray;
    //console.log(overTimeRulesArray);
   
    Object.keys(overTimeRulesArray['controls']).forEach(element => {
      const control = overTimeRulesArray.get(element);
      //console.log(control);
      Object.keys(control['controls']).forEach(field => {
        const controlItem = control.get(field);
         //console.log(controlItem);
        if (controlItem instanceof FormControl) {
          //console.log("MarkAsTouched");
          controlItem.markAsTouched({ onlySelf: true });
          controlItem.markAsDirty();
          //console.log("MarkAsDirty");
        }
      });
    });
  }


  onStartTimeChange(time, value, rowIndex){
    let startTime = time.get('startTime').value;
    let endTime = time.get('endTime').value;
    let items = time.get('workingDay').value;
    let validTimePeriod = true;

    if(endTime != '' && startTime !='' && startTime >=  endTime )
      {
          //validTimePeriod = false;
          alert('Start Time is grater than end Time');
          time.get('startTime').setValue('');
          return;
      }

    const overTimeRulesArray = this.overTimeRuleForm.get('overTimeRules') as FormArray;
    let updatedValues = [];

    Object.keys(overTimeRulesArray['controls']).forEach((element, index) => {
      const control = overTimeRulesArray.get(element);
        if(index !=  rowIndex)
        {

          let sTime = control.value.startTime;
          let eTime = control.value.endTime;
          let days = control.value.workingDay;

          let isExistingItems = items.some( r => days.includes(r));
          let existingItems = items.filter(f => days.includes(f));

          //start time 
          if(startTime >= sTime && startTime < eTime &&  isExistingItems)
          {
            validTimePeriod = false;

            updatedValues = this.removeExistingItems(isExistingItems, items,days, time );
            //return;
          } 

          if(sTime >= startTime && eTime <= endTime  &&  isExistingItems )
          {
             validTimePeriod = false;
             updatedValues = this.removeExistingItems(isExistingItems, items, days, time );

           // return;
          } 
        }         
    });

    if(!validTimePeriod)
    {
      alert("Invalid Time Entry : duplicated or overritten time periods occurred.");
      time.get('workingDay').setValue(updatedValues) ;
      if(updatedValues.length == 0)
      {
        //if(isStarting)
        time.get('startTime').setValue('');
        //else
        time.get('endTime').setValue('');
      }
    }
  }

  onEndTimeChange(time, value, rowIndex)
  {
    let startTime = time.get('startTime').value;
    let endTime = time.get('endTime').value;
    let items = time.get('workingDay').value;
    let validTimePeriod = true;

    // this should come to end time
    if( endTime !='' && endTime <= startTime )
      {
         // validTimePeriod = false;
          alert('End Time is lesser than start Time');
          time.get('endTime').setValue('');
          return;
      }
      const overTimeRulesArray = this.overTimeRuleForm.get('overTimeRules') as FormArray;
      let updatedValues = [];

      Object.keys(overTimeRulesArray['controls']).forEach((element, index) => {
        const control = overTimeRulesArray.get(element);
       // console.log("Checking row index : " + rowIndex);
       // console.log("Current row inedx : " + index);
  
      // if(index < overTimeRulesArray['controls'].length-1)
        //  {
            if( index !=  rowIndex)
            {
              const ctrl = overTimeRulesArray.get(element);
              let sTime = control.value.startTime;
              let eTime = control.value.endTime;
              let days = control.value.workingDay;
      
             // console.log("current row sTime : " +  sTime);
             // console.log("current row eTime : " +  eTime);
             // console.log("current row days : " +  days);
             // console.log("checking row days : " +  items);
              
              let isExistingItems = items.some( r => days.includes(r));
              let existingItems = items.filter(f => days.includes(f));

             // console.log(isExistingItems);
             // console.log(existingItems);

             // console.log("Has Existing days :" + isExistingItems);
             // console.log("Existing Days : "+ existingItems);

              // end time
              if(endTime != null && endTime != '' && endTime > sTime && endTime <= eTime &&  isExistingItems)
              {
                validTimePeriod = false;
                updatedValues = this.removeExistingItems(isExistingItems, items,days, time );
                //return;
              }

              if(startTime != null && startTime != '' && sTime >= startTime && eTime <= endTime &&  isExistingItems)
              {
                validTimePeriod = false;
                updatedValues = this.removeExistingItems(isExistingItems, items,days, time );
                //return;
              } 
          }
        //}          
      });

      if(!validTimePeriod)
      {
        alert("Invalid Time Entry : duplicated or overritten time periods occurred.");
        time.get('workingDay').setValue(updatedValues) ;
        if(updatedValues.length == 0)
        {
          //if(isStarting)
          time.get('startTime').setValue('');
          //else
          time.get('endTime').setValue('');
        }
      }
  }

  removeExistingItems(isExists, toFilter, toRemove, control)
  {
    if(isExists)
    {
     // console.log("Removing Days : " + toRemove);
      toFilter = toFilter.filter( function( el ) {
        return !toRemove.includes( el );
      } );

     // console.log("New Days : ", + toFilter);

      return toFilter;
      // control.get('workingDay').setValue(toFilter) ;
      // if(toFilter.length == 0)
      // {
      //   //if(isStarting)
      //     control.get('startTime').setValue('');
      //   //else
      //     control.get('endTime').setValue('');
      // }
    }
  }

  onTagChanged(time, data, rowIndex): void {
    let validTimePeriod = true;
   // console.log("selected values : "+ data);
    //console.log(time);
    let startTime = time.get('startTime').value;
    let endTime = time.get('endTime').value;
    let items = time.get('workingDay').value;
    //this.selectedData = data.value;
    //console.log(time.get('workingDay'));
    //console.log(data.length);
    if(startTime != '' && endTime != '')
    {
      if(data.length != 0)
      {
        let selectedValue = [];
        selectedValue.push(data[data.length-1]); //get the last value selected
        const overTimeRulesArray = this.overTimeRuleForm.get('overTimeRules') as FormArray;
        let updatedValues = [];

        Object.keys(overTimeRulesArray['controls']).forEach((element, index) => {
          const control = overTimeRulesArray.get(element);
          //console.log("Checking row index : " + rowIndex);
          //console.log("Current row inedx : " + index);
    
              if( index !=  rowIndex)
              {
                const ctrl = overTimeRulesArray.get(element);
                let sTime = control.value.startTime;
                let eTime = control.value.endTime;
                let days = control.value.workingDay;
          
               // console.log("current row sTime : " +  sTime);
               // console.log("current row eTime : " +  eTime);
               // console.log("current row days : " +  days);
               // console.log("checking row days : " +  items);
                let isExistingItems = selectedValue.some( r => days.includes(r));
                let existingItems = selectedValue.filter(f => days.includes(f));
      
               // console.log(isExistingItems);
               // console.log(existingItems);

               // console.log("Has Existing days :" + isExistingItems);
                //console.log("Existing Days : "+ existingItems);

                //start time 
                if(startTime >= sTime && startTime < eTime &&  isExistingItems)
                {
                 // console.log("Exists inbetween same timeperiod");
                  validTimePeriod = false;
                  updatedValues =  this.removeExistingItems(isExistingItems, items,days, time );
                  //return;
                } 

                if(sTime >= startTime && eTime <= endTime  &&  isExistingItems )
                {
                 // console.log("Exists between overriding time period");
                  validTimePeriod = false;
                  updatedValues = this.removeExistingItems(isExistingItems, items,days, time );
                // return;
                } 
            }      
        });
        
        if(!validTimePeriod)
        {
          alert("Invalid Time Entry : duplicated or overritten time periods occurred.");
          time.get('workingDay').setValue(updatedValues) ;
          if(updatedValues.length == 0)
          {
            //if(isStarting)
            time.get('startTime').setValue('');
            //else
            time.get('endTime').setValue('');
          }
        }
      }
    } 
  }

  save() {
    this.validateFormFields();
    if (this.overTimeRuleForm.valid) {
      //console.log("form valid");
      //if(this.overTimeRuleForm.dirty)
      //{
        this.isSubmitInvalid = false;
        //console.log("form dirty");
        this.overTimeRuleForm.value.overTimeRules = this.overTimeRuleForm.value.overTimeRules ? this.overTimeRuleForm.value.overTimeRules : [];
        //console.log(this.overTimeRuleForm.value);
        this.configService.saveOverTimeRules(this.overTimeRuleForm.value).subscribe(
          //() => this.onSaveComplete('update', true, '0'),
          (res) => {
           //console.log(res);
          this.setNotificationData('update',res);
          this.getOverTimeRules();
        });
     // }  
    }
    else{
     //console.log(this.overTimeRuleForm) ;
     // console.log("form invalid");
      this.isSubmitInvalid = true
    }   
    //console.log(this.overTimeRuleForm) ;
  }

  getOverTimeRules() {
    this.tempDaysDictionary = [];

    this.configService.getOverTimeRules().subscribe((res) => {
       console.log("Overtime rules");
       console.log(res);
       this.overTimeRulesArr = res.overTimeRules;

       const overTimeRulesArray = this.fbp.array([]);

        res.overTimeRules.forEach((item, index) => {
      
          this.tempWorkingDay =[];
          this.tempWorkingDays2 = [];

         let tempArr : number[] = [];
         for(var i = 0; i < item.workingDay.length; i++)
         {
            tempArr.push(item.workingDay[i]); 
         }

        this.tempWorkingDay =item.workingDay;
        this.tempWorkingDays2 = item.select2WorkingDay;

        this.listOfWorkingDays[index]  = [...this.tempWorkingDay];
        this.listOfWorkingDays2[index]  = [...this.tempWorkingDays2];
      
        var objt = this.createTimePeriod(item.id, item.timePeriodId, item.startTime, item.endTime, item.percentage,  this.listOfWorkingDays[index], item.ruleName) ;// item.workingDay or temArr add more properties
         // console.log(objt)
         overTimeRulesArray.push(objt);

       });

       this.overTimeRuleForm.setControl('overTimeRules', overTimeRulesArray);
     });
  }

  get rulesFormArrays() {
    return (<FormArray>this.overTimeRuleForm.get('overTimeRules'));
  }

  resetField(fieldName) {
    //this.rulesFormArrays.controls.forEach(group => group.get(fieldName).reset());
    this.rulesFormArrays.controls.forEach(group => group.reset());
  }


  setNotificationData(flow, status): void {
    if (flow === 'update') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Uppdatering av regler över tiden lyckades!';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Uppdatering av tidsregler misslyckades! Var god försök igen.';
        this.notificationModal.type = 'danger';
      }
      console.log(this.notificationModal);
    }
    ($('#timePeriodsNotificationModal') as any).modal('show');

    setTimeout(() => {
      ($('#timePeriodsNotificationModal') as any).modal('hide');
    }, 1000);
  }
}
