import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AdminReportComponent } from './admin-report.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EmployeeReportComponent } from './employee-report.component';
import { ClientReportComponent } from './client-report.component';
import { SuperclientReportComponent } from './superclient-report/superclient-report.component';



@NgModule({
  declarations: [AdminReportComponent, EmployeeReportComponent, ClientReportComponent, SuperclientReportComponent],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class ReportModule { }
