import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpHandler, HttpHeaderResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { of, Observable, throwError, Subject, BehaviorSubject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ApiActionName } from '../constants/enums/action-names';

const API_URL = environment.apiEndpoint;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  }),
};

@Injectable({
  providedIn: 'root'
})

export class ConfigService {


  GlobalConfigurationObject: any; // Need to have this type of object for all initial config values to be loaded at first

  constructor(private http: HttpClient) { }

  savePharmacySystems(formData: FormData) {
    return this.http.post<boolean>(API_URL + 'config/pharmacysystems/update', formData).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  getPharmacySystems() {
    return this.http.get<any>(API_URL + 'config/pharmacysystems').pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  saveLegitimations(formData: FormData) {
    return this.http.post<boolean>(API_URL + 'config/legitimations/update', formData).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }
  getLegitimations() {
    return this.http.get<any>(API_URL + 'config/legitimations').pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  saveExperienceAreas(formData: FormData) {
    return this.http.post<boolean>(API_URL + 'config/experienceareas/update', formData).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  getExperienceAreas() {
    return this.http.get<any>(API_URL + 'config/experienceareas').pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  saveOverTimeRules(formData: FormData) {
    return this.http.post<boolean>(API_URL + 'config/timeperiods/update', formData).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  getOverTimeRules() {
    return this.http.get<any>(API_URL + 'config/timeperiods').pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    )
  }

  getConfigData() {
    return this.http.get<any>(API_URL + 'config/configurationData').pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  getLogoUrl() {
    return this.http.get<any>(API_URL + 'config/logourl').pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  getFaviconUrl() {
    return this.http.get<any>(API_URL + 'config/faviconurl').pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  getBookingUnits() {
    return this.http.get<any>(API_URL + 'config/bookingUnits').pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  getMatchingAlgorithmCriterias() {
    return this.http.get<any>(API_URL + 'config/matchingAlgorithmCriterias').pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  saveBookingUnit(formData: FormData) {
    return this.http.post<boolean>(API_URL + 'config/bookingUnit/update', formData).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  updateAdminCOnfigurations(request: any[]): Observable<any[]> {
    return this.http.put<any[]>(API_URL + ApiActionName.UpdateAdminConfiguration, request, httpOptions)
  }

  updateuserwiseadminconfiguration(request: any): Observable<any> {
    return this.http.put<any>(API_URL + ApiActionName.Updateuserwiseadminconfiguration, request, httpOptions)
  }

  getAdminCOnfigurations(): Observable<any[]> {
    return this.http.get<any[]>(API_URL + ApiActionName.GetAdminConfiguration).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  getUserWiseAdminCOnfigurations(userId: string): Observable<any[]> {
    let url = "?userId=" + userId;

    return this.http.get<any[]>(API_URL + ApiActionName.Getuserwiseadminconfiguration + url).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
  }

  getLongBookingById(id: number): Observable<any> {
    let url = "?id=" + id;
    return this.http.get<any>(API_URL + ApiActionName.GetLongBookingById + url, httpOptions)
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occured: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
