import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import * as jQuery from 'jquery';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { AlliocationModel } from './alliocation-model';
import { CalendarService } from './calendar.service';
import { AllocationInfo } from './allocation-info';
import { AvailabilityModel } from './availability-model';
import { AvailabilityInfo } from './availability-info';
import { filter } from 'rxjs/operators';
import { ClientBookingInfo } from './client-booking-info';
import { UserService } from '../user/user.service';
import { IUserInfo } from '../user/user-info';
import { Subscription, Observable, observable, Subject } from 'rxjs';
import { PartialAvailabilityInfo } from './partial-availability-info';
import { SignalrService } from '../shared/services/common/signalr.service';
import SwedishHolidays from '../shared/constants/swedishHolidays';
// import { BookingInfo } from './booking-info';

declare var kendo: any;


@Component({
  selector: 'wfm-client-calendar',
  templateUrl: './client-calendar.component.html',
  styleUrls: ['./client-calendar.component.css']
})
export class ClientCalendarComponent implements OnInit, AfterViewInit {
  addAllocationTab = 1;
  allocationForm: FormGroup;
  errorMessage: string;
  allocationList: ClientBookingInfo[];
  allocation: ClientBookingInfo;
  availabilityCheckDates: AvailabilityModel[];
  usersList: IUserInfo[];
  userFilter: any = { fullName: '' };
  userId: string;
  notificationModal: any;
  currentDate = new Date();
  selectedUserFullName: string;
  isWeekend: boolean = true;
  subscription: Subscription;
  selectedPartialAvlDate: string;
  partialAvailableListForDay: PartialAvailabilityInfo[];
  allocationTryDate: Date;
  allocationTryTime: string;
  allocationTryLunch: number;
  selectedResourceTypeId = 1;
  resourceTypes: any[];
  availabilityData: AvailabilityInfo[];
  isBookingSelected = true;
  isBookingFromPartialAvailability: boolean = false;
  deleteAllocationId: number;
  isfavEmpTypeRemove: boolean;
  favEmployeeId: string;
  holidayLastMonth: string
  employeeProfileId: Subject<string> = new Subject<string>();
  clientCommonTimes: any[]
  allocationHistory: any
  sendRequest: any;
  isMobile: boolean = window.innerWidth < 768 ? true : false;
  swedishHolidays: typeof SwedishHolidays;


  deviceWidth: number = window.innerWidth;
  deviceHight: number = window.innerHeight;
  selectedDate: Date = new Date(new Date().setHours(0, 0, 0, 0));
  viewStartDate: Date
  viewEndDate: Date

  lunchTimeList: any[] = [
    { value: 0, label: '0' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
    { value: 45, label: '45' },
    { value: 60, label: '60' },
  ]

  constructor(private fb: FormBuilder, private calendarService: CalendarService, private userService: UserService,
    private signalRService: SignalrService) {

    this.subscription = this.signalRService.receiveCalendarUpdates().subscribe(st => {
      if (st) {
        const scheduler = $('#scheduler').data('kendoScheduler');
        scheduler.dataSource.read({ userId: this.userId });
        scheduler.refresh();
      }
    });

    this.resourceTypes = [
      { id: '1', name: 'USK' },
      { id: '2', name: 'SSK' }
    ];
  }

  ngOnInit() {
    kendo.culture("sv-SE");
    this.swedishHolidays = SwedishHolidays;
    this.currentDate.setHours(0, 0, 0, 0);

    this.allocationForm = this.fb.group({
      startDate: [],
      endDate: [],
      weekdayStart: [],
      weekdayEnd: [],
      weekdayLunch: [],
      weekendStart: [],
      weekendEnd: [],
      weekendLunch: [],
      resourceTypeId: ['1'],
      holidays: [''],
      isInquiry: [false]
    });
    this.allocation = {
      id: 0,
      title: '',
      sDate: '',
      eDate: '',
      lunch: 0,
      bookingTotal: 0,
      start: new Date(),
      end: new Date(),
      empName: '',
      empPhone: '',
      empEmail: '',
      empImage: '',
      imagePath: '',
      resourceTypeId: 1,
      resourceTypeClass: '',
      description: '',
      isInquiry: false,
      colorClass: '',
      resourceTypeIconLetter: ''
    };

    this.notificationModal = {
      header: '',
      description: '',
      type: ''
    };

    this.getAllocatedUsers();

    $('#kendo-wd-start').on('change', () => {
      let loadData: boolean = true
      if ($('#kendo-wd-start').val().toString().length == 2 || $('#kendo-wd-start').val().toString().length == 1) {
        loadData = false
        if ($('#kendo-wd-start').val().toString().length == 2) {
          let newVal = $('#kendo-wd-start').val() + ':00'
          $('#kendo-wd-start').val(newVal)
          $('#kendo-wd-start').trigger('change')
        }
        if ($('#kendo-wd-start').val().toString().length == 1) {
          let newVal = '0' + $('#kendo-wd-start').val() + ':00'
          $('#kendo-wd-start').val(newVal)
          $('#kendo-wd-start').trigger('change')
        }
      }
    })
    $('#kendo-wd-end').on('change', () => {
      let loadData: boolean = true
      if ($('#kendo-wd-end').val().toString().length == 2 || $('#kendo-wd-end').val().toString().length == 1) {
        loadData = false
        if ($('#kendo-wd-end').val().toString().length == 2) {
          let newVal = $('#kendo-wd-end').val() + ':00'
          $('#kendo-wd-end').val(newVal)
          $('#kendo-wd-end').trigger('change')
        }
        if ($('#kendo-wd-end').val().toString().length == 1) {
          let newVal = '0' + $('#kendo-wd-end').val() + ':00'
          $('#kendo-wd-end').val(newVal)
          $('#kendo-wd-end').trigger('change')
        }
      }
    })

    $('#kendo-end-date').on('change', (event) => {

      let date = new Date((<HTMLInputElement>event.target).value);
      const filterData = this.availabilityData.find((data) => {
        return (data.startTime.toDateString() === date.toDateString());
      });
      this.isBookingSelected = true;
      if (!filterData || !filterData.isAvailable) {
        this.isBookingSelected = false;
      }
      let scheduler = $("#scheduler").data("kendoScheduler");
      scheduler.select(null, null);
    })

    $(window).resize(() => {
      const scheduler = $('#scheduler').data('kendoScheduler');
      // scheduler.dataSource.read({ userId: this.userId });
      scheduler.refresh();
    })

    var date = new Date();
    this.viewStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
    this.viewEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 1, 1);
    this.getCurrentConfigData()
  }

  ngAfterViewInit(): void {
    $(document).ready(function () {
      $(window).scrollTop(0);

      if (!$('.mob-inquiry').hasClass('outside-click')) {
        $('.mob-bookings').removeClass('tab-inactive');
        $('.mob-bookings').addClass('btn-success');
        $('.mob-inquiry').removeClass('outside-click');
      }

    });
    const calendarService = this.calendarService;
    // Kendo Scheduler Initialize
    let userId = this.userId;
    let viewStartDate = this.viewStartDate
    let viewEndDate = this.viewEndDate
    let patentThis = this

    const customAgenda = kendo.ui.AgendaView.extend({
      endDate() {
        const date = kendo.ui.AgendaView.fn.startDate.call(this);
        return kendo.date.addDays(date, 30);
      }
    });

    var eventHeight: number
    var eventsPerDay = 2
    var isAdaptiveSlotHeight: boolean
    if (this.deviceWidth < 576) {
      eventHeight = 60
      isAdaptiveSlotHeight = true
    } else if (this.deviceWidth < 768) {
      eventHeight = 43
      isAdaptiveSlotHeight = true
    } else if (this.deviceWidth < 992) {
      eventHeight = 43
      isAdaptiveSlotHeight = false
    } else if (this.deviceWidth < 1200) {
      eventHeight = 31
      isAdaptiveSlotHeight = false
    } else {
      isAdaptiveSlotHeight = false
      eventHeight = 30
    }

    ($('#scheduler') as any).kendoScheduler({
      // views: ['month', { type: 'day', allDaySlot: false, eventTemplate: $('#event-day-template').html() },
      //   { type: customAgenda, title: 'Agenda', eventTemplate: $('#event-agenda-template').html() }],
      views: [
        {
          type: 'month',
          eventsPerDay: eventsPerDay,
          eventHeight: eventHeight,
          adaptiveSlotHeight: isAdaptiveSlotHeight,
        },
        { type: 'day', allDaySlot: false, eventTemplate: $('#event-day-template').html() },
        {
          type: customAgenda, title: 'Agenda', eventTemplate: $('#event-agenda-template').html(),
          selectedDateFormat: "{0:dd/MM/yyyy} - {1:dd/MM/yyyy}",
          selectedShortDateFormat: "{0:dd/MM} - {1:dd/MM}"
        }
      ],
      eventTemplate: ($('#event-slot-template') as any).html(),
      dateHeaderTemplate: kendo.template("<strong>#=kendo.toString(date, 'ddd dd/M')#</strong>"),
      selectable: true,
      timezone: "Europe/Stockholm",
      editable: {
        destroy: false,
        move: false,
        resize: false,
        create: false
      },
      dataSource:
      {
        transport: {
          read(o) {
            let loadData = false;

            if (!o.data.userId && !userId) {
              loadData = true;
              userId = o.data.userId ? o.data.userId : '';
            } else if (o.data.userId) {
              loadData = true;
              userId = o.data.userId;
            }
            viewStartDate = o.data.viewStartDate ? o.data.viewStartDate : viewStartDate
            viewEndDate = o.data.viewEndDate ? o.data.viewEndDate : viewEndDate
            if (loadData) {
              calendarService.getClientAllocations(userId, viewStartDate, viewEndDate)
                .subscribe(
                  (allocations: ClientBookingInfo[]) => {
                    o.success(allocations);
                  },
                  (error: any) => this.errorMessage = error as any
                );
            } else {
              o.success([]);
            }
          }
        }
      },
      toolbar: ['pdf'],
      pdf: {
        fileName: 'Bookings-Export.pdf',
      }
    });

    const scheduler = $('#scheduler').data('kendoScheduler');

    scheduler.wrapper.on('mouseup', '.k-scheduler-table td, .k-event', function (e) {
      const target = $(e.currentTarget);
      if (target.hasClass('k-event')) {
        const event = scheduler.occurrenceByUid(target.data('uid'));
        scheduler.editEvent(event);
      }
    });

    scheduler.bind('change', (event) => {
      const eventStartDate = new Date(event.start);
      const eventEndDate = new Date(event.end);
      $('.k-scheduler-table td').removeClass('cell-celected');

      this.selectedDate = eventStartDate
      if (eventStartDate >= this.currentDate) {
        if (event != null && event.slots != null && event.slots.length > 0) {
          $(event.slots[0].element).addClass('cell-celected');
        }
      }

      const filterData = this.availabilityData.find((data) => {
        return (data.startTime.toDateString() === event.start.toDateString());
      });
      this.isBookingSelected = true;
      if (!filterData || !filterData.isAvailable) {
        this.isBookingSelected = false;
      }
      // Following is required because event end date is set to next day 00:00:00
      const endDate = new Date(eventEndDate.setDate(eventEndDate.getDate() + -1));

      this.isWeekend = (endDate.getDay() === 6) || (endDate.getDay() === 0);
      const startTime = (this.isWeekend ? '10:00' : '09:00');
      const endTime = (this.isWeekend ? '15:00' : '18:00');
      const lunch = (this.isWeekend ? 0 : 45);

      if (this.isWeekend) {
        var startInitTime = new Date(2000, 0, 0, 10, 0, 0);
        var endInitTime = new Date(2000, 0, 0, 15, 0, 0);
        this.setEndTimeMin(startInitTime);
        this.setStartTimeMax(endInitTime);
      } else {
        var startInitTime = new Date(2000, 0, 0, 9, 0, 0);
        var endInitTime = new Date(2000, 0, 0, 18, 0, 0);
        this.setEndTimeMin(startInitTime);
        this.setStartTimeMax(endInitTime);
      }

      this.allocationForm.patchValue({
        startDate: eventStartDate,
        endDate,
        weekdayStart: startTime,
        weekdayEnd: endTime,
        weekdayLunch: lunch,
      });

      // $('#kendo-start-date').data('kendoDatePicker').value(eventStartDate);
      $('#kendo-end-date').data('kendoDatePicker').value(endDate);
      $('#kendo-wd-start').data('kendoTimePicker').value(startTime);
      $('#kendo-wd-end').data('kendoTimePicker').value(endTime);

    });

    scheduler.bind('edit', (e) => {
      e.preventDefault()
      this.isBookingSelected = true;
      const filterData = this.availabilityData.find((data) => {
        return (data.startTime.toDateString() === e.event.start.toDateString());
      });
      if (!filterData || !filterData.isAvailable) {
        this.isBookingSelected = false;
      }
      if (e.event.id === 0) {
        if (e.event.start >= this.currentDate) {

          ($('#requestAllocationModal') as any).modal('show');
        }
      } else {
        this.allocation = e.event;
        ($('#allocationDataModal') as any).modal('show');
      }
    });

    scheduler.element.on('dblclick', '.k-scheduler-content td', (e) => {
      e.preventDefault();
      const slot = scheduler.slotByElement($(e.target));
      if (slot.startDate >= this.currentDate) {
        $('.k-scheduler-table td').removeClass('cell-celected');
        $(scheduler.slotByElement($(e.target)).element).addClass('cell-celected');
        const eventStartDate = slot.startDate;
        const eventEndDate = slot.endDate;
        // Following is required because event end date is set to next day 00:00:00
        const endDate = new Date(eventEndDate.setDate(eventEndDate.getDate() + -1));

        this.isWeekend = (endDate.getDay() === 6) || (endDate.getDay() === 0);
        const startTime = (this.isWeekend ? '10:00' : '09:00');
        const endTime = (this.isWeekend ? '15:00' : '18:00');
        const lunch = (this.isWeekend ? 0 : 45);

        if (this.isWeekend) {
          var startInitTime = new Date(2000, 0, 0, 10, 0, 0);
          var endInitTime = new Date(2000, 0, 0, 15, 0, 0);
          this.setEndTimeMin(startInitTime);
          this.setStartTimeMax(endInitTime);
        } else {
          var startInitTime = new Date(2000, 0, 0, 9, 0, 0);
          var endInitTime = new Date(2000, 0, 0, 18, 0, 0);
          this.setEndTimeMin(startInitTime);
          this.setStartTimeMax(endInitTime);
        }

        this.allocationForm.patchValue({
          startDate: eventStartDate,
          endDate,
          weekdayStart: startTime,
          weekdayEnd: endTime,
          weekdayLunch: lunch,
          resourceTypeId: this.selectedResourceTypeId
        });

        // $('#kendo-start-date').data('kendoDatePicker').value(eventStartDate);
        $('#kendo-end-date').data('kendoDatePicker').value(endDate);
        $('#kendo-end-date').data('kendoDatePicker').enable(true);
        $('#kendo-wd-start').data('kendoTimePicker').value(startTime);
        $('#kendo-wd-start').data('kendoTimePicker').enable(true);
        $('#kendo-wd-end').data('kendoTimePicker').value(endTime);
        $('#kendo-wd-end').data('kendoTimePicker').enable(true);

        this.isBookingFromPartialAvailability = false;
        this.onMakeBookingClick()
      }
    });

    scheduler.bind('navigate', (e) => {
      this.setViewDateRange(e)
      if (e.action == 'next') {
        $('.k-state-default.k-header.k-nav-next').addClass('k-state-hover');
      }
      if (e.action == 'previous') {
        $('.k-state-default.k-header.k-nav-prev').addClass('k-state-hover');
      }
      if (e.view === 'day') {
        e.sender.select(null);
        setTimeout(() => {
          this.scrollToHour(8);
        }, 200);
      }
    });

    scheduler.bind('dataBound', (e) => {
      this.schedulerDataBound(scheduler);
      setTimeout(() => {
        $('.k-scheduler-navigation').css('pointer-events', 'all');
        $('.k-scheduler-navigation').css('opacity', '1');
        $('.k-state-default.k-header.k-nav-prev').removeClass('k-state-hover');
        $('.k-state-default.k-header.k-nav-next').removeClass('k-state-hover');
      }, 500);
    });


    this.initializeKendoComponents();

    $('.k-scheduler-tools').hide();
    $('.client-booking-scheduler .k-scheduler-views').append('<li class="k-state-default  k-exportpdf" data-name="Export"><a role="button" href="#" class="k-link mobile-export">Skriv ut</a></li>')
    $('.client-booking-scheduler .mobile-export').click(() => {
      $('.client-booking-scheduler .k-pdf').click();
    });
    if (kendo.support.mobileOS) {
      $('.k-sm-date-format').css('font-size', '100%');
    }

    $('#allocationDataModal').on('hide.bs.modal', () => {
      $('.collapse').removeClass('show');
      this.sendRequest = false;
    })

  }

  schedulerDataBound(scheduler): void {
    // const kscheduler = $('#scheduler').data('kendoScheduler');
    const schView = scheduler.view().name;

    // $(".k-scheduler-views li").on("click", function () {

    //   if ($(this).hasClass('k-view-agenda')) {
    //     setTimeout(() => {
    //       $('.k-nav-current').addClass('agenda-date-header')
    //     }, 200);
    //   }else {
    //     $('.k-nav-current').removeClass('agenda-date-header')
    //   }
    // });

    if (schView === 'month') {
      $('.k-scheduler-navigation').css('pointer-events', 'none');
      $('.k-scheduler-navigation').css('opacity', '0.5');
      const container = scheduler.view().element;
      const cells = container.find('td[role=gridcell]');

      $('.slot-orange').parent().addClass('dayview-slot-orange');
      $('.slot-yellow').parent().addClass('dayview-slot-yellow');
      $('.slot-blue').parent().addClass('dayview-slot-blue');
      $('.slot-yellow .font-weight-bold').css('border-color', 'red');

      this.availabilityCheckDates = [];
      for (let i = 0; i < cells.length; i++) {
        const cell = $(cells[i]);
        const slot = scheduler.slotByElement(cell);
        const avl = {} as AvailabilityModel;
        avl.category = scheduler.view().name;
        avl.startTime = slot.startDate;
        avl.endTime = slot.endDate;
        avl.startTimeString = avl.startTime.getFullYear() + '-' + (avl.startTime.getMonth() + 1) + '-' + avl.startTime.getDate();
        avl.endTimeString = avl.endTime.getFullYear() + '-' + (avl.endTime.getMonth() + 1) + '-' + avl.endTime.getDate();
        avl.resourceTypeId = this.selectedResourceTypeId;
        this.availabilityCheckDates.push(avl);
      }

      //this.swedishHolidays = this.calendarService.getSwedishHolidays()

      this.calendarService.checkClientAvailability(this.availabilityCheckDates).subscribe(
        (avlData: AvailabilityInfo[]) => {
          this.availabilityData = avlData;
          for (let i = 0; i < cells.length; i++) {
            const cell = $(cells[i]);
            const slot = scheduler.slotByElement(cell);

            if (slot) {
              const slotStartDate = slot.startDate as Date;
              const slotEndDate = slot.endDate as Date;
              const filterData = avlData.find(function (data) {
                return (data.startTime.toDateString() === slotStartDate.toDateString()
                  && data.endTime.toDateString() === slotEndDate.toDateString());
              });
              if (filterData) {
                if (filterData.startTime.toDateString() === this.selectedDate.toDateString()) {
                  if (filterData.isAvailable != this.isBookingSelected) {
                    this.isBookingSelected = filterData.isAvailable
                  }
                }

                var holidayArr: any[];
                var slotDateJsonFormat = this.getJsonDateFormat(slot.startDate);
                if (this.holidayLastMonth !== slotDateJsonFormat) {
                  this.holidayLastMonth = slotDateJsonFormat
                  holidayArr = this.swedishHolidays[slotDateJsonFormat]
                }

                // Mark Sundays & saturdays with different color using CSS class
                if (slotStartDate.getDay() == 0) {
                  cell.addClass('cell-sunday')
                }
                if (slotStartDate.getDay() == 6) {
                  cell.addClass('cell-saturday')
                }

                if (filterData && filterData.startTime >= this.currentDate) {
                  if (filterData.isAvailable) {
                    cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span>');
                    cell.css('background-image', 'linear-gradient(225deg, #85BD3C, #85BD3C 30px, transparent 0px, transparent)');
                  } else {
                    cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span>');
                    cell.css('background-image', 'linear-gradient(225deg, #FF6161, #FF6161 30px, transparent 0px, transparent)');
                  }
                  if (holidayArr) {
                    var holidayObj = holidayArr.find(e => e.date == slot.startDate.getDate())
                    if (holidayObj) {
                      cell.addClass('holiday')
                      cell.append(`<a class="d-lg-block d-xl-block d-md-block badge badge-info holiday-name mobile-hide" style="color:white;margin-top: 2px;" >${holidayObj.holiday}</a >`);
                    }
                  }
                } else {
                  cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span>');
                  cell.css('background-image', 'linear-gradient(225deg, #d1d1d1, #d1d1d1 30px, transparent 0px, transparent)');
                  if (holidayArr) {
                    var holidayObj = holidayArr.find(e => e.date == slot.startDate.getDate())
                    if (holidayObj) {
                      cell.addClass('holiday')
                      cell.append(`<a class="d-lg-block d-xl-block d-md-block badge badge-info holiday-name mobile-hide" style="color:white;margin-top: 2px;" >${holidayObj.holiday}</a >`);
                    }
                  }
                }

                // Check for monthname
                var isFirstDay: boolean
                if (slotStartDate.getDate() == 1) {
                  isFirstDay = true
                  var month = slotStartDate.toLocaleString('sv-SE', { month: 'long' });
                  var monthCapitalized = month.charAt(0).toUpperCase() + month.slice(1)
                } else {
                  isFirstDay = false
                }

                // Display month first day label if not holiday
                if (!holidayObj && isFirstDay) {
                  if (cell.children('.badge-info.holiday-name').length == 0) {
                    cell.append(`<a class=" d-lg-block d-xl-block d-md-block  badge badge-info holiday-name mobile-hide" style="color:white;margin-top: 2px;" >Första ${monthCapitalized}</a>`);
                  }
                }

                // Dispaly week number for every monday
                if (slotStartDate.getDay() == 1) {
                  var weekNoArr = this.calendarService.getWeekNumber(slotStartDate)
                  cell.append(`<span class="badge badge-pill week-number mobile-hide" >V.${weekNoArr[1]}</span >`);
                }
              }
            }
          }
        },
        (error: any) => this.errorMessage = error as any
      );

    } if (schView === 'day') {
      $('.slot-orange.day-view').parent().addClass('dayview-slot-orange');
      $('.slot-yellow.day-view').parent().addClass('dayview-slot-yellow');
      $('.slot-blue.day-view').parent().addClass('dayview-slot-blue');
    }
    $('.k-scheduler-navigation').css('pointer-events', 'all');
    $('.k-scheduler-navigation').css('opacity', '1');

    if (this.deviceWidth < 768) {
      $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(1)').text('Mån');
      $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(2)').text('Tis');
      $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(3)').text('Ons');
      $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(4)').text('Tor');
      $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(5)').text('Fre');
      $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(6)').text('Lör');
      $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(7)').text('Sön');
    }
  };

  getJsonDateFormat(date: Date): string {
    return date.getFullYear() + '' + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1))
  }

  initializeKendoComponents(): void {

    ($('#kendo-end-date') as any).kendoDatePicker({
      format: 'yyyy-MM-dd',
      min: new Date(),
      change: (val) => {
        this.isWeekend = (val.sender._value.getDay() === 6) || (val.sender._value.getDay() === 0);
        const startTime = (this.isWeekend ? '10:00' : '09:00');
        const endTime = (this.isWeekend ? '15:00' : '18:00');
        const lunch = (this.isWeekend ? 0 : 45);

        this.allocationForm.patchValue({
          startDate: val.sender._value,
          endDate: val.sender._value,
          weekdayStart: startTime,
          weekdayEnd: endTime,
          weekdayLunch: lunch,
          resourceTypeId: this.selectedResourceTypeId
        });

        $('#kendo-wd-start').data('kendoTimePicker').value(startTime);
        $('#kendo-wd-end').data('kendoTimePicker').value(endTime);
      }
    });

    ($('#kendo-wd-start') as any).kendoTimePicker({
      format: 'HH:mm',
      interval: 15,
      change: (val) => {
        // const dt = new Date(val.sender._value);
        var dt: any
        if (val.sender._value) {
          dt = new Date(val.sender._value);
        } else {
          var timeStr = val.sender._oldText
          dt = new Date(2000, 0, 0, parseInt(timeStr.slice(0, 2)), parseInt(timeStr.slice(3, 5)), 0);
        }
        const hours = dt.getHours();
        const minutes = dt.getMinutes();
        const formattedTime = [(hours > 9 ? '' : '0') + hours.toString(), ':', (minutes > 9 ? '' : '0') + minutes.toString()].join('');
        this.setEndTimeMin(dt);
        const formControlName = val.sender.element[0].attributes['data-fcname'].value;
        this.allocationForm.get(formControlName).setValue(formattedTime);
      }
    });
    ($('#kendo-wd-end') as any).kendoTimePicker({
      format: 'HH:mm',
      interval: 15,
      change: (val) => {
        // const dt = new Date(val.sender._value);
        var dt: any
        if (val.sender._value) {
          dt = new Date(val.sender._value);
        } else {
          var timeStr = val.sender._oldText
          dt = new Date(2000, 0, 0, parseInt(timeStr.slice(0, 2)), parseInt(timeStr.slice(3, 5)), 0);
        }
        const hours = dt.getHours();
        const minutes = dt.getMinutes();
        const formattedTime = [(hours > 9 ? '' : '0') + hours.toString(), ':', (minutes > 9 ? '' : '0') + minutes.toString()].join('');
        this.setStartTimeMax(dt);
        const formControlName = val.sender.element[0].attributes['data-fcname'].value;
        this.allocationForm.get(formControlName).setValue(formattedTime);
      }
    });

    ($('.kendo-picker') as any).bind('focus', function () {
      $(this).data('kendoDatePicker').open();
    });
    ($('.kendo-time') as any).bind('focus', function () {
      $(this).data('kendoTimePicker').open();
    });

    // $(".k-scheduler-views li").on("click", function () {

    //   if ($(this).hasClass('k-view-agenda')) {
    //     setTimeout(() => {
    //       $('.k-nav-current').addClass('agenda-date-header')
    //     }, 500);
    //   }else {
    //     $('.k-nav-current').removeClass('agenda-date-header')
    //   }
    // });

  }

  setEndTimeMin(dt) {
    var endTimepicker = $("#kendo-wd-end").data("kendoTimePicker");
    endTimepicker.min(new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), (dt.getMinutes() == 45 ? dt.getHours() + 1 : dt.getHours()), (dt.getMinutes() == 45 ? 0 : dt.getMinutes() + 15), 0));
  }
  setStartTimeMax(dt) {
    var startTimepicker = $("#kendo-wd-start").data("kendoTimePicker");
    startTimepicker.max(new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), (dt.getMinutes() == 0 ? dt.getHours() - 1 : dt.getHours()), (dt.getMinutes() == 0 ? 45 : dt.getMinutes() - 15), 0));
  }

  getAllocatedUsers(): void {
    this.userService.getAllocatedUserList('Client', 0).subscribe(
      (res: IUserInfo[]) => {
        this.usersList = res;
      },
      (error: any) => this.errorMessage = error as any
    );
  }

  requestAllocation(): void {
    this.isBookingFromPartialAvailability = false;

    const validator = ($('#allocationFormContent') as any).kendoValidator({
      rules: {
        matches: function (input) {
          if (input.data('greaterthan') == 'kendo-wd-start') {
            var startTimeStr = $('#kendo-wd-start').val() as string;
            var endTimeStr = $('#kendo-wd-end').val() as string;

            var startWDTime = new Date(2000, 0, 0, parseInt(startTimeStr.slice(0, 2)), parseInt(startTimeStr.slice(3, 5)), 0);
            var endWDTime = new Date(2000, 0, 0, parseInt(endTimeStr.slice(0, 2)), parseInt(endTimeStr.slice(3, 5)), 0);

            if (startWDTime < endWDTime) {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        }
      },
    }).data('kendoValidator');

    if (validator.validate()) {

      const filterData = this.availabilityData.find((data) => {
        return (data.startTime.toDateString() === this.allocationForm.value.startDate.toDateString());
      });
      if (!filterData.isAvailable) {
        this.isBookingSelected = false;
      }


      var allocationDate = this.allocationForm.get('startDate').value;
      var day = 60 * 60 * 24 * 1000;
      var isAllocationDateHoliday = this.calendarService.isHoliday(allocationDate);
      var nextDate = new Date(allocationDate.getTime() + day);
      var prevDate = new Date(allocationDate.getTime() - day);
      var isNextDateHoliday = this.calendarService.isHoliday(nextDate);
      var isPrevDateHoliday = this.calendarService.isHoliday(prevDate);
      
      var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

      var holidayStr: string = isAllocationDateHoliday ? 'Holiday' :
        ((isNextDateHoliday && !isAllocationDateHoliday) ? 'DayBeforeHoliday' : (
          (isPrevDateHoliday && !isAllocationDateHoliday) ? 'DayAfterHoliday' : days[this.allocation.start.getDay()])); // 0 = Sunday, 6 = Saturday

      this.allocationForm.patchValue({
        isInquiry: !this.isBookingSelected,
        holidays: holidayStr
      });
      const validator = ($('#allocationFormContent') as any).kendoValidator().data('kendoValidator');
      if (validator.validate()) {
        this.calendarService.requestClientAllocations(this.allocationForm.value).subscribe(
          (allocationInfo: ClientBookingInfo) => {
            $('.k-scheduler-table td').removeClass('cell-celected');
            ($('#requestAllocationModal') as any).modal('hide');
            this.onAllocationRequestComplete(allocationInfo, this.allocationForm.value);
          },
          (error: any) => this.errorMessage = error as any
        );
      }
    }
  }

  onAllocationRequestComplete(allocationInfo: ClientBookingInfo, allocationForm: AlliocationModel): void {

    if (allocationInfo != null) {
      const scheduler = $('#scheduler').data('kendoScheduler');
      this.userId = 'all';
      this.selectedUserFullName = null;
      scheduler.dataSource.read({ userId: this.userId });
      $('.employee-wrapper').removeClass('employee-wrapper-active');
      scheduler.select(null);
      this.userService.getAllocatedUserList('Client', 0).subscribe(
        (res: IUserInfo[]) => {
          this.usersList = res;
        },
        (error: any) => this.errorMessage = error as any
      );
      this.allocation = allocationInfo;

      ($('#allocationDataModal') as any).modal('show');
      this.signalRService.broadcastCalendarUpdates();
    } else {

      this.calendarService.getPartialAvailabilityList(allocationForm).subscribe(
        (partialList: PartialAvailabilityInfo[]) => {
          if (partialList != null && partialList.length > 0) {
            this.partialAvailableListForDay = partialList;
            this.allocationTryDate = allocationForm.startDate;
            this.allocationTryLunch = allocationForm.weekdayLunch;
            this.allocationTryTime = allocationForm.weekdayStart + '-' + allocationForm.weekdayEnd;
            ($('#partialAvailabilityListModal') as any).modal('show');
          } else {
            this.setNotificationData('booking', false);
          }
        },
        (error: any) => this.errorMessage = error as any
      );
    }
    $('#kendo-end-date').data('kendoDatePicker').value('');
    $('#kendo-wd-start').data('kendoTimePicker').value('');
    $('#kendo-wd-end').data('kendoTimePicker').value('');
    // this.allocationForm.patchValue({
    //   startDate: '',
    //   endDate: '',
    //   weekdayStart: '',
    //   weekdayEnd: '',
    //   weekdayLunch: 0,
    //   resourceTypeId: this.selectedResourceTypeId
    // });
  }

  userSelect(userId: string, fullName: string): void {
    $('.employee-wrapper').removeClass('employee-wrapper-active');
    $('#employee-wrapper-' + userId).addClass('employee-wrapper-active');
    if (this.userId === userId) {
      this.userId = 'all';
      this.selectedUserFullName = null;
      $('#employee-wrapper-' + userId).removeClass('employee-wrapper-active');
    } else {
      this.selectedUserFullName = fullName;
      this.userId = userId;
    }
    const scheduler = $('#scheduler').data('kendoScheduler');
    scheduler.dataSource.read({ userId: this.userId });
    scheduler.refresh();
    scheduler.select(null);
  }


  setNotificationData(flow, status): void {
    if (flow === 'booking') {
      this.notificationModal.header = 'Bokning misslyckades';
      this.notificationModal.description = 'Tyvärr så kunde vi inte hitta en tillgänglig resurs för det valda datumet';
      this.notificationModal.type = 'danger';
    } else if (flow === 'remove') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Förfrågan raderades';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Borttaggning av förfrågan misslyckades! Var god försök igen';
        this.notificationModal.type = 'danger';
      }
    } else if (flow === 'favEmp') {
      if (status) {
        this.notificationModal.header = 'Successfull!';
        this.notificationModal.description = 'Your changes have been saved successfuly.';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Failed!';
        this.notificationModal.description = 'Something went wrong! Changes have been discarded.';
        this.notificationModal.type = 'danger';
      }
    } else if (flow === 'inquiry') {
      if (status) {
        this.notificationModal.header = 'Lyckades';// Success
        this.notificationModal.description = 'Inquiry added successfully!';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades'; //'Failed';
        this.notificationModal.description = 'Inquiry adding failed! Please try again.';
        this.notificationModal.type = 'danger';
      }
    }
    ($('#notificationModal') as any).modal('show');
    setTimeout(() => {
      ($('#notificationModal') as any).modal('hide');
    }, 3000);
  }


  scrollToHour(hour): void {
    const time = new Date();
    time.setHours(hour);
    time.setMinutes(0);
    time.setSeconds(0);
    time.setMilliseconds(0);

    const scheduler = $('#scheduler').data('kendoScheduler');
    const contentDiv = scheduler.element.find('div.k-scheduler-content');
    const rows = contentDiv.find('tr');

    for (let i = 0; i < rows.length; i++) {
      const element = $(rows[i]);
      const slot = scheduler.slotByElement(element);

      const slotTime = kendo.toString(slot.startDate, 'HH:mm');
      const targetTime = kendo.toString(time, 'HH:mm');

      if (targetTime === slotTime) {
        contentDiv.scrollTop(0);

        const elementTop = element.offset().top;
        const containerTop = contentDiv.offset().top;

        contentDiv.scrollTop(elementTop - containerTop);
      }
    }
  }


  bookFromPartialAvailability(partialAvailabilityData: PartialAvailabilityInfo): void {
    ($('#partialAvailabilityListModal') as any).modal('hide');
    this.allocationForm.patchValue({
      startDate: this.allocationTryDate,
      endDate: this.allocationTryDate,
      weekdayStart: partialAvailabilityData.fromTime,
      weekdayEnd: partialAvailabilityData.toTime,
      weekdayLunch: this.allocationTryLunch,
    });

    $('#kendo-end-date').data('kendoDatePicker').value(this.allocationTryDate);
    $('#kendo-end-date').data('kendoDatePicker').enable(false);
    $('#kendo-wd-start').data('kendoTimePicker').value(partialAvailabilityData.fromTime);
    $('#kendo-wd-start').data('kendoTimePicker').enable(false);
    $('#kendo-wd-end').data('kendoTimePicker').value(partialAvailabilityData.toTime);
    $('#kendo-wd-end').data('kendoTimePicker').enable(false);

    this.isBookingFromPartialAvailability = true;
    ($('#requestAllocationModal') as any).modal('show');
  }

  addInquiryOnpartial(allocationData) {
    this.allocationForm.patchValue({
      clientId: this.userId,
      isInquiry: true
    });
    this.calendarService.requestClientAllocations(this.allocationForm.value).subscribe(
      (allocationInfo: ClientBookingInfo) => {
        if (allocationInfo != null) {
          ($('#partialAvailabilityListModal') as any).modal('hide');
          this.signalRService.broadcastCalendarUpdates();
          this.setNotificationData('inquiry', true);
          this.allocationForm.patchValue({
            startDate: '',
            endDate: '',
            weekdayStart: '',
            weekdayEnd: '',
            weekdayLunch: 0,
            employeeId: ''
          });
        } else {
          this.setNotificationData('inquiry', false);
        }
        $('.k-scheduler-table td').removeClass('cell-celected');
        // this.onAllocationRequestComplete(allocationInfo, this.allocationForm.value);
      },
      (error: any) => this.errorMessage = error as any
    );
  }

  onResourceTypeChange(resourceTypeId: number): void {
    this.selectedResourceTypeId = resourceTypeId;
    this.allocationForm.patchValue({
      resourceTypeId: resourceTypeId
    });
    this.availabilityCheckDates.forEach(x => x.resourceTypeId = resourceTypeId);
    const scheduler = $('#scheduler').data('kendoScheduler');
    scheduler.dataSource.read({ userId: this.userId });
  }

  onMakeBooking(event): void {
    const scheduler = $('#scheduler').data('kendoScheduler');
    scheduler.trigger('edit', event)
  }

  openDeleteConfirmModal(allocationId: number): void {
    this.deleteAllocationId = allocationId;
    ($('#allocationDataModal') as any).modal('hide');
    ($('#allocationDeleteModal') as any).modal('show');
  }

  onEmployeeProfileClick(userId: string): void {
    this.employeeProfileId.next(userId)
    setTimeout(() => {
      ($('#employeeProfileViewModal') as any).modal('show');
    }, 1000);

  }


  onDeleteInquiry(): void {
    this.calendarService.removeClientAllocation(this.deleteAllocationId).subscribe(
      (status: boolean) => {
        const scheduler = $('#scheduler').data('kendoScheduler');
        ($('#allocationDeleteModal') as any).modal('hide');
        scheduler.dataSource.read({ userId: this.userId });
        $('.employee-wrapper').removeClass('employee-wrapper-active');
        scheduler.select(null);
        this.setNotificationData('remove', status);
        this.signalRService.broadcastCalendarUpdates();
      },
      (error: any) => this.errorMessage = error as any
    )
  }

  onStarclick(event, userId: string, isActive: boolean): void {
    event.preventDefault();

    this.isfavEmpTypeRemove = isActive;
    this.favEmployeeId = userId;
    ($('#employeefavouriteModal') as any).modal('show');
  }

  onStarclickConfirm(): void {
    ($('#employeefavouriteModal') as any).modal('hide');
    this.userService.switchFavouriteEmployee(this.favEmployeeId, this.isfavEmpTypeRemove).subscribe(
      (status: boolean) => {
        this.setNotificationData('favEmp', status);
        this.getAllocatedUsers();
      },
      (error: any) => this.errorMessage = error as any
    )
  }

  onMobileTabSelect(event) {
    if (event == 'userlist') {
      $('#myTab').css('display', 'flex')
      $('#myTabContent').css('display', 'block')
    } else if (event == 'bookings') {
      $('#myTab').css('display', 'none')
      $('#myTabContent').css('display', 'none')
      $('.btn-calendar-booking').click()
    } else if (event == 'inquiry') {
      $('#myTab').css('display', 'none')
      $('#myTabContent').css('display', 'none')
      $('.btn-calendar-inquiry').click()
    }
  }

  setCommonTimes(item): void {
    this.allocationForm.patchValue({
      weekdayStart: item.startTime,
      weekdayEnd: item.endTime,
      weekdayLunch: item.lunch,
    });
    // $('#kendo-start-date').data('kendoDatePicker').value(eventStartDate);
    // $('#kendo-end-date').data('kendoDatePicker').value(item.endDate);
    $('#kendo-wd-start').data('kendoTimePicker').value(item.startTime);
    $('#kendo-wd-end').data('kendoTimePicker').value(item.endTime);
  }

  closeDrawer() {
    if ($('.mob-bookings').hasClass('last-selection')) {
      var tab = 'bookings'
    } else if ($('.mob-inquiry').hasClass('last-selection')) {
      var tab = 'inquiry'
    }
    $('.mob-tab').removeClass('btn-success')
    $('.mob-tab').addClass('tab-inactive')
    if (tab == 'userlist') {
      $('.mob-userlist').addClass('btn-success')
      $('.mob-userlist').removeClass('tab-inactive')
    } else if (tab == 'bookings') {
      $('.mob-bookings').addClass('btn-success')
      $('.mob-bookings').removeClass('tab-inactive')
    } else if (tab == 'inquiry') {
      $('.mob-inquiry').addClass('btn-success')
      $('.mob-inquiry').removeClass('tab-inactive')
    }
    $('#myTab').css('display', 'none')
    $('#myTabContent').css('display', 'none')
    setTimeout(() => {
      $('.k-scheduler-navigation').css('pointer-events', 'all');
      $('.k-scheduler-navigation').css('opacity', '1');
    }, 500);
  }
  onMakeBookingClick() {
    this.clientCommonTimes = []
    if (this.isBookingSelected) {
      this.calendarService.getClientCommonTimes(null).subscribe((respo) => {
        this.clientCommonTimes = respo;
        ($('#requestAllocationModal') as any).modal('show');
      })
    } else {
      ($('#requestAllocationModal') as any).modal('show');
    }
  }
  onViewHistoryBtnClick(allocationId: number) {
    this.toggleSendRequest();
    if (this.sendRequest) {
      this.calendarService.getAllocationHistory(allocationId).subscribe(
        (res) => {
          this.allocationHistory = res;
        },
        (error: any) => this.errorMessage = error as any
      );
    }

  }
  toggleSendRequest() {
    this.sendRequest = !this.sendRequest
  }

  setViewDateRange(e) {
    var view = e.sender.view();

    switch (e.view) {
      case 'month':
        this.viewStartDate = new Date(e.date.getFullYear(), e.date.getMonth(), 1);
        this.viewEndDate = new Date(e.date.getFullYear(), e.date.getMonth() + 1, 1, 1);

        break;
      case 'day':
        this.viewStartDate = new Date(e.date.getFullYear(), e.date.getMonth(), 1);
        this.viewEndDate = new Date(e.date.getFullYear(), e.date.getMonth() + 1, 1, 1);
        break;
      case 'Agenda':
        this.viewStartDate = new Date(e.date.getFullYear(), e.date.getMonth(), e.date.getDate());
        this.viewEndDate = new Date(e.date.getFullYear(), e.date.getMonth() + 1, e.date.getDate());
        break;
      default:
        break;
    }
    const scheduler = $('#scheduler').data('kendoScheduler');
    scheduler.dataSource.read({ userId: this.userId, viewStartDate: this.viewStartDate, viewEndDate: this.viewEndDate });
    // scheduler.refresh();

  }

  getCurrentConfigData(): void {
    this.userService.getConfigData().subscribe((res) => {
      this.resourceTypes = res.resourceTypes

    })
  }
  getResourceNameById(id) {
    var resource = this.resourceTypes.find(x => x.id == id)
    if (resource) {
      return resource.displayName
    } else {
      return '';
    }
  }
}
