import { Component, OnInit } from '@angular/core';
import { FormsModule, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/user/user.service';

@Component({
  selector: 'wfm-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup
  showSuccessMsg: Boolean = false
  showFailedMsg: Boolean = false
  logoImageUrl : string;

  constructor(
    private fb: FormBuilder,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.email, Validators.required]]
    })
        //this.logoImageUrl ="https://teamplanstorage.blob.core.windows.net/24transport/logo.png"// localStorage.getItem('logoImageUrl');
        this.logoImageUrl = localStorage.getItem('logoImageUrl');
  }

  public get email() {
    return this.forgotPasswordForm.get('email');
  }

  onSubmitEmail(): void {
    if (this.forgotPasswordForm.valid) {
      const formData = new FormData();
      formData.append('email',this.email.value)
      this.userService.submitForgotPasswordEmail(formData).subscribe((status) => {
      if (status) {
        this.showFailedMsg = false
        this.showSuccessMsg = true
      } else {
        this.showFailedMsg = true
      }
    })
    }
    
  }

}
