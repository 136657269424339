import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
//import { Observable } from 'rxjs';
import { of, Observable, throwError, Subject, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { tap, catchError } from 'rxjs/operators';

const API_URL = environment.apiEndpoint;
 
 const httpOptions = {
  headers: new HttpHeaders ({
    'Content-Type': 'application/json'
  }),
};

@Injectable({
  providedIn: 'root'
})

export class FileUploadService {

  private subject = new Subject<any>();

  constructor(private http:HttpClient) { 
  }

  upload(formData : FormData):  Observable<string>{
    console.log('Uploading file in service !...');
    
    //return this.http.request(req);
    return this.http.post<string>(API_URL + 'config/logo', formData).pipe(
      tap(resp => {
        console.log(resp);
        this.subject.next({ path: resp });
        console.log('File upload completed.');
      }),
      catchError(this.handleError)
      
    );
  }


  getFiles() : Observable<any>{
    return this.http.get(API_URL + 'config/files', httpOptions).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );
    //return this.http.get(`${this.baseUrl}config/files`);
  }

  getFile(id: any) : Observable<any> {
    return this.http.get(API_URL + 'config/logo', httpOptions).pipe(
      tap(resp => {
      }),
      catchError(this.handleError)
    );

    //return this.http.get(`${this.baseUrl}config/logo`, id);
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occured: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

}
