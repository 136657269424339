const SwedishHolidays: any =
{
    "202001": [
        {
            "date": "01",
            "holiday": "Nyårsdagen"
        },
        {
            "date": "06",
            "holiday": "Trettondedag jul"
        }
    ],
    "202004": [
        {
            "date": "03",
            "holiday": "Annandag påsk"
        },
        {
            "date": "06",
            "holiday": "Annandag påsk"
        },
        {
            "date": "10",
            "holiday": "Långfredagen"
        },
        {
            "date": "12",
            "holiday": "Påskdagen"
        },
        {
            "date": "19",
            "holiday": "Annandag påsk"
        },
        {
            "date": "25",
            "holiday": "Annandag påsk"
        }
    ],
    "202005": [
        {
            "date": "01",
            "holiday": "Första Maj"
        },
        {
            "date": "21",
            "holiday": "Kristi himmelfärdsdag"
        },
        {
            "date": "31",
            "holiday": "Pingstdagen"
        }
    ],
    "202006": [
        {
            "date": "06",
            "holiday": "Sveriges nationaldag"
        },
        {
            "date": "20",
            "holiday": "Midsommar"
        }
    ],
    "202010": [
        {
            "date": "31",
            "holiday": "Alla helgons dag"
        }
    ],
    "202012": [
        {
            "date": "25",
            "holiday": "Juldagen"
        },
        {
            "date": "26",
            "holiday": "Annandag jul"
        }
    ],
    "202101": [
        {
            "date": "01",
            "holiday": "Nyårsdagen"
        },
        {
            "date": "06",
            "holiday": "Trettondedag jul"
        }
    ],
    "202104": [
        {
            "date": "02",
            "holiday": "Långfredagen"
        },
        {
            "date": "04",
            "holiday": "Påskdagen"
        },
        {
            "date": "05",
            "holiday": "Annandag påsk"
        }
    ],
    "202105": [
        {
            "date": "01",
            "holiday": "Första maj"
        },
        {
            "date": "13",
            "holiday": "Kristi himmelfärdsdag"
        },
        {
            "date": "23",
            "holiday": "Pingstdagen"
        }
    ],
    "202106": [
        {
            "date": "06",
            "holiday": "Sveriges nationaldag"
        },
        {
            "date": "26",
            "holiday": "Midsommar"
        }
    ],
    "202111": [
        {
            "date": "06",
            "holiday": "Alla helgons dag"
        }
    ],
    "202112": [
        {
            "date": "25",
            "holiday": "Juldagen"
        },
        {
            "date": "26",
            "holiday": "Annandag jul"
        }
    ],
    "202201": [
        {
            "date": "01",
            "holiday": "Nyårsdagen"
        },
        {
            "date": "06",
            "holiday": "Trettondedag jul"
        }
    ],
    "202204": [
        {
            "date": "15",
            "holiday": "Långfredagen"
        },
        {
            "date": "17",
            "holiday": "Påskdagen"
        },
        {
            "date": "18",
            "holiday": "Annandag påsk"
        }
    ],
    "202205": [
        {
            "date": "01",
            "holiday": "Första maj"
        },
        {
            "date": "26",
            "holiday": "Kristi himmelfärdsdag"
        }
    ],
    "202206": [
        {
            "date": "05",
            "holiday": "Pingstdagen"
        },
        {
            "date": "06",
            "holiday": "Sveriges nationaldag"
        },
        {
            "date": "25",
            "holiday": "Midsommar"
        }
    ],
    "202211": [
        {
            "date": "05",
            "holiday": "Alla helgons dag"
        }
    ],
    "202212": [
        {
            "date": "25",
            "holiday": "Juldagen"
        },
        {
            "date": "26",
            "holiday": "Annandag jul"
        }
    ],
    "202301": [
        {
            "date": "01",
            "holiday": "Nyårsdagen"
        },
        {
            "date": "06",
            "holiday": "Trettondedag jul"
        }
    ],
    "202304": [
        {
            "date": "07",
            "holiday": "Långfredagen"
        },
        {
            "date": "09",
            "holiday": "Påskdagen"
        },
        {
            "date": "10",
            "holiday": "Annandag påsk"
        }
    ],
    "202305": [
        {
            "date": "01",
            "holiday": "Första maj"
        },
        {
            "date": "18",
            "holiday": "Kristi himmelfärdsdag"
        },
        {
            "date": "28",
            "holiday": "Pingstdagen"
        }
    ],
    "202306": [
        {
            "date": "06",
            "holiday": "Sveriges nationaldag"
        },
        {
            "date": "24",
            "holiday": "Midsommar"
        }
    ],
    "202311": [
        {
            "date": "04",
            "holiday": "Alla helgons dag"
        }
    ],
    "202312": [
        {
            "date": "25",
            "holiday": "Juldagen"
        },
        {
            "date": "26",
            "holiday": "Annandag jul"
        }
    ],
    "202401": [
        {
            "date": "01",
            "holiday": "Nyårsdagen"
        },
        {
            "date": "06",
            "holiday": "Trettondedag jul"
        }
    ],
    "202403": [
        {
            "date": "29",
            "holiday": "Långfredagen"
        },
        {
            "date": "31",
            "holiday": "Påskdagen"
        }
    ],
    "202404": [
        {
            "date": "01",
            "holiday": "Annandag påsk"
        }
    ],
    "202405": [
        {
            "date": "01",
            "holiday": "Första maj"
        },
        {
            "date": "09",
            "holiday": "Kristi himmelfärdsdag"
        },
        {
            "date": "19",
            "holiday": "Pingstdagen"
        }
    ],
    "202406": [
        {
            "date": "06",
            "holiday": "Sveriges nationaldag"
        },
        {
            "date": "22",
            "holiday": "Midsommar"
        }
    ],
    "202411": [
        {
            "date": "02",
            "holiday": "Alla helgons dag"
        }
    ],
    "202412": [
        {
            "date": "25",
            "holiday": "Juldagen"
        },
        {
            "date": "26",
            "holiday": "Annandag jul"
        }
    ],
    "202501": [
        {
            "date": "01",
            "holiday": "Nyårsdagen"
        },
        {
            "date": "06",
            "holiday": "Trettondedag jul"
        }
    ],
    "202504": [
        {
            "date": "18",
            "holiday": "Långfredagen"
        },
        {
            "date": "20",
            "holiday": "Påskdagen"
        },
        {
            "date": "21",
            "holiday": "Annandag påsk"
        }
    ],
    "202505": [
        {
            "date": "01",
            "holiday": "Första maj"
        },
        {
            "date": "29",
            "holiday": "Kristi himmelfärdsdag"
        }
    ],
    "202506": [
        {
            "date": "06",
            "holiday": "Sveriges nationaldag"
        },
        {
            "date": "08",
            "holiday": "Pingstdagen"
        },
        {
            "date": "21",
            "holiday": "Midsommar"
        }
    ],
    "202511": [
        {
            "date": "02",
            "holiday": "Alla helgons dag"
        }
    ],
    "202512": [
        {
            "date": "25",
            "holiday": "Juldagen"
        },
        {
            "date": "26",
            "holiday": "Annandag jul"
        }
    ]
}

export default SwedishHolidays;