import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AlliocationModel } from 'src/app/calendar/alliocation-model';
import { ApiActionName } from 'src/app/shared/constants/enums/action-names';
import { LongBookingTemplate } from 'src/app/shared/models/long-booking/long-booking-template.model';
import { LongBooking } from 'src/app/shared/models/long-booking/long-booking.model';
import { environment } from 'src/environments/environment';
const API_URL = environment.apiEndpoint;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  }),
};
@Injectable({
  providedIn: 'root'
})
export class LongBookingService {

  constructor(private http: HttpClient) { }

  getLongBookingTemplates(): Observable<LongBookingTemplate[]> {
    return this.http.get<LongBookingTemplate[]>(API_URL + ApiActionName.GetLongBookingTemplates, httpOptions)
  }

  getLongBookingsa(): Observable<LongBooking[]> {
    return this.http.get<LongBooking[]>(API_URL + ApiActionName.GetLongBookings, httpOptions)
  }

  getLongBookings(searchCriteria: any): Observable<any> {
    return this.http.post<any>(API_URL +  ApiActionName.GetLongBookings, searchCriteria,  httpOptions).pipe(
      tap(resp => {
      }),
      catchError(null)
    );
  }

  getShortBookings(searchCriteria: any): Observable<any> {
    return this.http.post<any>(API_URL +  ApiActionName.GetShortBookings, searchCriteria,  httpOptions).pipe(
      tap(resp => {
      }),
      catchError(null)
    );
  }

  getAvailabilities(longBooking: LongBooking): Observable<any[]> {
    return this.http.post<any[]>(API_URL + ApiActionName.GetAvailabilities, longBooking, httpOptions)
  }

  saveLongBookingTemplate(longBookingTemplate: LongBookingTemplate): Observable<LongBookingTemplate[]> {
    return this.http.post<LongBookingTemplate[]>(API_URL + ApiActionName.SaveLongBookingTemplate, longBookingTemplate, httpOptions)
  }

  saveLongBooking(longBooking: LongBooking): Observable<LongBooking> {
    return this.http.post<LongBooking>(API_URL + ApiActionName.SaveLongBooking, longBooking, httpOptions)
  }

  deleteLongBooking(id: number): Observable<LongBooking> {
    let longBooking: LongBooking = new LongBooking();
    longBooking.id = id;
    return this.http.post<LongBooking>(API_URL + ApiActionName.DeleteLongBooking , longBooking, httpOptions)
  }

  deleteTemplate(id: number): Observable<LongBooking> {
    let longBooking: LongBooking = new LongBooking();
    longBooking.id = id;
    return this.http.post<LongBooking>(API_URL + ApiActionName.DeleteTemplate , longBooking, httpOptions)
  }

  updateLongBooking(longBooking: LongBooking): Observable<LongBooking> {
    return this.http.post<LongBooking>(API_URL + ApiActionName.UpdateLongBooking, longBooking, httpOptions)
  }

  deleteLongBookingByDateRange(allocationModel: AlliocationModel): Observable<LongBooking> {
    return this.http.post<LongBooking>(API_URL + ApiActionName.DeleteLongBookingRange, allocationModel, httpOptions)
  }

  updateLongBookingTemplate(longBookingTemplate: LongBookingTemplate): Observable<LongBookingTemplate[]> {
    return this.http.put<LongBookingTemplate[]>(API_URL + ApiActionName.UpdateLongBookingTemplate, longBookingTemplate, httpOptions)
  }

  updateLongBookingTemplateName(longBookingTemplate: LongBookingTemplate): Observable<LongBookingTemplate[]> {
    return this.http.post<LongBookingTemplate[]>(API_URL + ApiActionName.UpdateLongBookingTemplateName, longBookingTemplate, httpOptions)
  }

  getLongBookingById(id: number): Observable<any> {
    let url = "?id=" + id;
    return this.http.get<any>(API_URL + ApiActionName.GetLongBookingById + url, httpOptions)
  }
}
