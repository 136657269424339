
import { Component, OnInit, ElementRef, ViewChildren, AfterViewInit, Input, forwardRef, EventEmitter, Output } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators, FormControlName, FormControl, AbstractControl, 
  NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, ValidationErrors } from '@angular/forms';
import { escapeSelector } from 'jquery';
// import { UserService } from '../user/user.service';
// import { FileUploadService } from '../config/file-upload/file-upload.service';
import { ConfigService } from '../../shared/services/config.service';
import { Observable, merge, fromEvent, Subscription, timer } from 'rxjs';
import { debounceTime, switchMap, map } from 'rxjs/operators';

@Component({
  selector: 'wfm-booking-unit',
  templateUrl: './booking-unit.component.html',
  styleUrls: ['./booking-unit.component.css']
})
export class BookingUnitComponent implements OnInit {

  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  public bookingUnitForm: FormGroup;

  notificationModal :any;
  isSubmitInvalid:boolean = false;

  constructor( private fbp: FormBuilder, private configService: ConfigService ) { }

  ngOnInit() {
    this.bookingUnitForm = this.fbp.group({
      bookingUnits: this.fbp.array([this.createBookingUnit()]),
    })

    this.notificationModal = {
      header: '',
      description: '',
      type: ''
    };

    this.getBookingUnits();
  }

  createBookingUnit(id=0, name = null)
  {
    return this.fbp.group({
      id:[id==null ? 0 : id],
      name: [name ? name : '', Validators.required]
    })
  }
   get bookingUnits() : FormArray {
     return this.bookingUnitForm.get('bookingUnits') as FormArray;
   }
 
   AddBookingUnit(bookingUnit)
   { 
     this.bookingUnits.push(this.createBookingUnit(bookingUnit));
   }
 
   removeBookingUnit(index)
   { 
     this.bookingUnits.removeAt(index);
   }
 
   validateFormFields() {
     const bookingUnitsArray = this.bookingUnitForm.get('bookingUnits') as FormArray;
    
     Object.keys(bookingUnitsArray['controls']).forEach(element => {
       const control = bookingUnitsArray.get(element);
       Object.keys(control['controls']).forEach(field => {
         const controlItem = control.get(field);
         if (controlItem instanceof FormControl) {
           controlItem.markAsTouched({ onlySelf: true });
         }
       });
     });
   }
 
     save() {
       this.validateFormFields();
       if (this.bookingUnitForm.valid) {
         this.configService.saveBookingUnit(this.bookingUnitForm.value).subscribe(
           //() => this.onSaveComplete('update', true, '0'),
           (res) => {
            //console.log(res);
           this.setNotificationData('update', res);
           //this.getBookingUnits();
         });
       }else{
         this.isSubmitInvalid = true
       }   
     }
 
     cancel()
     {
       const bookingUnitsArray = this.bookingUnitForm.get('bookingUnits') as FormArray;
    
       Object.keys(bookingUnitsArray['controls']).forEach(element => {
         const control = bookingUnitsArray.get(element);
         Object.keys(control['controls']).forEach(field => {
           const controlItem = control.get(field);
           if (controlItem instanceof FormControl) {
             controlItem.markAsUntouched({ onlySelf: false });
           }
         });
       });
     }
 
     getBookingUnits() {
       this.configService.getBookingUnits().subscribe((res) => {

          const bookingUnits = this.fbp.array([]);
          if(res.bookingUnits.length > 0)
          {
            res.bookingUnits.forEach(item => {
              var bookingUnit = this.createBookingUnit(item.id, item.name)
              bookingUnits.push(bookingUnit);
            });
          }
          else{
            bookingUnits.push(this.createBookingUnit());
          }

          this.bookingUnitForm.setControl('bookingUnits', bookingUnits);
       })
     }
 
     setNotificationData(flow, status): void {

       if (flow === 'update') {
         if (status) {
           this.notificationModal.header = 'Lyckades';
           this.notificationModal.description = 'Uppdateringen av bokningsenheten lyckades!';
           this.notificationModal.type = 'success';
         } else {
           this.notificationModal.header = 'Misslyckades';
           this.notificationModal.description = 'Uppdatering av bokningsenhet misslyckades! Var god försök igen.';
           this.notificationModal.type = 'danger';
         }
 
         //console.log(this.notificationModal);
       }
       ($('#bookingUnitNotificationModal') as any).modal('show');
 
       setTimeout(() => {
         ($('#bookingUnitNotificationModal') as any).modal('hide');
       }, 1000);
     }
}
