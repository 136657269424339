import { Injectable } from '@angular/core';
import { AppUserAuth } from './app-user-auth';
import { of, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';

import { AppUser } from './app-user';
import { templateJitUrl } from '@angular/compiler';
import { environment } from '../../environments/environment';

const API_URL = environment.tokenUrl;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    'No-Auth': 'True'
  })
};


@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  securityObject: AppUserAuth = new AppUserAuth();

  constructor(private http: HttpClient) { }

  login(entity: AppUser): Observable<AppUserAuth> {
    // Initialize security object
    this.resetSecurityObject();
    const userData = 'username=' + entity.username + '&password=' + entity.password + '&grant_type=password';
    return this.http.post<AppUserAuth>(API_URL, userData, httpOptions).pipe(
      tap(resp => {
        Object.assign(this.securityObject, resp);
        this.securityObject.claims = JSON.parse(resp.claims.toString());
        localStorage.setItem('bearerToken', this.securityObject.access_token);
        localStorage.setItem('username', this.securityObject.userName);
        localStorage.setItem('role', this.securityObject.userType);
        localStorage.setItem('userImage', this.securityObject.userImage);
        localStorage.setItem('claims', resp.claims.toString());
      }),
      catchError(this.handleError)
    );
  }


  logout(): void {
    this.resetSecurityObject();
  }

  resetSecurityObject(): void {
    this.securityObject.userName = '';
    this.securityObject.access_token = '';
    this.securityObject.isAuthenticated = false;
    this.securityObject.claims = [];
    localStorage.removeItem('bearerToken');
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    localStorage.removeItem('userImage');
    localStorage.removeItem('claims');
  }

  isAuthenticated(): boolean {
    if (this.securityObject.isAuthenticated) {
      return true;
    } else {
      const token = localStorage.getItem('bearerToken');
      return token ? true : false;
    }

  }

  hasClaim(claimType: any, claimValue?: any): boolean {
    let ret = false;

    // See if an array of values was passed in.
    if (typeof claimType === 'string') {
      ret = this.isClaimValid(claimType, claimValue);
    } else {
      const claims: string[] = claimType;
      if (claims) {
        // tslint:disable-next-line: prefer-for-of
        for (let index = 0; index < claims.length; index++) {
          ret = this.isClaimValid(claims[index]);
          // If one is successful, then let them in
          if (ret) {
            break;
          }
        }
      }
    }
    return ret;

  }

  private isClaimValid(claimType: string, claimValue?: string): boolean {
    let ret = false;
    let auth: AppUserAuth = null;

    // Retrieve security object
    auth = this.securityObject;

    const claims = localStorage.getItem('claims');

    if (claims) {
      // See if the claim type has a value
      // *hasClaim="'claimType:value'"
      if (claimType.indexOf(':') >= 0) {
        const words: string[] = claimType.split(':');
        claimType = words[0].toLowerCase();
        claimValue = words[1];
      } else {
        claimType = claimType.toLowerCase();
        // Either get the claim value, or assume 'true'
        claimValue = claimValue ? claimValue : 'true';
      }
      // Attempt to find the claim
      const claimsData = JSON.parse(claims);
      ret = claimsData.find(c =>
        // tslint:disable-next-line: triple-equals
        c.claimType.toLowerCase() == claimType &&
        // tslint:disable-next-line: triple-equals
        c.claimValue == claimValue) != null;
    }
    return ret;
  }


  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occured: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }

}
